import moment from 'moment';

export const noop = true;

/*
 * Payment plans accepted : monthly, 3_month
 * TODO: down payment / deposit
 */
export const calculatePaymentSchedule = (params) => {
  const {
    startsAt,
    expiresAt,
    scheduledAmount,
    dayOfMonth = 5,
    paymentPlan = 'monthly', // monthly or n_month
    firstInstallmentDate = null,
    additionalAmountOnFirstInstallment = 0,
  } = params;

  let months;
  if (paymentPlan === 'monthly') {
    months = Math.round(
      moment(expiresAt).diff(moment(startsAt), 'months', true),
    );
  } else if (paymentPlan.includes('_month')) {
    months = Number(paymentPlan.split('_')[0]);
  } else { // full
    months = 1;
  }

  const p = 1; // precision
  const schedule = [];
  const numberOfInstallments = months;
  const perInstallment = Math.floor((scheduledAmount / p) / numberOfInstallments) * p;
  const last = scheduledAmount - ((numberOfInstallments - 1) * perInstallment);

  const incrDueDate = firstInstallmentDate && moment(firstInstallmentDate).isValid()
    ? moment(firstInstallmentDate)
    : moment(startsAt);

  for (let j = 0; j < numberOfInstallments; j += 1) {
    const amount = (j === (numberOfInstallments - 1)) ? last : perInstallment;
    const dueDate = moment.max(moment().add(1, 'days'), incrDueDate);
    schedule[j] = {
      amount,
      dueDate: dueDate.format('YYYY-MM-DD'),
    };
    incrDueDate.add(1, 'M').date(dayOfMonth);
  }

  if (schedule.length > 0) {
    schedule[0].amount += additionalAmountOnFirstInstallment;
  }

  return schedule;
};
