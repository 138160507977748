import gql from 'graphql-tag';

/* */
const searchFromLeClub = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`
      query(
        $query: String
        $customerId: Int
      ) {
        searchFromLeClub(
          query: $query
          customerId: $customerId
        ) {
          externalId
          firstname
          lastname
          email
          birthdate
          phone
          cardNumber
          cardExpirationDate
          fees
          program
        }
      }
    `,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => (data.searchFromLeClub)));

/* */
const findAllBy = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`
      query(
        $accountId: String!
        $type: Int
      ) {
        searchLoyaltyCards(
          accountId: $accountId
          type: $type
        ) {
          id
          type
          externalId
          program
          number
          expirationDate
          createdAt
        }
      }
    `,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => (data.searchLoyaltyCards)));

/* */
const assign = (client, variables) => (
  client.mutate({
    mutation: gql`
      mutation(
        $accountId: String!
        $loyaltyCard: LoyaltyCardInput!
      ) {
        assignLoyaltyCard(
          accountId: $accountId
          loyaltyCard: $loyaltyCard
        ) {
          id
          type
          externalId
          program
          number
          expirationDate
          createdAt
        }
      }
    `,
    variables,
  })
    .then((res) => res.data)
    .then((data) => (data.assignLoyaltyCard)));

/* */
const unassign = (client, variables) => (
  client.mutate({
    mutation: gql`
      mutation(
        $id: String!
      ) {
        unassignLoyaltyCard(
          id: $id
        ) {
          success
        }
      }
    `,
    variables,
  })
    .then((res) => res.data)
    .then((data) => (data.unassignLoyaltyCard)));

export {
  assign,
  unassign,
  findAllBy,
  searchFromLeClub,
};
