import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Chip,
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
} from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import CloseIcon from '@material-ui/icons/Close';
import UploadIcon from '@material-ui/icons/CloudUpload';
import DescriptionIcon from '@material-ui/icons/Description';

import config from '_config';
import helpers from '_utils/helpers';

const DOCUMENT_MAX_SIZE = config.upload.maxFileSize;
const DOCUMENT_MAX_FILES = config.upload.maxFiles;

/* */
const styles = (theme) => ({
  dialog: {
    backgroundColor: theme.palette.background.default,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.grey[500],
  },
  dropzone: {
    width: '100%',
    // minWidth: 300,
    // minHeight: 300,
    // display: 'flex',
    // alignItems: 'center',
    // flexDirection: 'column',
    // justifyContent: 'center',
  },
  uploadIcon: {
    marginRight: theme.spacing(2),
  },
  chip: {
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  error: {
    fontWeight: 600,
    color: red[500],
  },
});

/* */
const Upload = ({
  open,
  classes,
  handleClose,
  handleUpload,
}) => {
  /* */
  const [files, setFiles] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);

  const handleSelectFiles = (filelist) => {
    const list = Array.from(filelist);
    if (list.length > DOCUMENT_MAX_FILES) {
      setError(`Seulement ${DOCUMENT_MAX_FILES} fichiers à la fois`);
      return false;
    }
    const errors = [];
    const uploads = [];
    list.forEach((file) => {
      if (file.size > DOCUMENT_MAX_SIZE) {
        errors.push(`${file.name} trop volumineux (${helpers.string.humanFileSize(file.size)} max)`);
      } else {
        uploads.push(file);
      }
    });
    if (errors.length) {
      errors.push(`Taille max. acceptée : ${helpers.string.humanFileSize(DOCUMENT_MAX_SIZE)}`);
      setError(errors.map((err, i) => (
        <React.Fragment key={i}>
          {err}<br />
        </React.Fragment>
      )));
      return false;
    }
    setFiles(uploads);
    setError(null);
    return true;
  };

  const handleDelete = (idx) => {
    setFiles((files || []).filter((f, i) => i !== idx));
  };

  const handleSubmit = () => {
    if (files?.length) {
      setUploading(true);
      handleUpload(files)
        .then(() => setFiles([]))
        .finally(() => setUploading(false));
    }
  };

  const renderContent = () => {
    if (files?.length) {
      return files.map((file, i) => (
        <Chip
          key={i}
          color="secondary"
          className={classes.chip}
          onDelete={() => handleDelete(i)}
          label={`${files[i].name} (${helpers.string.humanFileSize(files[i].size)})`}
          icon={<DescriptionIcon />}
        />
      ));
    }
    return (
      <>
        <label htmlFor="upload-documents">
          <Button
            variant="contained"
            component="label"
          >
            <UploadIcon className={classes.uploadIcon} />
            {'Choisir des fichiers'}
            <input
              hidden
              multiple
              type='file'
              id='upload-documents'
              className={classes.dropzone}
              onChange={({ target }) => handleSelectFiles(target.files)}
            />
          </Button>
        </label>
      </>
    );
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      classes={{ paperFullWidth: classes.dialog }}
    >
      <DialogTitle>
        {'Ajouter un nouveau document'}
        <IconButton
          color="inherit"
          aria-label="Fermer"
          onClick={handleClose}
          className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography align='center' className={classes.error}>
          {error}
        </Typography>
        {renderContent()}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}>
          {'Annuler'}
        </Button>
        <Button
          color="secondary"
          variant="contained"
          disabled={uploading}
          onClick={handleSubmit}>
          {uploading
            ? <CircularProgress color="secondary" size={24} />
            : 'Envoyer'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(Upload);
