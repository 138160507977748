import gql from 'graphql-tag';
import actionTypes from './constants';
import api from '../../utils/api';
import helpers from '../../utils/helpers';

/* */
export const sendEmail = (variables) => (
  api.post('sendEmail', variables)
);

/* */
export const selectGolf = (client, variables, fetchPolicy = 'no-cache') => client.query({
  query: gql`${actionTypes.gql.GET_GOLF_INFOS}`,
  fetchPolicy,
  variables,
})
  .then(({ data }) => ({
    golf: data.getGolf,
    accessories: data.getAccessories,
    courses: data.getCourses,
  }));

/* */
export const searchGolf = (client, variables, fetchPolicy = 'no-cache') => client.query({
  query: gql`${actionTypes.gql.SEARCH_GOLFS}`,
  fetchPolicy,
  variables,
})
  .then(({ data }) => ({ golfs: data.searchGolfs }));

/* */
export const getCourseSchedule = (client, variables, fetchPolicy = 'no-cache') => client.query({
  query: gql`${actionTypes.gql.GET_COURSE_SCHEDULE}`,
  fetchPolicy,
  variables,
})
  .then(({ data }) => ({ courseSchedule: data.getCourseSchedule }));

/* */
export const getAccessoriesSchedule = (client, variables, fetchPolicy = 'no-cache') => {
  if ('accessoryIds' in variables) {
    return client.query({
      query: gql`${actionTypes.gql.GET_ACCESSORIES_SCHEDULE}`,
      fetchPolicy,
      variables,
    })
      .then(({ data }) => ({ accessoriesSchedule: data.getAccessoriesSchedule }));
  }

  return { accessoriesSchedule: null };
};

/* */
export const getSchedules = (client, variables) => Promise.all([
  getCourseSchedule(client, variables),
  getAccessoriesSchedule(client, variables),
]);

/* */
export const bookingLock = (client, details) => {
  // Filter accessories objects
  const accessoryFields = [
    'id', 'name', 'qty', 'initPrice', 'discount', 'price',
  ];
  // Global variables
  const variables = {
    accessories: details.accessories.map((obj) =>
      helpers.object.filterByKeys(obj, accessoryFields)),
    course: helpers.object.filterByKeys(details.course, ['id', 'type', 'name']),
    date: details.date,
    golf: helpers.object.filterByKeys(details.golf, ['id', 'name']),
    nb: details.players.length,
    time: details.time,
  };
  return client.mutate({
    mutation: gql`${actionTypes.gql.BOOKING_LOCK}`,
    variables,
  })
    .then(({ data }) => data.bookingLock);
};

// Book tee time & accessories
/* */
export const bookingBook = (client, details) => {
  // Filter players objects
  const playerFields = [
    'tid', 'gender', 'firstname', 'lastname', 'email', 'licenseNo',
    'handicap', 'initPrice', 'discount', 'price', 'payments',
  ];
  // Filter accessories objects
  const accessoryFields = [
    'id', 'name', 'qty', 'initPrice', 'discount', 'price', 'lockCode', 'payments',
  ];
  // Global variables
  const variables = {
    isPrivatized: details.isPrivatized || 0,
    forceAnonymous: details.forceAnonymous || 0,
    accountId: details.accountId || '',
    accessories: details.accessories.map((obj) =>
      helpers.object.filterByKeys(obj, accessoryFields)),
    course: helpers.object.filterByKeys(details.course, ['id', 'type', 'name']),
    date: details.date,
    golf: helpers.object.filterByKeys(details.golf, ['id', 'name']),
    lockCode: details.lockCode,
    nb: details.players.length,
    owner: details.owner,
    players: details.players.map((obj) => helpers.object.filterByKeys(obj, playerFields)),
    time: details.time,
    time9in: details.time9in,
    total: {
      discount: details.total.discount,
      initPrice: details.total.initPrice,
      price: details.total.price,
      currency: '€',
    },
  };

  return client.mutate({
    mutation: gql`${actionTypes.gql.BOOKING_BOOK}`,
    variables,
  })
    .then(({ data }) => data.bookingBook);
};

/* */
export const bookingConfirm = (client, variables) => client.mutate({
  mutation: gql`${actionTypes.gql.BOOKING_CONFIRM}`,
  variables,
})
  .then(({ data }) => data.bookingConfirm);

/* */
export const bookingCancel = (client, variables) => client.mutate({
  mutation: gql`${actionTypes.gql.BOOKING_CANCEL}`,
  variables,
})
  .then(({ data }) => data.bookingCancel);

/* */
export const bookingEdit = (client, variables) => client.mutate({
  mutation: gql`${actionTypes.gql.BOOKING_EDIT}`,
  variables,
})
  .then(({ data }) => data.bookingEdit);

/* */
export const getBookingList = (client, variables, fetchPolicy = 'no-cache') => client.query({
  query: gql`${actionTypes.gql.GET_BOOKING_LIST}`,
  fetchPolicy,
  variables,
})
  .then(({ data }) => ({ bookings: data.getBookings }));

/* */
export const getBooking = (client, id, fetchPolicy = 'no-cache') => client.query({
  query: gql`${actionTypes.gql.GET_BOOKING}`,
  fetchPolicy,
  variables: { id },
})
  .then(({ data }) => ({ booking: data.getBooking }));

/* */
export const getTeetime = (client, variables, fetchPolicy = 'no-cache') => client.query({
  query: gql`${actionTypes.gql.GET_TEETIME}`,
  fetchPolicy,
  variables,
})
  .then(({ data }) => data.Teetime_get);

/* */
export const getTeetimePlayerPrices = (client, variables, fetchPolicy = 'no-cache') => client.query({
  query: gql`${actionTypes.gql.GET_TEETIME_PLAYER_PRICES}`,
  fetchPolicy,
  variables,
})
  .then(({ data }) => data.Teetime_getPlayerPrices);
