import gql from 'graphql-tag';
import actionTypes from './constants';

export const loadMetrics = (client, variables, fetchPolicy = 'no-cache') => client.query({
  query: gql`${actionTypes.gql.GET_METRICS}`,
  fetchPolicy,
  variables,
})
  .then((res) => res.data)
  .then((data) => (data.getMetrics));

export const loadRealTime = (client, variables, fetchPolicy = 'no-cache') => client.query({
  query: gql`${actionTypes.gql.GET_REAL_TIME}`,
  fetchPolicy,
  variables,
}).then((res) => res.data)
  .then((data) => (data.getRealTime));

export const loadEvents = (client, variables, fetchPolicy = 'no-cache') => client.query({
  query: gql`${actionTypes.gql.GET_EVENTS}`,
  fetchPolicy,
  variables,
}).then((res) => res.data)
  .then((data) => (data.getEvents));
