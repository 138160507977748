import React from 'react';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { createValidationSchema, validators } from '_utils/validation';

/* */
const validationSchema = createValidationSchema({
  name: validators.required,
});

/* */
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

/* */
export default (({
  open,
  onClose,
  onSubmit,
  isSubmitting,
}) => {
  const classes = useStyles();

  const [name, setName] = React.useState('');

  // Reset name on open/close
  React.useEffect(() => setName(''), [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}>
        <DialogTitle id="openCreateList-title">{'Créer une nouvelle liste à partir des contacts sélectionnés.'}</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <Formik
              onSubmit={(values) => setName(values.name)}
              initialValues={{ name }}
              validationSchema={validationSchema}>
              {({
                errors,
                values,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form
                  id="createList-form"
                  onSubmit={handleSubmit}
                  className={classes.container}
                  autoComplete="off">
                  <TextField
                    required
                    fullWidth
                    name="name"
                    margin="normal"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.name}
                    onChange={handleChange}
                    error={!!(errors.name)}
                    label="Nom de la liste"
                    className={classes.textField}
                  />
                </form>
              )}
            </Formik>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {'Fermer'}
          </Button>
          <Button color="primary" variant="contained" type="submit" form="createList-form">
            {'Ajouter'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open && Boolean(name)}
        onClose={onClose}>
        <DialogTitle>{'Créer une nouvelle liste.'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmCreateList-description">
            {'Êtes-vous sûr de vouloir créer une nouvelle liste à partir des résultats de la recherche ?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {'Annuler'}
          </Button>
          <Button onClick={() => onSubmit(name)} disabled={isSubmitting} color="primary" variant="contained" autoFocus>
            {'Ok'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
