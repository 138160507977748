import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  // Button,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
} from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import * as App from '_redux/app';

// The usage of React.forwardRef will no longer be required for react-router-dom v6.
// see https://github.com/ReactTraining/react-router/issues/6056
const AdapterLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);

/* */
const customStyles = makeStyles((theme) => ({
  text: {},
  icon: {},
  link: {
    '& $icon': {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main, // svg
    },
    '&:hover': {
      '& $text': {
        color: theme.palette.text.secondary,
        fill: theme.palette.text.secondary, // svg
      },
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    fill: theme.palette.primary.contrastText,
  },
}));

/* */
const CustomListItem = ({
  icon,
  text,
  iconRight,
  expanded = null,
  ...props
}) => {
  const classes = customStyles();
  const customProps = { ...props };
  if (props.to) {
    customProps.component = AdapterLink;
    customProps.activeClassName = classes.selected;
  }

  return (
    <ListItem
      button
      disableRipple
      className={classes.link}
      {...customProps}>
      {icon && (
        <ListItemIcon className={classes.icon}>
          {icon}
        </ListItemIcon>
      )}
      <ListItemText
        classes={{ primary: classes.text }}
        primary={text} />
      {expanded !== null && (
        expanded
          ? <ExpandLessIcon color="action" />
          : <ExpandMoreIcon color="action" />
      )}
      {iconRight && (
        <ListItemSecondaryAction className={classes.link}>
          {iconRight}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

/* */
const useStyles = makeStyles((theme) => ({
  toolbar: {
    height: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.common.white,
      ...theme.mixins.toolbar,
    },
  },
  link: {
    '& $icon': {
      color: theme.palette.secondary.main,
      fill: theme.palette.secondary.main, // svg
    },
    '&:hover': {
      '& $text, & $icon': {
        color: theme.palette.text.secondary,
        fill: theme.palette.text.secondary, // svg
      },
    },
  },
  selected: {
    '&, &:hover': {
      backgroundColor: theme.palette.secondary.main,
      '& $text, & $icon': {
        color: theme.palette.secondary.contrastText,
        fill: theme.palette.secondary.contrastText, // svg
      },
    },
  },
  text: {},
  icon: {},
  email: { color: theme.palette.grey[500] },
  identity: { padding: theme.spacing(1) },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    marginBottom: theme.spacing(1),
  },
  logout: {
    display: 'block',
    margin: `${theme.spacing(1)}px auto`,
  },
}));

/* */
const CorporateMenu = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.toolbar} />
      <List>
        <CustomListItem
          to="/corporations/list"
          text="Contrats"
          iconRight={<ListAltIcon className={classes.icon} /> } />
        <CustomListItem
          to="/corporations/report"
          text="Reporting"
          iconRight={<ListAltIcon className={classes.icon} /> } />
      </List>
    </div>
  );
};

const mapStateToProps = ({ app: { user, navDrawer } }) => ({ user, navDrawer });

export default connect(mapStateToProps, App.actions)(CorporateMenu);
