import gql from 'graphql-tag';

import actionTypes from './constants';

// -----------------------------------------------------------------------------
//
// -----------------------------------------------------------------------------

/* */
const getAccounts = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`${actionTypes.gql.GET_ACCOUNTS}`,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => (data.getAccounts)));
/* */
const getAccountsWithPushToken = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`${actionTypes.gql.GET_ACCOUNTS_WITH_PUSH_TOKEN}`,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => (data.getAccountsWithPushToken)));
/* */
const getAccountsByIds = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`${actionTypes.gql.GET_ACCOUNTS_BY_IDS}`,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => (data.getAccountsbyIds)));

/* */
const matchAccount = (client, variables) => (
  client.mutate({
    mutation: gql`${actionTypes.gql.MATCH_ACCOUNT}`,
    variables,
  })
    .then((res) => res.data)
    .then((data) => (data.matchAccount)));

const getAccount = (client, variables) => (
  client.query({
    query: gql`${actionTypes.gql.GET_ACCOUNT}`,
    variables,
  })
    .then((res) => res.data)
    .then((data) => (data.getAccount)));

const listPassages = (client, variables) => (
  client.query({
    query: gql`${actionTypes.gql.LIST_PASSAGES}`,
    variables,
  })
    .then((res) => res.data)
    .then((data) => (data.listPassages)));

const listWalletOperations = (client, variables) => (
  client.query({
    query: gql`${actionTypes.gql.LIST_WALLET_OPERATIONS}`,
    variables,
  })
    .then((res) => res.data)
    .then((data) => (data.listWalletOperations)));

/* */
const setCovidCertificateOwned = (client, variables) => client.mutate({
  mutation: gql`${actionTypes.gql.SET_COVID_CERTIFICATE_OWNED}`,
  variables,
})
  .then((res) => res.data);

export {
  getAccount,
  matchAccount,
  getAccounts,
  getAccountsWithPushToken,
  getAccountsByIds,
  listPassages,
  listWalletOperations,
  setCovidCertificateOwned,
};
