import React from 'react';
import { connect } from 'react-redux';
import { Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FolderSharedIcon from '@material-ui/icons/FolderShared';

import { Screen } from '_components/core';
import * as Settings from '_redux/settings';

/* */
const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: 100,
    marginBottom: theme.spacing(2),
  },
}));

/* */
const Documents = ({ golf }) => {
  const classes = useStyles();

  const mailSent = localStorage.getItem('document_visited');

  React.useEffect(() => {
    if (!mailSent) {
      // console.log('mail sent !');
      Settings.api.sendEmail({
        to: ['contact@primagolf.fr'],
        templateId: 'd-0ecd5562d0c34b8299cc2d24cc8cef27',
        from: {
          name: golf.name,
          email: golf.email,
        },
        substitutions: {
          feature: 'Espace documentaire',
        },
      })
        .then(() => localStorage.setItem('document_visited', true))
        .catch(() => localStorage.removeItem('document_visited'));
    }
  });

  return (
    <Screen>
      <div className={classes.wrapper}>
        <FolderSharedIcon
          className={classes.icon}
          fontSize="inherit" />
        <Typography
          component="h1"
          gutterBottom
          variant="h4">
          {'Espace documentaire'}
        </Typography>
        <br />
        <Typography
          component="h2"
          align="center"
          variant="subtitle1">
          {'Vous souhaitez bénéficier de cette fonctionnalité ?'}
        </Typography>
        <Typography
          component="h2"
          align="center"
          variant="subtitle1">
          {"Rapprochez-vous de l'Equipe Prima au 02 38 42 18 20 ou "}
          <Link href="mailto:contact@primagolf.fr">{'contact@primagolf.fr'}</Link>.
        </Typography>
      </div>
    </Screen>
  );
};

const mapStateToProps = ({ app: { golf } }) => ({ golf });

export default connect(mapStateToProps)(Documents);
