import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import {
  useQuery,
  useMutation,
} from 'react-query';
import MaterialTable from 'material-table';
import BackIcon from '@material-ui/icons/ArrowBack';

/* */
import i18n from '_utils/i18n';
import Menu from '_screens/Menu';
import master from '_utils/master';
import * as Client from '_redux/client';
import * as Push from '_redux/push';
import { Screen } from '_components/core';

const mapStateToProps = ({ app: { golf } }) => ({ golf });
/* */
const useStyles = makeStyles({
  addButton: {
    float: 'right',
  },
  secondAddButton: {
    float: 'right',
    marginRight: '10px',
  },
  deleteButton: {
    float: 'right',
  },
});

/* */
export default connect(mapStateToProps)(({ golf, match: { params: { id } }, history }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [checked, setChecked] = React.useState([]);
  const [deleted, setDeleted] = React.useState([]);
  const [usersList, setUsersList] = React.useState([]);
  const [deleteButton, setDeleteButton] = React.useState(true);
  const [deleteListOpen, setDeleteListOpen] = React.useState(false);

  const list = useQuery(['list', golf.id], () => Push.api.getPushList(master, { id }).then(({
    accounts,
    createdAt,
    updatedAt,
    ...rest
  }) => ({
    ...rest,
    accounts,
    createdAt: createdAt && i18n.l('time.formats.short_y', new Date(createdAt)),
    updatedAt: updatedAt && i18n.l('time.formats.short_y', new Date(updatedAt)),
  })));
  const { data: users } = useQuery(
    ['list-users', { ids: id }],
    () => Client.api.getAccountsByIds(master, {
      ids: list.data?.accounts,
    }),
    {
      enabled: !!list.data?.accounts,
      keepPreviousData: false,
    },
  );

  useEffect(() => {
    const fetch = () => {
      setUsersList(users?.filter((user) => !deleted
        .map((deletedUser) => deletedUser.id).includes(user.id)));
    };
    fetch();
  }, [deleted, users]);

  /* */
  const handleToggle = (values) => {
    if (values.length === 0) {
      setDeleteButton(true);
    } else {
      setDeleteButton(false);
    }
    setChecked(values);
  };

  /* */
  const handleConfirm = () => {
    Push.api.pushListDeleteAccounts(master, {
      accounts: checked.reduce((acc, current) => {
        const actualAcc = acc || [];
        actualAcc.push(current.id);
        return actualAcc;
      }, []),
      id,
    })
      .then(() => setDeleted(checked))
      .then(() => setUsersList(users.filter((user) => !deleted
        .map((deletedUser) => deletedUser.id).includes(user.id))))
      .then(() => setChecked([]))
      .then(() => setDeleteButton(true))
      .catch((e) => setError(e))
      .finally(() => setOpen(false));
  };

  const mutation = useMutation(() => handleConfirm());

  /* */
  const openDeleteList = () => {
    setDeleteListOpen(true);
  };

  /* */
  const handleDelete = () => {
    Push.api.deleteList(master, { id })
      .then(() => history.push('/push'))
      .catch((e) => setError(e));
  };
  return (
    <Screen
      error={list.isError || error}
      layout='fluid'
      loading={list.isLoading || list.isFetching}
      title='Liste de diffusion'
      menu={<Menu />}
    >
      <IconButton
        edge="start"
        color="inherit"
        onClick={history.goBack}
        className={classes.backButton}>
        <BackIcon />
      </IconButton>
      {users && list.data && (
        <>
          <Box display='flex' justifyContent="space-between">
            <Typography variant="h4">{list.data.name}</Typography>
            <Box display='flex' justifyContent="space-between" alignItems="flex-end" flexDirection='column'>
              <Typography variant="caption" display="inline">{`Créée le ${list.data.createdAt}`}</Typography>
              {list.data.updatedAt && (<Typography variant="caption" display="inline">{`Modifiée le ${list.data.updatedAt}`}</Typography>)}
            </Box>
          </Box>
          <br />
          <Button className={classes.addButton} variant="outlined" color="primary" onClick={() => history.push('/contact')}>
            {'Ajouter des contacts'}
          </Button>
          <Button className={classes.secondAddButton} variant="outlined" color="secondary" onClick={openDeleteList}>
            {'Supprimer la liste'}
          </Button>
          <Box pt={8}>
            <MaterialTable
            title=""
            data={usersList?.filter((user) => !deleted
              .map((deletedUser) => deletedUser.id).includes(user.id))}
            onSelectionChange={(rows) => handleToggle(rows)}
            localization={{
              toolbar: {
                nRowsSelected: `{0} ${i18n.t('MUIDataTable.selectedRows.text')}`,
                searchPlaceholder: i18n.t('MUIDataTable.toolbar.search'),
                searchTooltip: i18n.t('MUIDataTable.toolbar.search'),
              },
              body: {
                filterRow: {
                  filterTooltip: i18n.t('MUIDataTable.toolbar.filterTable'),
                },
              },
            }}
            options={{
              paging: true,
              pageSize: 10,
              toolbar: true,
              selection: true,
              filtering: true,
              showTitle: false,
              showHeader: false,
              toolbarButtonAlignment: 'right',
              pageSizeOptions: [10, 50, 100, 150, 200],
              maxBodyHeight: '80vh',
            }}
            columns={[
              { field: 'id', hidden: true },
              { title: 'Prenom', field: 'firstname', defaultSort: 'asc' },
              { title: 'Nom', field: 'lastname' },
              { title: 'email', field: 'email' },
              { title: 'tel', field: 'phone' },
            ]}
          />
          </Box>
          <br />
          <Box align="right" flex="1">
            {users && (users.length > 0) && (
              <Button className={classes.deleteButton} variant="outlined" disabled={deleteButton} color="secondary" onClick={() => setOpen(true)}>
                {'Supprimer'}
              </Button>
            )}
          </Box>

          <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>{`Supprimer les contacts de la liste ${list.data.name}`}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`Attention, vous êtes sur le point de supprimer ${checked.length || 1} utilisateurs de la liste.`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                {'Annuler'}
              </Button>
              <Button onClick={mutation.mutate} color="secondary" autoFocus>
                {'Supprimer'}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={deleteListOpen} onClose={() => setDeleteListOpen(false)}>
            <DialogTitle>{`Supprimer la liste ${list.data.name}`}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {'Attention, vous êtes sur le point de supprimer la liste.'}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteListOpen(false)} color="primary">
                {'Annuler'}
              </Button>
              <Button onClick={handleDelete} color="secondary" autoFocus>
                {'Supprimer'}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Screen>
  );
});
