import React from 'react';
import MaskedInput from 'react-text-mask';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import i18n from '_utils/i18n';

/* */
const styles = (theme) => ({
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2, 3),
  },
});

/* */
const DateOfBirthMaskedInput = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
    />
  );
};

/* */
class RegisterStudentForm extends React.Component {
  /* */
  render() {
    const {
      errors,
      values,
      classes,
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
    } = this.props;

    return (
      <Paper className={classes.paper}>
        <Typography
          gutterBottom
          component="h2"
          color="primary"
          variant="subtitle1">
          {'Ajouter un élève'}
        </Typography>
        <TextField
          fullWidth
          label="Prénom"
          name="firstname"
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.firstname}
          error={!!(errors.firstname)}
          className={classes.textField}
          helperText={errors.firstname}
          InputLabelProps={{ shrink: true }} />
        <TextField
          fullWidth
          label="Nom"
          name="lastname"
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.lastname}
          error={!!(errors.lastname)}
          helperText={errors.lastname}
          className={classes.textField}
          InputLabelProps={{ shrink: true }} />
        <TextField
          fullWidth
          name="dateOfBirth"
          label="Date de naissance"
          variant="outlined"
          onBlur={handleBlur}
          value={values.dateOfBirth}
          onChange={handleChange}
          error={!!(errors.dateOfBirth)}
          helperText={errors.dateOfBirth}
          className={classes.textField}
          placeholder="JJ/MM/AAAA"
          InputProps={{
            inputComponent: DateOfBirthMaskedInput,
          }}
          InputLabelProps={{ shrink: true }} />
        <TextField
          fullWidth
          name="phone"
          label="Téléphone"
          variant="outlined"
          onBlur={handleBlur}
          value={values.phone}
          onChange={handleChange}
          error={!!(errors.phone)}
          helperText={errors.phone}
          className={classes.textField}
          InputLabelProps={{ shrink: true }} />
        <Button
          fullWidth
          size="large"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitting}
          className={classes.submitButton}>
          {isSubmitting
            ? <CircularProgress color="inherit" size={24} />
            : i18n.t('default.actions.add')}
        </Button>
      </Paper>
    );
  }
}

export default withStyles(styles)(RegisterStudentForm);
