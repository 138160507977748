/* */
export const ages = {
  baby: 'Poussin (4-6 ans)',
  kid1: 'Jeune (6-9 ans)',
  kid2: 'Jeune (10-13 ans)',
  kid3: 'Jeune (14-17 ans)',
  // kid4: 'Enfant (13-17 ans)',
  teenager1: 'Espoir (-13 ans)',
  teenager2: 'Espoir (+13 ans)',
  adult: 'Adulte (18 ans et +)',
};

/* */
export const levels = {
  all: 'Tout niveau',
  beginner: 'Débutant',
  advanced: 'Espoir',
  expert: 'Confirmé',
};
