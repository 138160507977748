import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import React, { useState, useEffect } from 'react';
import {
  Box,
  Fab,
  Input,
  Paper,
  Select,
  MenuItem,
  IconButton,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import i18n from '_utils/i18n';
import master from '_utils/master';
import * as Push from '_redux/push';
import { Screen } from '_components/core';

/* */
const types = [
  'Date de dernier lavage',
  'Date du dernier chargement',
  'Nombre de lavages',
];
const operators = [
  '>',
  '>=',
  '=',
  '<',
  '<=',
];

const Criteria = ({ criteria, set, del }) => {
  /* */
  const [scenarioType, setType] = React.useState(criteria.type || '');
  const [scenarioOperator, setOperator] = React.useState(criteria.operator || '');
  const [scenarioVariable, setVariable] = React.useState(criteria.variable || '');

  useEffect(() => {
    set({
      type: scenarioType,
      operator: scenarioOperator,
      variable: scenarioVariable,
    });
  }, [set, scenarioType, scenarioOperator, scenarioVariable]);

  /* */
  return (
    <Box display="flex" flexDirection="row">
      <Box display="flex" flex="3" p={2}>
        <FormControl
          fullWidth
          margin="normal">
          <InputLabel html-for='select-type'>{'Type'}</InputLabel>
          <Select
            autoWidth
            value={scenarioType}
            onChange={(event) => setType(event.target.value)}
            inputProps={{
              name: 'type',
              id: 'select-type',
            }}>
            {types && types.map((type, idx) => (
              <MenuItem key={`type-${idx}`} value={idx}>{type}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box display="flex" flex="2" p={2}>
        <FormControl
          fullWidth
          margin="normal">
          <InputLabel html-for='select-operator'>{'Opérateur'}</InputLabel>
          <Select
            autoWidth
            value={scenarioOperator}
            onChange={(event) => setOperator(event.target.value)}
            inputProps={{
              name: 'operator',
              id: 'select-operator',
            }}>
            {operators && operators.map((operator, idx) => (
              <MenuItem key={`operator-${idx}`} value={idx}>{operator}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box display="flex" flex="3" p={2}>
        <FormControl
          fullWidth
          margin="normal">
          <InputLabel html-for='select-variable'>{'Variable'}</InputLabel>
          <Input
            value={scenarioVariable}
            onChange={(event) => setVariable(event.target.value)}
            inputProps={{
              name: 'variable',
              id: 'select-variable',
            }} />
        </FormControl>
      </Box>
      <Box display="flex" flex="1" p={2}>
        {Boolean(del) && (
          <IconButton size="small" onClick={del}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

/* */
const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
  },
}));

/* */
const mapStateToProps = ({ app: { station } }) => ({ station });

/* */
export default connect(mapStateToProps)(({ station }) => {
  const classes = useStyles();

  /* */
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [criterias, setCriterias] = useState([]);
  const [items, setItems] = useState([]);

  /* */
  useEffect(() => {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => Push.api.getAutomationList(master))
      .then((list) => list && setItems(list.map(({ createdAt, updatedAt, ...rest }) => ({
        ...rest,
        createdAt: createdAt && i18n.l('time.formats.short_y', new Date(createdAt)),
        updatedAt: updatedAt && i18n.l('time.formats.short_y', new Date(updatedAt)),
      }))))
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  }, [station]);

  /* */
  const addCriteria = (criteria = {}) => setCriterias([
    ...criterias,
    criteria,
  ]);

  /* */
  const setCriteria = (idx) => (value) => {
    // console.log(criterias[idx]);
    criterias[idx] = value;
  };

  /* */
  const removeCriteria = (idx) => () => {
    const newC = [...criterias];
    newC.splice(idx, 1);
    setCriterias(newC);
  };

  /* */
  const renderList = () => (!loading) && (<MaterialTable
    title="Liste des scénarios"
    data={items}
    options={{
      paging: true,
      pageSize: 10,
      toolbar: false,
      filtering: true,
      selection: false,
      showTitle: false,
      showHeader: false,
      toolbarButtonAlignment: 'right',
      pageSizeOptions: [10, 50, 100, 200],
    }}
    columns={[
      { field: 'id', hidden: true },
      { title: 'Nom', field: 'name' },
      { title: 'Date de création', field: 'createdAt', defaultSort: 'asc' },
      { title: 'Date de modification', field: 'updatedAt' },
    ]}
  />);

  /* */
  return (
    <Screen
      error={error}
      layout='fluid'
      title='Automation'
      >
        <Paper>
          <Box m={2} display="flex" justifyContent='right'>
            <Fab color="primary" aria-label="add" className={classes.fab} onClick={() => addCriteria()}>
              <AddIcon />
            </Fab>
          </Box>
          <br />
          {criterias.map((criteria, idx) => (
            <Criteria
              key={idx}
              criteria={criteria}
              set={setCriteria(idx)}
              del={idx && removeCriteria(idx)} />
          ))}
        </Paper>
        <br />
        <br />
        <br />
        {renderList()}
    </Screen>
  );
});
