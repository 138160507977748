import moment from 'moment';

/* */
const cbLogo = 'https://static.prima.golf/images/payment/cb.png';
const mcdLogo = 'https://static.prima.golf/images/payment/mcd.png';
const visaLogo = 'https://static.prima.golf/images/payment/visa.png';

/* */
export const getBrandName = (code) => {
  let name;
  switch (code) {
    case '1':
    case 'A':
    case 'B':
    case 'C':
    case 'D':
    case 'E':
      name = 'CB';
      break;
    case '2':
    case '8':
    case '9':
      name = 'VISA';
      break;
    case '0':
    case '3':
      name = 'MASTERCARD';
      break;
    default:
      name = null;
  }
  return name;
};

/* */
export const createUuidFrom = (id) => `${id.slice(0, -12)}${moment().format('x').slice(-12)}`;

/* */
export const anonymizeNumber = (str) => {
  if (str.length > 0) {
    return str.replace(/(\d+)(\d{4})/g, (match, start, end) => `${'*'.repeat(start.length)}${end}`);
  }
  return str;
};

/* */
export const getDueDateMY = (dueDate) => ({
  dueDateM: `${dueDate}`.substr(0, 2),
  dueDateY: `${dueDate}`.substr(2, 2),
});

/* */
export const getStatus = (dueDate, delay = 2) => {
  const expiredAt = moment(dueDate, 'MMYY');
  const deadline = moment().date(1).add(delay, 'months');

  const isExpired = expiredAt.diff(moment(), 'months', true) <= -1;
  const isSoonExpired = expiredAt.diff(deadline, 'months', true) <= 0;

  return {
    isExpired,
    isSoonExpired: !isExpired && isSoonExpired,
  };
};

/* */
export const getBrandImage = (name) => {
  let img;
  switch (name) {
    case 'CB':
      img = cbLogo;
      break;
    case 'VISA':
      img = visaLogo;
      break;
    case 'MASTERCARD':
      img = mcdLogo;
      break;
    default:
      img = null;
  }

  if (!img) return null;

  return img;
};
