import React, { memo } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule,
  ZoomableGroup,
} from 'react-simple-maps';
import i18n from '_utils/i18n';
import { Box, Typography } from '@material-ui/core';
/* */
import countries from './countries';
/* */
const MapChart = ({ setTooltipContent, highlighted }) => {
  return (
    <Box width='100%' height='100%' data-tip="">
      <ComposableMap
        width={800}
        height={550}
        projectionConfig={{
          rotate: [-10, 0, 0],
          scale: 147,
        }}
      >
        <ZoomableGroup>
          <Sphere stroke="#E4E5E6" strokeWidth={0.5} />
          <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
          <Geographies geography={countries}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const isHighlighted = highlighted
                  && highlighted.map((val) => val.values.map((e) => e.id).indexOf(geo.id) !== -1)
                    .indexOf(true) !== -1;
                return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    setTooltipContent((
                      <Box>
                        <Typography style={{ fontWeight: 'bold' }}>{i18n.t(`country.${geo.id}`)}</Typography>
                        {isHighlighted && highlighted.map((val, i) => (
                          <Typography key={i} style={{ fontWeight: '400' }}>
                            {`${val.name} : ${val.values[val.values.map((e) => e.id).indexOf(geo.id)].value || 0}`}
                          </Typography>
                        ))}
                      </Box>
                    ));
                  }}
                  onMouseLeave={() => {
                    setTooltipContent('');
                  }}
                  style={{
                    default: {
                      fill: isHighlighted ? '#4869c5' : '#D6D6DA',
                      outline: 'none',
                    },
                    hover: {
                      fill: isHighlighted ? '#1e3d92' : '#949494',
                      outline: 'none',
                    },
                    pressed: {
                      fill: isHighlighted ? '#1e3d92' : '#949494',
                      outline: 'none',
                    },
                  }}
                />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </Box>
  );
};
/* */
export default memo(MapChart);
