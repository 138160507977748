import React from 'react';
import MaskedInput from 'react-text-mask';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Paper,
  Typography,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import i18n from '_utils/i18n';

/* */
const styles = (theme) => ({
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  submitButton: {
    marginTop: theme.spacing(0),
  },
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
});

/* */
const DateOfBirthMaskedInput = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
    />
  );
};

/* */
class EditCustomerForm extends React.Component {
  /* */
  render() {
    const {
      errors,
      values,
      classes,
      readOnly,
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
    } = this.props;

    const buttonText = readOnly
      ? i18n.t('default.actions.get')
      : i18n.t('default.actions.save');

    return (
      <Grid
        container
        spacing={3}
        justify="center"
        direction='row'>
        <Grid item sm={12} md={6}>
          <Paper className={classes.paper}>
            <Typography
              gutterBottom
              component="h2"
              color="primary"
              variant="subtitle1">
              {'Profil'}
            </Typography>
            <TextField
              select
              name="gender"
              label="Civilité"
              variant="outlined"
              disabled={readOnly}
              value={values.gender}
              onChange={handleChange}
              className={classes.textField}
              SelectProps={{ native: true }}
              InputLabelProps={{ shrink: true }}>
              <option value="">{'-'}</option>
              <option value="male">{'M.'}</option>
              <option value="female">{'Mme'}</option>
            </TextField>
            <TextField
              fullWidth
              label="Prénom"
              name="firstname"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.firstname}
              error={!!(errors.firstname)}
              className={classes.textField}
              helperText={errors.firstname}
              InputProps={{ readOnly }}
              InputLabelProps={{ shrink: true }} />
            <TextField
              fullWidth
              label="Nom"
              name="lastname"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.lastname}
              error={!!(errors.lastname)}
              helperText={errors.lastname}
              className={classes.textField}
              InputProps={{ readOnly }}
              InputLabelProps={{ shrink: true }} />
            <TextField
              fullWidth
              name="email"
              label="E-mail"
              variant="outlined"
              onBlur={handleBlur}
              value={values.email}
              onChange={handleChange}
              error={!!(errors.email)}
              helperText={errors.email}
              className={classes.textField}
              InputProps={{ readOnly }}
              InputLabelProps={{ shrink: true }} />
            <TextField
              fullWidth
              name="dateOfBirth"
              label="Date de naissance"
              variant="outlined"
              onBlur={handleBlur}
              value={values.dateOfBirth}
              onChange={handleChange}
              error={!!(errors.dateOfBirth)}
              helperText={errors.dateOfBirth}
              className={classes.textField}
              placeholder="JJ/MM/AAAA"
              InputProps={{
                readOnly,
                inputComponent: DateOfBirthMaskedInput,
              }}
              InputLabelProps={{ shrink: true }} />
            <TextField
              fullWidth
              name="license"
              label="N° licence"
              variant="outlined"
              onBlur={handleBlur}
              value={values.license}
              onChange={handleChange}
              error={!!(errors.license)}
              helperText={errors.license}
              className={classes.textField}
              InputProps={{ readOnly }}
              InputLabelProps={{ shrink: true }} />
          </Paper>
        </Grid>
        <Grid item sm={12} md={6}>
          <Paper className={classes.paper}>
            <Typography
              gutterBottom
              component="h2"
              color="primary"
              variant="subtitle1">
              {'Coordonnées'}
            </Typography>
            <TextField
              fullWidth
              name="phone"
              label="Téléphone"
              variant="outlined"
              onBlur={handleBlur}
              value={values.phone}
              onChange={handleChange}
              error={!!(errors.phone)}
              helperText={errors.phone}
              className={classes.textField}
              InputProps={{ readOnly }}
              InputLabelProps={{ shrink: true }} />
            <TextField
              fullWidth
              name="addressLine"
              label="Adresse"
              variant="outlined"
              onBlur={handleBlur}
              value={values.addressLine}
              onChange={handleChange}
              error={!!(errors.addressLine)}
              helperText={errors.addressLine}
              className={classes.textField}
              InputProps={{ readOnly }}
              InputLabelProps={{ shrink: true }} />
            <TextField
              fullWidth
              name="addressLine2"
              variant="outlined"
              onBlur={handleBlur}
              value={values.addressLine2}
              onChange={handleChange}
              error={!!(errors.addressLine2)}
              helperText={errors.addressLine2}
              className={classes.textField}
              InputProps={{ readOnly }}
              InputLabelProps={{ shrink: true }} />
            <TextField
              fullWidth
              name="postalCode"
              label="Code postal"
              variant="outlined"
              onBlur={handleBlur}
              value={values.postalCode}
              onChange={handleChange}
              error={!!(errors.postalCode)}
              helperText={errors.postalCode}
              className={classes.textField}
              InputProps={{ readOnly }}
              InputLabelProps={{ shrink: true }} />
            <TextField
              fullWidth
              name="city"
              label="Ville"
              variant="outlined"
              onBlur={handleBlur}
              value={values.city}
              onChange={handleChange}
              error={!!(errors.city)}
              helperText={errors.city}
              className={classes.textField}
              InputProps={{ readOnly }}
              InputLabelProps={{ shrink: true }} />
            <TextField
              fullWidth
              name="country"
              variant="outlined"
              label="Pays"
              onBlur={handleBlur}
              value={values.country}
              onChange={handleChange}
              error={!!(errors.country)}
              helperText={errors.country}
              className={classes.textField}
              InputProps={{ readOnly }}
              InputLabelProps={{ shrink: true }} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={isSubmitting}
            className={classes.submitButton}>
            {isSubmitting
              ? <CircularProgress color="inherit" size={24} />
              : buttonText}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(EditCustomerForm);
