import React from 'react';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import {
  Box,
  Button, Chip,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, TextField, Typography,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Icon from '@mdi/react';
import { mdiPlusCircle } from '@mdi/js';
/* */
const styles = (theme) => ({
  addPlayer: {
    padding: 1,
    elevation: 0,
    height: '100%',
    display: 'flex',
    textAlign: 'center',
    borderRadius: 20,
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'space-around',
    '&:hover': {
      marginBottom: 1,
      elevation: 1,
      cursor: 'pointer',
    },
    '&:active': {
      marginTop: 2,
      elevation: 0,
      backgroundColor: '#FEFEFE',
      cursor: 'pointer',
    },
  },
  addPlayerNoClick: {
    padding: 1,
    elevation: 0,
    height: '100%',
    display: 'flex',
    textAlign: 'center',
    borderRadius: 20,
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'space-around',
  },
  modal: {
    position: 'absolute',
    backgroundColor: '#DDDDDD',
    zIndex: 1,
    width: '100%',
    marginLeft: -10,
    marginBottom: -15,
    height: '68%',
    bottom: 10,
    left: 10,
  },
  playerTable: {
    height: 'calc(100% - 280px)',
    position: 'absolute',
  },
  selected: {
    backgroundColor: '#9998E2',
    '&:hover': {
      backgroundColor: '#9998E2 !important',
    },
  },
  chip: {
    margin: theme.spacing(0.5),
  },
});

/* */
class TeeSheetRow extends React.Component {
  emptyPlayerButton = (value) => {
    const {
      classes,
      openPlayerSearch,
    } = this.props;
    let i;
    const items = [];
    // eslint-disable-next-line no-plusplus
    for (i = 0; i < 4 - value; i++) {
      items.push(
        <TableCell key={i} colSpan={2} style={{ position: 'relative', width: '200px', maxWidth: '200px' }}>
          <Paper
            className={classes.addPlayer}
            onClick={() => openPlayerSearch(false)}
          >
            <Box>
              <Icon path={mdiPlusCircle} size={3} color={'#999999'} className={classes.icons} key={'icon'} style={{ alignSelf: 'center' }}/>
            </Box>
          </Paper>
        </TableCell>,
      );
    }
    return items;
  }

  /* */
  render() {
    const {
      classes,
      isOpen,
      playerSearch,
      selectedHour,
      bookings,
      searchedPlayer,
      searchLoading,
      playerResult,
      addPlayer,
      playerExist,
      players,
      save,
      cancel,
      removePlayer,
      openPlayerList,
      openPlayerSearch,
      cancelSelectedPlayers,
      onSave,
    } = this.props;
    return (
      <Slide direction="up" key={'paperBox'} in mountOnEnter unmountOnExit>
        <Paper className={classes.modal} hidden={!isOpen}>
          <Table id='teeSheet' style={{ width: '100%', height: '100%' }}>
            <TableHead style={{ display: 'none' }}>
              <TableRow>
                <TableCell width={'10%'}/>
                <TableCell width={'10%'}/>
                <TableCell width={'10%'}/>
                <TableCell width={'10%'}/>
                <TableCell width={'10%'}/>
                <TableCell width={'10%'}/>
                <TableCell width={'10%'}/>
                <TableCell width={'10%'}/>
                <TableCell width={'10%'}/>
                <TableCell width={'10%'}/>
              </TableRow>
            </TableHead>
            <TableBody style={{ textAlign: 'center' }}>
              <TableRow style={{
                height: '10%',
                backgroundColor: '#EEEEEE',
                display: playerSearch && 'none',
              }}>
                <TableCell colSpan={10}>
                  <Box display={'flex'} flexWrap={'nowrap'} flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
                    <Box display={'flex'} flexDirection={'row'} alignContent={'center'} justifySelf={'flex-start'} height={50}>
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignSelf={'center'}
                        paddingRight={2}
                        style={{ textJustify: 'middle' }}>
                        <Select
                          labelId="hourSelect-label"
                          id="hourSelect"
                          value={selectedHour}
                          onChange={(n) =>
                            openPlayerList({
                              time: n.target.value,
                              players: bookings && bookings[bookings.findIndex((booking) =>
                                booking.time === n.target.value)].players,
                            })}
                        >
                          {bookings && bookings.map((booking, index) => (
                            <MenuItem key={`book_${index}`} value={booking.time}>{[booking.time.slice(0, 2), ':', booking.time.slice(2, 4)].join('')}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'flex-start'}
                        alignContent={'center'}
                        paddingRight={2}>
                      </Box>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} alignContent={'center'} justifySelf={'flex-end'}>
                      <Button
                        value={'test'}
                        variant={'outlined'}
                        color={'default'}
                        style={{ marginLeft: 10 }}
                        onClick={() => cancel()}>Annuler</Button>
                      <Button
                        value={'test'}
                        variant={'contained'}
                        color={'primary'}
                        style={{ marginLeft: 10 }}
                        disabled={onSave}
                        onClick={() => save()}>{onSave ? <CircularProgress style={{ color: '#868686' }} /> : 'Enregistrer'}</Button>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
              <Slide in={!playerSearch} direction={'up'} mountOnEnter unmountOnExit>
                <TableRow style={{ height: '100%', width: '100%' }}>
                  <TableCell colSpan={1}
                             style={{
                               paddingInline: 5,
                               maxWidth: '200px',
                             }}>
                  </TableCell>
                  {players.map((player, key) => (
                    <TableCell key={key} colSpan={2} style={{ position: 'relative', width: '200px', maxWidth: '200px' }}>
                      { player.tid &&
                        <Box
                          style={{
                            position: 'absolute',
                            cursor: 'pointer',
                            top: 25,
                            right: 25,
                          }}
                          onClick={() =>
                            removePlayer(player)
                          }
                        >
                          <CancelIcon size={1} style={{ verticalAlign: 'middle', color: '#ac3b3b' }}/>
                        </Box>
                      }
                      <Paper
                        className={classes.addPlayerNoClick}
                      >
                        <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} alignContent={'center'} height={'100%'}>
                          <Typography style={{ marginTop: 25, marginBottom: -50 }} variant={'h6'}>{player.firstname} {player.lastname}</Typography>
                          <Typography style={{ marginTop: 25, marginBottom: -50 }} variant={'subtitle1'}>{player.tid && player.tid}</Typography>
                          <Box marginTop={'-30px'}>
                            {(player.index) && (
                              <h4 style={{ marginBlock: 5 }}>Index</h4>)}
                            <Box position="relative" display="inline-flex" justifyContent={'center'}>
                              <CircularProgress size={75} variant="determinate" value={ player.index && 100 - (player.index * 100) / 54 } />
                              <Box
                                top={0}
                                left={0}
                                bottom={0}
                                right={0}
                                position="absolute"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Typography variant="caption" component="div" color="textSecondary">{ player.index && player.index }</Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                          </Box>
                        </Box>
                      </Paper>
                    </TableCell>
                  ))}
                  {this.emptyPlayerButton(players.length)}
                  <TableCell colSpan={1}/>
                </TableRow>
              </Slide>
              <Slide in={playerSearch} direction={'up'} mountOnEnter unmountOnExit>
                <TableRow style={{ height: '100%' }}>
                  <TableCell colSpan={8} style={{ paddingInline: 5, paddingLeft: 10 }}>
                    <Paper style={{
                      width: '100%', height: '100%', paddingInline: 20, paddingBlock: 10,
                    }}>
                      <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignContent={'center'}>
                        <TextField
                          label={'Recherche'}
                          style={{
                            marginLeft: 20,
                            width: '90%',
                            userSelect: 'none',
                            paddingBottom: '20px',
                          }}
                          onChange={searchedPlayer}
                          placeholder={'Nom, prénom ou e-mail'}
                        />
                      </Box>
                      <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignContent={'flex-start'} position={'relative'} height={'calc(100% - 50px)'}>
                        <Box style={{
                          height: 'calc(100% - 20px)',
                          borderRadius: '5px',
                          position: 'absolute',
                          width: '100%',
                          overflowY: 'scroll',
                        }}>
                          <Table stickyHeader aria-label="sticky table" id='teeSheet' className={classes.playerTable}>
                            <TableHead>
                              <TableRow style={{ width: '100%', textAlign: 'center', userSelect: 'none' }}>
                                <TableCell style={{ width: '20%', height: '60px', textAlign: 'center', fontWeight: 'bold' }}>{'Nom complet'}</TableCell>
                                <TableCell style={{ width: '20%', height: '60px', textAlign: 'center', fontWeight: 'bold' }}>{'Index'}</TableCell>
                                <TableCell style={{ width: '20%', height: '60px', textAlign: 'center', fontWeight: 'bold' }}>{'E-mail'}</TableCell>
                                <TableCell style={{ width: '20%', height: '60px', textAlign: 'center', fontWeight: 'bold' }}>{'Téléphone'}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {!searchLoading && !playerResult && (<Typography align={'center'}>{'Veuillez entrer au minimum 3 charactères...'}</Typography>)}
                              {!searchLoading && playerResult && playerResult.length === 0 && (
                                <TableRow>
                                  <TableCell size="medium" align="center" colSpan="5">
                                    <AccountCircleIcon color="disabled" style={{ color: '#737b83', fontSize: 64 }} />
                                    <Typography style={{ color: '#737b83', userSelect: 'none' }} variant="h6">
                                      {'Aucun client trouvé.'}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              )}
                              {searchLoading && (
                                <TableRow>
                                  <TableCell size="medium" align="center" colSpan="5">
                                    <CircularProgress style={{ position: 'absolute', left: '50%', bottom: '-100%' }} />
                                  </TableCell>
                                </TableRow>)}
                              {!searchLoading && playerResult && playerResult
                                .sort((a, b) => {
                                  if (a.lastname < b.lastname) { return -1; }
                                  if (a.lastname > b.lastname) { return 1; }
                                  return 0;
                                })
                                .map(({
                                  id,
                                  matches,
                                  lightspeed,
                                  ffg,
                                  attachmentGolf,
                                  ...data
                                },
                                i) => !playerExist({ tid: matches[0].reference }) && (
                                  <TableRow
                                    hover
                                    style={{
                                      textAlign: 'center',
                                      cursor: 'pointer',
                                      userSelect: 'none',
                                    }}
                                    className={playerExist({ tid: matches[0].reference }) ?
                                      classes.selected : null}
                                    onClick={(n) => (
                                      (addPlayer({
                                        id: id || '',
                                        tid: matches[0].reference,
                                        matches,
                                        firstname: data.firstname,
                                        lastname: data.lastname,
                                        email: data.email,
                                        phone: data.phone,
                                        price: 0,
                                        initPrice: 0,
                                      })) && (
                                        (players.length < 4 &&
                                          !players[
                                            players.findIndex((player) =>
                                              player.tid ===
                                              matches[0].reference)].bookingId) &&
                                        n.target.parentNode.classList.toggle(classes.selected)) &&
                                      (players.length === 4 &&
                                        n.target.parentNode.classList.remove(classes.selected))
                                    )}
                                    key={i}>
                                    <TableCell
                                      style={{ textAlign: 'center' }}>
                                      {' '}
                                      {data.firstname}
                                      {' '}
                                      {data.lastname}<br />
                                      {attachmentGolf && (
                                        <Typography variant="caption" color="primary">
                                          {attachmentGolf.name.replace(/\[NoProd]/g, '')}
                                        </Typography>
                                      )}
                                    </TableCell>
                                    <TableCell component="th" scope="row"
                                               style={{ textAlign: 'center' }}>
                                      {(ffg && ffg.handicap) || ''}
                                    </TableCell>
                                    <TableCell
                                      style={{ textAlign: 'center' }}>{data.email}
                                    </TableCell>
                                    <TableCell
                                      style={{ textAlign: 'center' }}>{data.phone}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Box>
                    </Paper>
                  </TableCell>
                  <TableCell colSpan={1} style={{ paddingInline: 5, paddingRight: 10, width: '150px' }}>
                    <Paper style={{
                      height: '100%', width: '300px', paddingInline: 20, paddingBlock: 10,
                    }}>
                      <Box display={'flex'} flexDirection={'column'} alignContent={'space-between'}>
                        {'Joueurs séléctionnés'}
                        <br/>
                        ({players.length} / 4)
                        {players.map((player, i) =>
                          <li style={{ listStyle: 'none' }} key={i}>
                            <Chip
                              label={`${player.firstname} ${player.lastname}`}
                              onDelete={() => addPlayer({
                                tid: player.tid,
                              })}
                              deleteIcon={player.bookingId && <span hidden={true}/>}
                              className={classes.chip}
                            />
                          </li>)}
                        <Box display={'flex'} flexDirection={'column'} alignSelf={'center'} justifySelf={'flex-end'} position={'absolute'} bottom={25}>
                          <Button
                            value={'test'}
                            variant={'contained'}
                            color={'primary'}
                            style={{ marginInline: 5, marginBottom: 10 }}
                            onClick={() => openPlayerSearch(true)}>Enregistrer</Button>
                          <Button
                            value={'test'}
                            variant={'outlined'}
                            color={'default'}
                            style={{ marginInline: 5, marginBottom: 10 }}
                            onClick={() =>
                              (cancelSelectedPlayers())}>Annuler</Button>
                        </Box>
                      </Box>
                    </Paper>
                  </TableCell>
                </TableRow>
              </Slide>
            </TableBody>
          </Table>
        </Paper>
      </Slide>
    );
  }
}
/* */
export default withStyles(styles, { withTheme: true })(TeeSheetRow);
