import gql from 'graphql-tag';

import actionTypes from './constants';

// -----------------------------------------------------------------------------
//
// -----------------------------------------------------------------------------

/* */
export const getPushLists = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`${actionTypes.gql.GET_PUSH_LISTS}`,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => (data.getPushLists)));

/* */
export const getPushList = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`${actionTypes.gql.GET_PUSH_LIST}`,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => (data.getPushList)));

/* */
export const createList = (client, variables) => (
  client.mutate({
    mutation: gql`${actionTypes.gql.SET_PUSH_LIST}`,
    variables,
  })
    .then((res) => res.data)
    .then((data) => (data.setPushList)));

/* */
export const deleteList = (client, variables) => (
  client.mutate({
    mutation: gql`${actionTypes.gql.DELETE_PUSH_LIST}`,
    variables,
  })
    .then((res) => res.data)
    .then((data) => (data.deletePushList)));

/* */
export const pushListAddAccounts = (client, variables) => (
  client.mutate({
    mutation: gql`${actionTypes.gql.PUSH_LIST_ADD_ACCOUNTS}`,
    variables,
  })
    .then((res) => res.data)
    .then((data) => (data.pushListAddAccounts)));

/* PUSH LIST DELETE ACCOUNT */
export const pushListDeleteAccounts = (client, variables) => (
  client.mutate({
    mutation: gql`${actionTypes.gql.PUSH_LIST_DELETE_ACCOUNTS}`,
    variables,
  })
    .then((res) => res.data)
    .then((data) => (data.pushListDeleteAccounts)));

// -----------------------------------------------------------------------------
//
// -----------------------------------------------------------------------------

/* */
export const getPushMessages = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`${actionTypes.gql.GET_PUSH_MESSAGES}`,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => (data.getPushMessages)));

/* */
export const getPushMessage = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`${actionTypes.gql.GET_PUSH_MESSAGE}`,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => (data.getPushMessage)));

/* */
export const createMessage = (client, variables) => (
  client.mutate({
    mutation: gql`${actionTypes.gql.SET_PUSH_MESSAGE}`,
    variables,
  })
    .then((res) => res.data)
    .then((data) => (data.setPushMessage)));

/* */
export const sendMessage = (client, variables) => (
  client.mutate({
    mutation: gql`${actionTypes.gql.SEND_PUSH_MESSAGE}`,
    variables,
  })
    .then((res) => res.data)
    .then((data) => (data.sendPushMessage)));

// -----------------------------------------------------------------------------
//
// -----------------------------------------------------------------------------

/* */
export const getPushCampaigns = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`${actionTypes.gql.GET_PUSH_CAMPAIGNS}`,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => (data.getPushCampaigns)));

/* */
export const getPushCampaign = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`${actionTypes.gql.GET_PUSH_CAMPAIGN}`,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => (data.getPushCampaign)));

/* */
export const setPushCampaign = (client, variables) => (
  client.mutate({
    mutation: gql`${actionTypes.gql.SET_PUSH_CAMPAIGN}`,
    variables,
  })
    .then((res) => res.data)
    .then((data) => (data.setPushCampaign)));

/* */
export const deletePushCampaign = (client, variables) => (
  client.mutate({
    mutation: gql`${actionTypes.gql.DELETE_PUSH_CAMPAIGN}`,
    variables,
  })
    .then((res) => res.data)
    .then((data) => (data.deletePushCampaign)));

/* */
export const sendPushCampaign = (client, variables) => (
  client.mutate({
    mutation: gql`${actionTypes.gql.SEND_PUSH_CAMPAIGN}`,
    variables,
  })
    .then((res) => res.data)
    .then((data) => (data.sendPushCampaign)));

// -----------------------------------------------------------------------------
//
// -----------------------------------------------------------------------------

/* */
export const getAutomationList = () => Promise.resolve([]);
// export const getAutomationList = (client, variables) => (
//   client.query({
//     query: gql`${actionTypes.gql.GET_AUTOMATION_LIST}`,
//     variables,
//   })
//     .then((res) => res.data)
//     .then((data) => (data.getAutomationList)));
