import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import {
  Box,
  Paper,
  Button,
  Typography,
  Select,
  MenuItem,
  ListItem,
  ListItemIcon,
} from '@material-ui/core';
/* */
import master from '_utils/master';
import { Screen } from '_components/core';
import * as Booking from '_redux/booking';
import * as Customer from '_redux/customer';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { mdiGolf } from '@mdi/js';
import Icon from '@mdi/react';
import moment from 'moment';
import TeeSheets from '_components/elements/TeeSheets/TeeSheets';
import Indicators from '_components/elements/TeeSheets/Elements/Indicators';
import DateSelector from '_components/elements/TeeSheets/DateSelector';
/* */
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  paperHead: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  header: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    margin: 3,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
  picker: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
  wrapper: {
    border: '0, solid, #FFFFFF',
  },
}));
/* */
const Customers = ({ golf }) => {
  /* */
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState({ reload: false, withLoading: true });
  // eslint-disable-next-line no-unused-vars
  const [searchLoading, setSearchLoading] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [accessories, setAccessories] = useState([]);
  const [selectedDate, setSelectedDate] = useState({
    month: moment().month() + 1,
    day: moment().date(),
    year: moment().year(),
  });
  const [courses, setCourses] = useState([]);
  const [golfId, setGolfId] = useState(golf.id);
  const [selectedGolf, setSelectedGolf] = useState(golf);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedHour, setSelectedHour] = useState('');
  const [players, setPlayers] = useState([]);
  const [searchedPlayer, setSearchedPlayer] = useState('');
  moment.suppressDeprecationWarnings = true;
  const activeCourse = (course) => {
    setSelectedCourse(course);
  };

  /* */
  const onSelectDate = (day) => {
    setReload({ reload: true, withLoading: true });
    setSelectedDate(day);
  };
  /* */
  const onError = () => {
    setTimeout(() => setError(null), 1000);
  };
  /* */
  // Golf Data
  useEffect(() => {
    Promise.resolve()
      .then(() => reload.withLoading && setLoading(true))
      .then(() => Booking.api.selectGolf(master, {
        id: golfId,
      }))
      .then((data) => {
        setSelectedGolf(data.golf);
        setAccessories(data.accessories);
        setCourses(
          data.courses.filter((course) =>
            course.dayOpen !== 0 &&
              course.dates.length > 0),
        );
        Booking.api.getCourseSchedule(master, {
          golfId: golfId,
          courseId: (selectedCourse && data.courses[selectedCourse].id) || data.courses[0].id,
          courseType: (selectedCourse && data.courses[selectedCourse].type) || data.courses[0].type,
          fullday: 1,
          players: [],
          date: `${selectedDate.year}-${selectedDate.month < 10 ? `0${selectedDate.month}` : selectedDate.month}-${selectedDate.day}`,
          admin: 1,
        }).then((listBookings) => {
          listBookings.courseSchedule && setBookings(listBookings.courseSchedule.teetimes);
        }).catch((e) => {
          setBookings(null);
          console.debug(e);
        });
      })
      .then(() => selectedCourse === '' && setSelectedCourse('0'))
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setReload({ reload: false, withLoading: reload.withLoading });
        reload.withLoading && setLoading(false);
      });
  }, [golfId, selectedCourse, selectedDate, reload.reload, reload.withLoading, selectedGolf.id]);

  /* */
  // Players Data
  const getPlayers = () => {
    setSearchLoading(true);

    setTimeout(() => Customer.api.search(master, {
      golfId: golfId,
      query: searchedPlayer || null,
    })
      .then((result) => {
        const allPlayers = result.map((player) => ({
          id: player.id,
          tid: player.matches &&
            player.matches[player.matches.findIndex((item) =>
              item.golfId === golf.id)].reference,
          firstname: player.firstname,
          lastname: player.lastname,
          email: player.email,
          phone: player.phone,
          ffg: player.ffg,
          attachmentGolf: player.attachmentGolf,
          matches: player.matches || [{}],
        }));
        setPlayers(allPlayers);
      })
      .catch((e) => console.debug(e))
      .finally(() => {
        setSearchLoading(false);
      }), 1000);
  };
  useEffect(getPlayers, [golfId, searchedPlayer]);
  useEffect(onError, [error]);
  /* */
  return (
    <Screen
      error={error}
      layout="fullWidth">
      <Paper className={classes.paperHead}>
        <Box
          mb={2}
          display="flex"
          alignItems="center"
          flexDirection="row"
          className={classes.header}>
          <Box className={classes.title} display={'flex'} flexDirection={'row'} justifyContent={'start'} alignItems={'center'} width={'100%'}>
            <WatchLaterIcon style={{ margin: 8 }} />
            <Typography
              gutterBottom
              variant="h5"
              align="center"
              component="h2"
              style={{
                margin: 0, fontSize: 14, fontWeight: 'bold', letterSpacing: -0.8, justifySelf: 'flex-start',
              }}>
              {'Tee Sheets'}
            </Typography>
            <Button
              style={{
                letterSpacing: -0.8, textTransform: 'capitalize', justifySelf: 'flex-end', display: 'none',
              }}
              color="primary"
              variant="contained">
              {'Ajouter'}<ArrowDropDownIcon/>
            </Button>
          </Box>
        </Box>
      </Paper>
      <Paper className={classes.paperHead}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} marginRight={2} marginLeft={2}>
          <Indicators
            /*
            // TODO Linking weather, hole and players to indicators
            weather={{ type: 'rain', temp: 11 }}
            totalHolesCount={0}
            totalCartsCount={0}
            totalPlayersCount={0}
            */
          />
          <Box display={'flex'}
               flexDirection={'row'}
               justifyContent={'center'}
               alignContent={'center'}
               flex={3}>
            <DateSelector
              onChange={onSelectDate}
              selectedDate={selectedDate}
              variant={'large'}/>
          </Box>
          <Box display={'flex'}
               flexDirection={'row'}
               justifyContent={'flex-end'}
               flex={2}>
          </Box>
        </Box>
        <Box flex={'1'} display={'flex'} flexDirection={'row'}>
          <Select
            style={{
              flex: 11, textAlign: 'center', borderRadius: 5, paddingBlock: 0, letterSpacing: -0.8, margin: 8, textTransform: 'capitalize',
            }} value={selectedGolf.id} onChange={(n) => setGolfId(n.target.value)}>
            <MenuItem component={'ul'} value={'65973ed0-8893-11ea-bc55-0242ac130003'} key={'65973ed0-8893-11ea-bc55-0242ac130003'} alignItems={'center'}>
              <ListItem alignItems={'center'} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                Planning - Domaine du Gouverneur
              </ListItem>
            </MenuItem>
            <MenuItem component={'ul'} value={'65973c5a-8893-11ea-bc55-0242ac130003'} key={'65973c5a-8893-11ea-bc55-0242ac130003'} alignItems={'center'}>
              <ListItem alignItems={'center'} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                Planning - Golf de la Bresse
              </ListItem>
            </MenuItem>
          </Select>
        </Box>
        <Box display={'flex'} justifyContent={'center'}>
          <Select
            style={{
              flex: 11, textAlign: 'center', borderRadius: 5, paddingBlock: 0, letterSpacing: -0.8, margin: 8, textTransform: 'capitalize',
            }} value={selectedCourse} onChange={(n) => setSelectedCourse(n.target.value)}>
            {courses.filter((course) => course.id.includes('P')).map((n, index) => (
              <MenuItem component={'ul'} value={index} key={index} alignItems={'center'}>
                <ListItem alignItems={'center'} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                  <ListItemIcon style={{
                    textAlign: 'center',
                  }}>
                    <Icon path={mdiGolf} size={1} key={'icon'}/>
                    <Typography style={{ paddingTop: 5 }}>
                      {n.name} ({n.type === '1' ? '9' : '18'} trous)
                    </Typography>
                  </ListItemIcon>
                </ListItem>
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Paper>
      <TeeSheets
        onChange={onSelectDate}
        bookings={bookings}
        courses={courses}
        golf={golf}
        selectedGolf={selectedGolf}
        loading={loading}
        error={error}
        setError={(n) => setError(n)}
        reload={reload}
        setReload={(n) => setReload(n)}
        accessories={accessories}
        selectedCourse={selectedCourse}
        onChangeCourse={(n) => activeCourse(n)}
        onChangeHour={(n) => setSelectedHour(n)}
        selectedHour={selectedHour}
        searchLoading={searchLoading}
        searchedPlayer={(n) => (n.target.value.length >= 3 ? setSearchedPlayer(n.target.value) : setSearchedPlayer(''))}
        playerResult={players}
        selectedDate={selectedDate}
        timePerHole={{ hours: 0, minutes: 9, seconds: 0 }}
        event={[
          {
            eventName: 'test',
            eventStartingDateTime: '2021-03-20 12:00:00',
            eventEndingDateTime: '2021-03-20 16:00:00',
            eventTimePerHole: { hours: 0, minutes: 5, seconds: 0 },
            eventColor: '#456321',
          },
        ]}
      />
    </Screen>
  );
};
/* */
const mapStateToProps = ({
  app,
  app: { golf },
  bookingData,
  bookingSearch,
}) => ({
  ...app,
  ...golf,
  data: bookingData,
  search: bookingSearch,
});
/* */
export default connect(
  mapStateToProps,
  Booking.actions,
)(Customers);
