import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Chip,
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';
/* */
import helpers from '_utils/helpers';
import { Autocomplete } from '@material-ui/lab';

const PERIODS = helpers.date.getPeriods();

/* */
const useStyles = makeStyles((theme) => ({
  root: {
    height: 24,
    fontSize: 11,
    borderRadius: 12,
    margin: '0 6px 6px 0',
    [theme.breakpoints.down('md')]: {
      margin: '0.5rem',
    },
  },
  textField: {
    marginRight: theme.spacing(3),
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}));

/* */
export default ({
  values,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  setOpenPlayers,
  openPlayers,
  subscriptionOptions,
  playersOptions,
  playersLoading,
  loadFilteredPlayers,
}) => {
  const classes = useStyles();

  /* */
  const setDateRangeInput = (period) => () => {
    setFieldValue('to', PERIODS[period][1]);
    setFieldValue('from', PERIODS[period][0]);
  };

  const setSubscriptionValue = (event) => {
    if (event.target.value) {
      setFieldValue('subscription', event.target.value);
      setFieldValue('player', { firstname: 'Tous', lastname: '', id: '0' });
      const nextValues = {
        subscription: event.target.value,
      };
      loadFilteredPlayers({ player: '', values: nextValues });
    }
  };

  /* */
  return (
    <React.Fragment>
      <Box
        className={classes.filters}
        mb={2}>
        <TextField
          label="Du"
          name="from"
          value={values.from}
          onChange={handleChange}
          placeholder="jj/mm/aaaa"
          InputLabelProps={{ shrink: true }} />
        <TextField
          label="Au"
          name="to"
          value={values.to}
          onChange={handleChange}
          placeholder="jj/mm/aaaa"
          className={classes.textField}
          InputLabelProps={{ shrink: true }} />
        <Box>
          <Box>
            <Chip
              color="primary"
              label="Aujourd'hui"
              classes={{ root: classes.root }}
              onClick={setDateRangeInput('today')} />
            <Chip
              color="primary"
              label="Cette semaine"
              classes={{ root: classes.root }}
              onClick={setDateRangeInput('thisWeek')} />
            <Chip
              color="primary"
              label="Ce mois"
              classes={{ root: classes.root }}
              onClick={setDateRangeInput('thisMonth')} />
            <Chip
              color="primary"
              label="Cette année"
              classes={{ root: classes.root }}
              onClick={setDateRangeInput('thisYear')} />
          </Box>
          <Box>
            <Chip
              label="Hier"
              classes={{ root: classes.root }}
              onClick={setDateRangeInput('yesterday')} />
            <Chip
              label="La semaine dernière"
              classes={{ root: classes.root }}
              onClick={setDateRangeInput('lastWeek')} />
            <Chip
              label="Le mois dernier"
              classes={{ root: classes.root }}
              onClick={setDateRangeInput('lastMonth')} />
            <Chip
              label="L'année dernière"
              classes={{ root: classes.root }}
              onClick={setDateRangeInput('lastYear')} />
          </Box>
        </Box>
        <Box p={2}>
          <TextField
            select
            name="subscription"
            label="Contrat"
            style={{ flex: 1 }}
            value={values.subscription}
            onChange={(event) => setSubscriptionValue(event)}
            className={classes.textField}
            SelectProps={{ native: true }}>
            <option value="0">{'Tous'}</option>
            {subscriptionOptions
              .map((subscription) => <option key={subscription.id} value={subscription.id}>{subscription.name}</option>)}
          </TextField>
        </Box>
        <Box p={2}>
          <Autocomplete
            id="asynchronous-demo"
            style={{ width: 300 }}
            open={openPlayers}
            onOpen={() => {
              setOpenPlayers(true);
            }}
            onClose={() => {
              setOpenPlayers(false);
            }}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
            options={playersOptions}
            value={values.player}
            loading={playersLoading}
            onChange={(event, value) => { values.player = value }}
            onInputChange={(x) => loadFilteredPlayers({ player: x && x.target && x.target.value, values })}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Joueur"
                variant="outlined"
                className={classes.textField}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {playersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end">
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitting}>
          {isSubmitting
            ? <CircularProgress color="inherit" size={24} />
            : 'Rechercher'}
        </Button>
      </Box>
    </React.Fragment>
  );
};
