export default {
  gql: {
    GET_TEACHERS: `
      query($golfId: String!) {
        getSchoolTeachers(golfId: $golfId) {
          id
          lastname
          firstname
        }
      }
    `,
    GET_LESSONS: `
      query(
        $golfId: String!
        $level: String
        $day: Int
        $teachers: [String!]
      ) {
        getSchoolLessons(
          golfId: $golfId
          day: $day
          level: $level
          teachers: $teachers
        ) {
          id
          title
          teacherId
          teacher
          age
          level
          day
          places
          nextSlot
          slot {
            id
            date
            endHour
            startHour
          }
        }
      }
    `,
    GET_LESSON_STUDENTS: `
      query(
        $ids: [String]
      ) {
        getSchoolLessonSlotStudents(
          ids: $ids
        ) {
          id
          lastname
          firstname
          tid
          comment
          title
          teacher
          age
          level
          date
          hour
        }
      }
    `,
    ADD_LESSON_STUDENT: `
      mutation (
        $id: String!
        $student: SchoolStudentInput!
      ) {
        addLessonSlotStudent(
          id: $id
          student: $student
      ) {
          success
        }
      }
    `,
    GET_REGISTRATIONS: `
      query (
        $tids: [String!]
        $golfId: String!
      ) {
        getSchoolStudentRegistrations(
          tids: $tids
          golfId: $golfId
        ) {
          id
          title
          teacherId
          teacher
          age
          level
          day
          places
          nextSlot
          slot {
            id
            date
            endHour
            startHour
          }
        }
      }
    `,
  },
};
