import gql from 'graphql-tag';

// -----------------------------------------------------------------------------
// Stripe charges
// -----------------------------------------------------------------------------
const listStripeCharges = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`
      query(
        $to: String
        $from: String
        $golfId: String
        $accountReference: String
      ) {
        listStripeCharges(
          to: $to
          from: $from
          golfId: $golfId
          accountReference: $accountReference
        ) {
          id
          amount
          status
          outcome
          metadata
          createdAt
          cardLast4
          matchedAt
          description
          installmentId
          cardExpiration
          accountReference
          customer {
            id
            lastname
            firstname
          }
        }
      }
    `,
    variables,
    fetchPolicy,
  })
    .then(({ data }) => data.listStripeCharges));

// -----------------------------------------------------------------------------
// Stripe installments
// -----------------------------------------------------------------------------
const listStripeInstallments = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`
      query(
        $to: String
        $from: String
        $golfId: String
        $accountReference: String
      ) {
        listStripeInstallments(
          to: $to
          from: $from
          golfId: $golfId
          accountReference: $accountReference
        ) {
          id
          name
          state
          endsAt
          amount
          dueDate
          chargeId
          startsAt
          createdAt
          matchedAt
          totalAmount
          accountReference
          customer {
            id
            lastname
            firstname
          }
        }
      }
    `,
    variables,
    fetchPolicy,
  })
    .then(({ data }) => data.listStripeInstallments));

// -----------------------------------------------------------------------------
// List potential payers
// -----------------------------------------------------------------------------
const listPotentialPayers = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`
      query(
        $golfId: String!
      ) {
        listPayersWithUnmatchedCharges(
          golfId: $golfId
        ) {
          id
          firstname
          lastname
          lightspeed {
            id
            reference
          }
        }
      }
    `,
    variables,
    fetchPolicy,
  })
    .then(({ data }) => data.listPayersWithUnmatchedCharges));

// -----------------------------------------------------------------------------
// Match debtor with payer
// -----------------------------------------------------------------------------
const matchDebtorWithPayer = (client, variables) => (
  client.mutate({
    mutation: gql`
      mutation(
        $golfId: String!
        $payerReference: String!
        $debtorReference: String!
      ) {
        matchDebtorWithPayer(
          golfId: $golfId
          payerReference: $payerReference
          debtorReference: $debtorReference
        ) {
          success
        }
      }
    `,
    variables,
  })
    .then(({ data }) => data.matchDebtorWithPayer));

// -----------------------------------------------------------------------------
// Match charge with installment
// -----------------------------------------------------------------------------
const matchChargeWithInstallment = (client, variables) => (
  client.mutate({
    mutation: gql`
      mutation(
        $golfId: String!
        $chargeId: String!
        $installmentId: String!
      ) {
        matchChargeWithInstallment(
          golfId: $golfId
          chargeId: $chargeId
          installmentId: $installmentId
        ) {
          success
        }
      }
    `,
    variables,
  })
    .then(({ data }) => data.matchChargeWithInstallment));

export {
  listStripeCharges,
  listPotentialPayers,
  matchDebtorWithPayer,
  listStripeInstallments,
  matchChargeWithInstallment,
};
