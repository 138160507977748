import React, { useEffect } from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import { createValidationSchema, validators } from '_utils/validation';

import config from '_config';
/* */
const validationSchema = createValidationSchema({
  body: validators.required,
  title: validators.required,
  date: validators.required,
});

/* */
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  hiddenInput: {
    visibility: 'hidden',
    height: 0,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textFieldBanner: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: 240,
    width: 520,
  },
  confirmNotification: {
    whiteSpace: 'pre-line',
    padding: theme.spacing(2),
    borderBottom: '1px solid #ccc',
  },
}));

/* */
export default ({
  open, onClose, onSubmit, nbSelected, isSubmitting,
}) => {
  const classes = useStyles();
  const inputRef = React.useRef(null);

  const [body, setBody] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [data, setData] = React.useState({});
  const [image, setImage] = React.useState(null);
  const [errorFormat, setErrorFormat] = React.useState([]);
  const [date, setDate] = React.useState(moment().format('YYYY-MM-DD HH:mm'));
  const [confirm, setConfirm] = React.useState(false);

  useEffect(() => {
    if (image) {
      setErrorFormat([]);
      if (
        image.type !== 'image/jpg' &&
        image.type !== 'image/png' &&
        image.type !== 'image/gif' &&
        image.type !== 'image/jpeg'
      ) {
        setImage(null);
        setErrorFormat((old) => [
          ...old,
          'Le fichier doit être dans un de ces formats (jpg, jpeg, png, gif).',
        ]);
      }
      if (image.size > 2000000) {
        setImage(null);
        setErrorFormat((old) => [
          ...old,
          'Le fichier doit faire moins de 2Mo.',
        ]);
      }
    }
  }, [image]);

  // Reset name on open/close
  React.useEffect(() => {
    setBody(
      config.pushDebug
        ? `Programmé le ${moment().format('DD/MM/YYYY')} à ${moment().format(
          'HH:mm:ss',
        )} par Julien`
        : '',
    );
    setTitle(config.pushDebug ? 'Notification programmé de test' : '');
    setData({});
    setDate(moment().format('YYYY-MM-DD HH:mm'));
    setConfirm(false);
  }, [open]);

  const showRefContent = () => inputRef.current.click();
  const handleChangeImage = (e, handleChange) => {
    setImage(e.target.files[0]);
    handleChange(e);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle id="openNotification-title">
          {'Envoyer une notification.'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="openNotification-description">
            {'Envoyer une notification aux contacts sélectionnés.'}
          </DialogContentText>
          <Box mb={2}>
            <Formik
              onSubmit={(values) => {
                setBody(values.body);
                setTitle(values.title);
                setData({
                  link: values.link,
                });
                setDate(values.date);
                setConfirm(true);
              }}
              initialValues={{
                body,
                title,
                link: data && data.link,
                date,
              }}
              validationSchema={validationSchema}
            >
              {({
                errors, values, handleBlur, handleChange, handleSubmit,
              }) => (
                <form
                  id="openNotification-form"
                  onSubmit={handleSubmit}
                  className={classes.container}
                  autoComplete="off"
                >
                  <TextField
                    fullWidth
                    name="date"
                    margin="normal"
                    variant="outlined"
                    type="datetime-local"
                    onBlur={handleBlur}
                    value={values.date || moment().format('YYYY-MM-DD HH:mm')}
                    onChange={handleChange}
                    error={!!errors.date}
                    className={classes.textField}
                    label="Programmé ?"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText="Date et heure de programmation (ne pas modifier pour un envois immédiat)."
                  />
                  <TextField
                    required
                    fullWidth
                    name="title"
                    margin="normal"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.title}
                    onChange={handleChange}
                    error={!!errors.title}
                    className={classes.textField}
                    label="Titre de la notification"
                    helperText="Titre de la notification qui apparait dans le centre de notification du contact."
                  />
                  <TextField
                    rows="3"
                    required
                    fullWidth
                    multiline
                    name="body"
                    margin="normal"
                    label="Message"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.body}
                    onChange={handleChange}
                    error={!!errors.body}
                    className={classes.textField}
                    helperText="Contenu de la notification qui apparait quand la notification est ouverte par le contact."
                  />
                  <TextField
                    fullWidth
                    name="link"
                    margin="normal"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.link || ''}
                    onChange={handleChange}
                    error={!!errors.link}
                    className={classes.textField}
                    label="Lien de redirection (optionnel)"
                    helperText="Lien vers lequel sera redirigé le contact lorsqu'il cliquera sur la notification."
                  />
                  <input
                    type="file"
                    name="image"
                    onBlur={handleBlur}
                    multiple={false}
                    ref={inputRef}
                    onChange={(e) => handleChangeImage(e, handleChange)}
                    style={{ visibility: 'hidden', height: 0 }}
                  />
                  <Button
                    onClick={() => showRefContent()}
                    fullWidth
                    name="image"
                    onBlur={handleBlur}
                    color="default"
                    variant="outlined"
                    value={values.image || ''}
                    style={{
                      backgroundImage: `url(${
                        image && typeof image === 'object'
                          ? URL.createObjectURL(image)
                          : image
                      })`,
                      objectFit: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: '100% auto',
                      backgroundOrigin: 'border-box',
                      color: image ? 'white' : 'grey',
                      borderColor: errorFormat.length > 0 ? 'red' : 'grey',
                    }}
                    className={classes.textFieldBanner}
                    startIcon={<WallpaperIcon />}
                  >
                    <Typography
                      style={{
                        backgroundColor: image ? '#79797955' : 'transparent',
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      {'Bannière de la Notification'}
                    </Typography>
                  </Button>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'left',
                      flexDirection: 'column',
                    }}
                  >
                    {errorFormat.length > 0 ? (
                      errorFormat.map((e, i) => (
                        <Typography
                          key={i}
                          style={{
                            color: 'red',
                            fontSize: '0.8rem',
                            paddingLeft: 20,
                          }}
                        >
                          {e}
                        </Typography>
                      ))
                    ) : (
                      <Typography
                        style={{
                          color: 'grey',
                          fontSize: '0.8rem',
                          paddingLeft: 20,
                        }}
                      >
                        {
                          "L'image sera visible dans le centre de notification de l'application mobile."
                        }
                      </Typography>
                    )}
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {'Fermer'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={errorFormat.length > 0}
            autoFocus
            type="submit"
            form="openNotification-form"
          >
            {'Envoyer'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open && confirm} onClose={onClose}>
        <DialogTitle id="confirmNotification-title">
          {'Êtes-vous sûr de vouloir envoyer cette notification ?'}
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            className={classes.confirmNotification}
          >{`${nbSelected} contacts sélectionnés`}</Typography>
          <Typography
            variant="subtitle1"
            className={classes.confirmNotification}
          >
            {title}
          </Typography>
          <Typography variant="body2" className={classes.confirmNotification}>
            {body}
          </Typography>
          {data && data.link && (
            <Typography variant="body2" className={classes.confirmNotification}>
              {data.link}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <form className={classes.container} autoComplete="off">
            <Button onClick={onClose} color="primary">
              {'Annuler'}
            </Button>
            <Button
              onClick={() =>
                onSubmit({
                  body,
                  title,
                  sendData: data,
                  date,
                  image,
                })
              }
              disabled={isSubmitting}
              color="primary"
              variant="contained"
              autoFocus
            >
              {'Ok'}
            </Button>
          </form>
        </DialogActions>
      </Dialog>
    </>
  );
};
