import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Radio,
  Button,
  Dialog,
  RadioGroup,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  FormControlLabel,
  DialogContentText,
} from '@material-ui/core';

import master from '_utils/master';
import * as Push from '_redux/push';
import { Error } from '_components/elements';

/* */
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

/* */
export default (({
  open,
  onClose,
  onSubmit,
  isSubmitting,
}) => {
  const classes = useStyles();

  const [error, setError] = React.useState(null);
  const [confirm, setConfirm] = React.useState(false);
  const [pushList, setPushList] = React.useState(null);
  const [pushLists, setPushLists] = React.useState([]);
  const [addAccountToList, setAddAccountToList] = React.useState(false);

  const load = () => {
    setError(null);
    setConfirm(false);
    setPushList(null);
    if (open) {
      Promise.resolve()
        .then(() => setAddAccountToList(true))
        .then(() => Push.api.getPushLists(master))
        .then((list) => list && setPushLists(list))
        .catch((e) => setError(e))
        .finally(() => setAddAccountToList('loaded'));
    }
  };

  // Reset name on open/close
  React.useEffect(load, [open]);

  if (error) {
    return (
      <Dialog
        open={open}
        onClose={onClose}>
        <Error
          error={error}
          retry={load}
          />
      </Dialog>
    );
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}>
        <DialogTitle id="addAccountToList-title">{'A quelle liste ajouter ces contacts?'}</DialogTitle>
        <DialogContent dividers>
          {(addAccountToList !== 'loaded') && (
            <CircularProgress />
          )}
          {(addAccountToList === 'loaded') && (
            <RadioGroup
              name="pushList"
              value={pushList}
              aria-label="pushList"
              onChange={(event) => setPushList(event.target.value)}
            >
              {pushLists.map((option) => (
                <FormControlLabel
                  key={option.id}
                  value={option.id}
                  control={<Radio />}
                  label={option.name}
                  />
              ))}
            </RadioGroup>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {'Fermer'}
          </Button>
          <Button color="primary" variant="contained" autoFocus onClick={() => setConfirm(true)}>
            {'Ajouter'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open && confirm}
        onClose={onClose}>
        <DialogTitle id="confirmAddAccountToList-title">{'Ajouter à la liste.'}</DialogTitle>
        <form className={classes.container} autoComplete="off">
          <DialogContent>
            <DialogContentText id="confirmAddAccountToList-description">
              {'Êtes-vous sûr de vouloir ajouter les contacts à une liste existante à partir des résultats de la recherche ?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              {'Annuler'}
            </Button>
            <Button onClick={() => onSubmit(pushList)} disabled={isSubmitting} color="primary" variant="contained" autoFocus>
              {'Ok'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
});
