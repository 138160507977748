import React, { useState } from 'react';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';
import {
  Box, Typography,
} from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import { Skeleton } from '@material-ui/lab';
/* */
import MapChart from './MapChart';
/* */
const highlighted = (data) => {
  const allData = data && [
    ...data?.iOS.countries,
    ...data?.Android.countries,
    ...data?.Web.countries,
  ].reduce((acc, current) => {
    const previousAcc = acc || [];
    previousAcc[current.countryId] = acc[current.countryId] || {
      id: current.countryId,
      value: 0,
    };
    previousAcc[current.countryId].value += current?.value;
    return previousAcc;
  }, [])
  return Object.keys(allData).map((element) => allData[element]);
};
/* */
const Chart = ({
  title,
  data = {},
  loading,
  variant = null,
}) => {
  const [content, setContent] = useState('');
  if ((!data && data.lenght <= 0) || loading) {
    return (
      <Box
        width={'100%'}
        height={300}
        style={{ backgroundColor: '#ffffff' }}
        display='flex'
        flexDirection={'column'}
        margin={1}
        paddingTop={1}
        paddingBottom={4}
        borderRadius={5}
        boxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)'
        alignItems='center'
        justifyContent='space-evenly'
        >
        {title && (
          <Typography
            style={{
              fontSize: '18px', fontWeight: 800, marginLeft: 8, marginBottom: 8, alignSelf: 'flex-start',
            }}
          >{title}
          </Typography>
        )}
        <Skeleton variant="rect" width={'calc(100% - 10px)'} height={'100%'} />
      </Box>
    );
  }
  switch (variant) {
    case 'map':
      return (
        <Box
          width={'100%'}
          height={'100%'}
          style={{ backgroundColor: '#ffffff' }}
          display='flex'
          flexDirection={'column'}
          margin={1}
          paddingTop={1}
          paddingBottom={4}
          borderRadius={5}
          boxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)'
          alignItems='center'
          justifyContent='flex-start'
        >
          <Typography
            style={{
              fontSize: '18px', fontWeight: 800, marginLeft: 8, marginBottom: 4, alignSelf: 'flex-start',
            }}
          >{'Utilisateurs'}
          </Typography>
          <Box width='100%' height='100%' marginRight={4} marginLeft={4}>
            <MapChart setTooltipContent={setContent} highlighted={[
              { name: 'Utilisateurs Actifs', values: highlighted(data?.activeUsers) },
              { name: 'Nouveaux Utilisateurs', values: highlighted(data?.newUsers) },
            ]} />
            <ReactTooltip backgroundColor='#ffffff' borderColor='#000000' border={true} textColor='#000000'>{content}</ReactTooltip>
          </Box>
        </Box>
      );
    default:
      return (
        <Box
          width={'100%'}
          height={300}
          style={{ backgroundColor: '#ffffff' }}
          display='flex'
          flexDirection={'column'}
          margin={1}
          paddingTop={1}
          paddingBottom={4}
          borderRadius={5}
          boxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)'
          alignItems='center'
          justifyContent='flex-start'
        >
          <Typography
            style={{
              fontSize: '18px', fontWeight: 800, marginLeft: 8, marginBottom: 4, alignSelf: 'flex-start',
            }}
          >{title}
          </Typography>
          <ResponsiveContainer width={'100%'} height="100%">
            <BarChart width={150} height={40} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="null" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Android" stroke="#34A853" fill="#34A853" />
              <Bar dataKey="iOS" stroke="#1B93EA" fill="#1B93EA" />
              <Bar dataKey="Web" stroke="#ea9b1b" fill="#ea9b1b" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      );
  }
};
/* */
export default Chart;
