export default {
  search: {
    RESET: 'booking/search/RESET',
    SET_TEXT: 'booking/search/SET_TEXT',
    SET_GOLF: 'booking/search/SET_GOLF',
    SET_DATE: 'booking/search/SET_DATE',
    SET_COURSE: 'booking/search/SET_COURSE',
    ADD_PLAYER: 'booking/search/ADD_PLAYER',
    ADD_ACCESSORY: 'booking/search/ADD_ACCESSORY',
    ADJUST_PLAYERS: 'booking/search/ADJUST_PLAYERS',
    REPLACE_PLAYER: 'booking/search/REPLACE_PLAYER',
    REMOVE_ACCESSORY: 'booking/search/REMOVE_ACCESSORY',
  },
  data: {
    SET_BOOKING: 'booking/data/SET_BOOKING',
    UPDATE_BOOKING: 'booking/data/UPDATE_BOOKING',
    DETAIL_BOOKING: 'booking/data/DETAIL_BOOKING',
    SET_DATES_LIST: 'booking/data/SET_DATES_LIST',
    SET_GOLFS_LIST: 'booking/data/SET_GOLFS_LIST',
    SET_COURSES_LIST: 'booking/data/SET_COURSES_LIST',
    SET_ACCESSORIES_LIST: 'booking/data/SET_ACCESSORIES_LIST',
  },
  gql: {
    SEARCH_GOLFS: `
      query(
        $limit: Int
        $offset: Int
        $name: String
        $latlng: LatlngInput
      ) {
        searchGolfs(
          name: $name
          limit: $limit
          latlng: $latlng
          offset: $offset
        ) {
          id
          name
          city
          email
          distance
          latitude
          longitude
          postalCode
        }
      }
    `,
    GET_GOLF_INFOS: `
      query(
        $id: String!
        $activity: Int
        $player: String
      ) {
        getGolf(id: $id)  {
          id
          name
          city
          email
          distance
          latitude
          longitude
          postalCode
          options {
            name
            value
          }
        }
        getAccessories(golfId: $id) {
          id
          name
          max
        }
        getCourses(
          golfId: $id
          player: $player
          activity: $activity
        ) {
          id
          name
          type
          dayOpen
          default
          anonymous
          maxPlayers
          dates {
            from
            to
          }
        }
      }
    `,
    GET_COURSE_SCHEDULE: `
      query(
        $fullday: Int
        $date: String!
        $golfId: String!
        $players:[String]!
        $courseId: String!
        $courseType: String
        $admin: Int
      ) {
        getCourseSchedule(
          date: $date
          golfId: $golfId
          fullday: $fullday
          players: $players
          courseId: $courseId
          courseType: $courseType
          admin: $admin
        ) {
          dailyNote
          anonymousAllowed
          reasons {
            code
            player
          }
          teetimes {
            time
            time9in
            time9out
            availability
            privatizationAllowed
            prices {
              player
              initPrice
              discount
              price
              comment
            }
            players {
              tid
              index
              lastname
              firstname
              bookingId
              location {
                id
                name
              }
            }
          }
        }
      }
    `,
    GET_ACCESSORIES_SCHEDULE: `
      query(
        $date: String!
        $golfId: String!
        $courseId: String!
        $players: [String]!
        $courseType: String
        $accessoryIds: [String]!
      ) {
        getAccessoriesSchedule(
          date: $date
          golfId: $golfId
          players: $players
          courseId: $courseId
          courseType: $courseType
          accessoryIds: $accessoryIds
        ) {
          id
          schedule {
            time
            availability
            prices {
              player
              initPrice
              discount
              price
              comments
            }
          }
        }
      }
    `,
    BOOKING_LOCK: `
      mutation(
        $nb: Int!
        $date: String!
        $time: String!
        $golf: BookGolfInput!
        $course: BookCourseInput!
        $accessories: [LockAccessoryInput]
      ) {
        bookingLock(
          nb: $nb
          golf: $golf
          date: $date
          time: $time
          course: $course
          accessories: $accessories
        ) {
          lockCode
        }
      }
    `,
    BOOKING_BOOK: `
      mutation(
        $nb: Int!
        $date: String!
        $time: String!
        $time9in: String
        $lockCode: String
        $accountId: String!
        $golf: BookGolfInput!
        $owner: BookOwnerInput!
        $total: BookTotalInput!
        $course: BookCourseInput!
        $players: [BookPlayerInput]!
        $accessories: [BookAccessoryInput]
        $isPrivatized: Int
        $forceAnonymous: Int
      ) {
        bookingBook(
          nb: $nb
          date: $date
          golf: $golf
          time: $time
          total: $total
          owner: $owner
          accountId: $accountId
          course: $course
          players: $players
          time9in: $time9in
          lockCode: $lockCode
          accessories: $accessories
          isPrivatized: $isPrivatized
          forceAnonymous: $forceAnonymous
        ) {
          id
          golf {
            id
            name
          }
          course {
            id
            type
            name
          }
          date
          time
          time9in
          owner {
            name
            email
            phone
          }
          players {
            tid
            email
            price
            gender
            discount
            handicap
            lastname
            firstname
            licenseNo
            initPrice
            payments {
              id
              type
              amount
            }
          }
          accessories {
            id
            qty
            name
            price
            discount
            initPrice
            payments {
              id
              type
              amount
            }
          }
          total {
            price
            discount
            initPrice
          }
        }
      }
    `,

    BOOKING_CANCEL: `
      mutation(
        $id: String!
        $failed: Boolean
      ) {
        bookingCancel(
          id: $id
          failed: $failed
        ) {
          status
        }
      }
    `,
    BOOKING_EDIT: `
      mutation(
        $id: String
        $accountId: String!
        $date: String!
        $time: String!
        $golf: BookGolfInput!
        $course: BookCourseInput!
        $players: [BookUpdatedPlayerInput]!
        $accessories: [BookUpdatedAccessoryInput]!
      ) {
        bookingEdit(
          id: $id
          accountId: $accountId
          date: $date
          golf: $golf
          time: $time
          course: $course
          players: $players
          accessories: $accessories
        ) {
          success
        }
      }
    `,
    BOOKING_CONFIRM: `
      mutation(
        $id: String!
        $transaction: BookTransactionInput
      ) {
        bookingConfirm(
          id: $id
          transaction: $transaction
        ) {
          status
          transaction {
            id
            card
            amount
            callNo
            transactionNo
          }
        }
      }
    `,
    GET_BOOKING_LIST: `
      query(
        $golfId: String
        $dateMax: String
      ) {
        getBookings(
          golfId: $golfId
          dateMax: $dateMax
        ) {
          id
          golf {
            id
            name
          }
          course {
            id
            type
            name
          }
          players {
            tid
            email
            lastname
            firstname
          }
          accessories {
            id
            qty
            name
          }
          date
          time
          status
          isLocked
          bookingId
          nbPlayers
          nbAccessories
          total {
            price
            discount
            currency
            initPrice
          }
        }
      }
    `,
    GET_BOOKING: `
      query($id: String!) {
        getBooking(id: $id) {
          id
          golf {
            id
            name
          }
          course {
            id
            name
            type
          }
          date
          time
          time9in
          owner {
            email
            name
            phone
          }
          players {
            tid
            email
            price
            discount
            handicap
            lastname
            bookingId
            firstname
            initPrice
            licenseNo
          }
          accessories {
            id
            qty
            name
            price
            discount
            initPrice
            bookingIds
          }
          status {
            date
            status
          }
          total {
            price
            discount
            initPrice
          }
          payments {
            amount
            card
            callNo
            transactionNo
          }
          paymentMethod
        }
      }
    `,
    GET_TEETIME: `
      query(
        $date: String!
        $time: String!
        $golfId: String!
        $courseId: String!
        $courseType: String!
      ) {
        Teetime_get(
          date: $date
          time: $time
          golfId: $golfId
          courseId: $courseId
          courseType: $courseType
        ) {
          players {
            tid,
            email
            firstname
            lastname
            isLocked
            bookingId
          }
          accessories {
            qty
            name
          }
        }
      }
    `,
    GET_TEETIME_PLAYER_PRICES: `
      query(
        $time: String!
        $date: String!
        $golfId: String!
        $courseId: String!
        $players: [String]!
        $courseType: String!
      ) {
        Teetime_getPlayerPrices(
          date: $date
          time: $time
          golfId: $golfId
          players: $players
          courseId: $courseId
          courseType: $courseType
        ) {
          price
          player
          reason
          discount
          initPrice
        }
      }
    `,
  },
};
