export default {
  gql: {
    GET_DOCUMENT: `
      query($id: String!) {
        getDocument(id: $id) {
          id
          ownerId
          filename
          mimetype
          createdAt
        }
      }
    `,
    GET_DOCUMENTS: `
      query($ownerId: String) {
        getDocuments(ownerId: $ownerId) {
          id
          ownerId
          filename
          mimetype
          createdAt
        }
      }
    `,
    ADD_DOCUMENT: `
      mutation(
        $file: Upload!
        $access: String
        $ownerId: String
        $readerId: String
      ) {
        addDocument(
          file: $file
          access: $access
          ownerId: $ownerId
          readerId: $readerId
        ) {
          id
          ownerId
          filename
          mimetype
          createdAt
        }
      }
    `,
    UPDATE_DOCUMENT: `
      mutation (
        $id: String!
        $filename: String!
      ) {
        updateDocument(
          id: $id
          filename: $filename
        ) {
          id
          ownerId
          filename
          mimetype
          createdAt
        }
      }
    `,
    DELETE_DOCUMENT: `
      mutation ($id: String!) {
        deleteDocument(id: $id) {
          success
        }
      }
    `,
  },
};
