import gql from 'graphql-tag';

import actionTypes from './constants';

// -----------------------------------------------------------------------------
//
// -----------------------------------------------------------------------------
/* */
const getTeachers = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`${actionTypes.gql.GET_TEACHERS}`,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => (data.getSchoolTeachers)));

/* */
const getLessons = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`${actionTypes.gql.GET_LESSONS}`,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => (data.getSchoolLessons)));

/* */
const getLessonStudents = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`${actionTypes.gql.GET_LESSON_STUDENTS}`,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => (data.getSchoolLessonSlotStudents)));

/* */
const addLessonStudent = (client, variables) => (
  client.mutate({
    mutation: gql`${actionTypes.gql.ADD_LESSON_STUDENT}`,
    variables,
  })
    .then((res) => res.data)
    .then((data) => (data.addLessonSlotStudent)));

/* */
const getStudentRegistrations = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`${actionTypes.gql.GET_REGISTRATIONS}`,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => (data.getSchoolStudentRegistrations)));

export {
  getTeachers,
  getLessons,
  getLessonStudents,
  addLessonStudent,
  getStudentRegistrations,
};
