import React from 'react';
import { Form, FieldArray } from 'formik';
import * as colors from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Fab,
  Grid,
  Grow,
  Paper,
  Button,
  Popper,
  Switch,
  MenuItem,
  MenuList,
  TextField,
  IconButton,
  Typography,
  FormHelperText,
  CircularProgress,
  ClickAwayListener,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Close as DeleteIcon,
} from '@material-ui/icons';
import i18n from '_utils/i18n';

/* */
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  submitButton: {
    marginTop: theme.spacing(3),
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(3),
    margin: theme.spacing(1),
  },
  title: {
    flex: 1,
    display: 'flex',
    margin: `0 ${theme.spacing(2)}px`,
  },
  textfield: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

/* */
const getFieldArrayError = (errors, arr, index, field) => (
  errors[arr] && errors[arr][index] && errors[arr][index][field]
);

/* */
export default ({
  values,
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => {
  /* */
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const anchorEl = React.useRef();

  /* */
  function renderFab(services, arrayHelpers) {
    return (
      <React.Fragment>
        <Fab
          ref={anchorEl}
          color="primary"
          aria-haspopup="true"
          className={classes.fab}
          aria-owns={open ? 'menu-list-grow' : undefined}
          onClick={() => setOpen(true)}>
          <AddIcon />
        </Fab>
        <Popper
          transition
          disablePortal
          open={open}
          style={{ zIndex: 1 }}
          anchorEl={anchorEl.current}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
              <Paper>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList>
                    {services.map((service, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          arrayHelpers.push({
                            ...service,
                            status: false,
                            name: '',
                          });
                          setOpen(false);
                        }}>{service.label}</MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    );
  }

  function renderCommentInput() {
    return (
      <React.Fragment>
        <Typography
          gutterBottom
          variant="subtitle1">
          {'Informations générales'}
        </Typography>
        <TextField
          required
          fullWidth
          multiline
          rows={7}
          name="comment"
          variant="outlined"
          onBlur={handleBlur}
          value={values.comment}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }} />
      </React.Fragment>
    );
  }

  const renderStatuses = (arrayHelpers) => (
      <React.Fragment>
        {values.gameConditions && values.gameConditions.length ?
          values.gameConditions.map((value, index) => (
            <Paper key={index} className={classes.paper}>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="row">
                <IconButton
                  aria-label="Supprimer"
                  onClick={() => arrayHelpers.remove(index)}>
                  <DeleteIcon color="error" fontSize="small" />
                </IconButton>
                <Typography
                  variant="subtitle1"
                  className={classes.title}>
                  {value.label}
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="row">
                  <Typography
                    variant="caption"
                    style={{ fontWeight: 'bold', color: value.status ? colors.green[500] : colors.red[500] }}>
                    {value.statuses[Number(value.status)].label
                      || value.statuses[Number(value.status)]}
                    </Typography>
                  <Switch
                    color="primary"
                    onChange={handleChange}
                    checked={!!value.status}
                    name={`gameConditions.${index}.status`} />
                  {getFieldArrayError(errors, 'gameConditions', index, 'status') && (
                    <FormHelperText error required>
                      {getFieldArrayError(errors, 'gameConditions', index, 'status')}
                    </FormHelperText>
                  )}
                </Box>
              </Box>
              {value.nameable && (
                <TextField
                  required
                  fullWidth
                  label="Nom"
                  variant="outlined"
                  value={value.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className={classes.textfield}
                  InputLabelProps={{ shrink: true }}
                  name={`gameConditions.${index}.name`}
                  error={!!(getFieldArrayError(errors, 'gameConditions', index, 'name'))}
                  helperText={getFieldArrayError(errors, 'gameConditions', index, 'name')} />
              )}
            </Paper>
          ))
          : (
            <Typography
              align="center"
              variant="subtitle1">
              {'Aucune condition définie.'}
            </Typography>
          )
        }
        {renderFab(values.services, arrayHelpers)}
      </React.Fragment>
  );

  /* */
  return (
    <Form>
      <Grid container spacing={4}>
        <Grid item md={6} sm={12} xs={12}>
          <FieldArray name="gameConditions" render={renderStatuses} />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          {renderCommentInput()}
          <Button
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={isSubmitting}
            className={classes.submitButton}>
            {isSubmitting ? <CircularProgress color="inherit" size={24} /> : i18n.t('default.actions.confirm')}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};
