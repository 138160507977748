import firewall from '_config/firewall.json';
import defaultFeatures from '_config/features.json';

/* */
export const isFeatureEnabled = (golfFeatures = {}, feature) => {
  const {
    enabled = [],
    disabled = [],
  } = golfFeatures;
  if (enabled.includes(feature)) return true;
  if (disabled.includes(feature)) return false;

  // par défaut
  return defaultFeatures[feature];
};

/* */
export const getAuthorization = (path, data) => {
  const { golf } = data;

  // test regex
  const key = Object.keys(firewall).find((f) => {
    const regex = new RegExp(`^${f}`);

    return regex.test(path);
  });

  let isAuthorized = true;
  const {
    feature = null,
    redirect403 = null,
  } = firewall[key];

  if (feature) {
    const golfFeatures = golf && golf.options && golf.options.features;
    isAuthorized = isAuthorized && isFeatureEnabled(golfFeatures, feature);
  }

  return { isAuthorized, redirect403 };
};
