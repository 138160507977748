import React from 'react';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiGolfCart } from '@mdi/js';
import CloudIcon from '@material-ui/icons/Cloud';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
/* */
const styles = () => ({
  indicators: {
    fontWeight: 'bold',
    fontSize: 18,
    userSelect: 'none',
  },
  icons: {
    marginLeft: 10,
    paddingTop: 3,
    fontSize: 24,
  },
});
/* */
export interface IIndicatorsProps {
  // return selected date
  weather: Object,
  totalHolesCount: Number,
  totalPlayersCount: Number,
  totalCartsCount: Number,
}
/* */
class Indicators extends React.Component<IIndicatorsProps> {
  static defaultProps = {
    totalHolesCount: null,
    totalPlayersCount: null,
    totalCartsCount: null,
    weather: null,
  };

  /* */
  render() {
    const {
      classes,
      weather,
      totalHolesCount,
      totalPlayersCount,
      totalCartsCount,
    } = this.props;
    return [
      <Box
        key={'indicators'}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'flex-start'}
        flex={2}
        className={classes.indicators}
      >
        {weather && weather.temp !== null && [
          <CloudIcon className={classes.icons} key={'cloudIcon'}/>,
          weather.temp,
          '°',
        ]}
        {totalHolesCount !== null && [
          <RadioButtonUncheckedIcon className={classes.icons} key={'radioButton'}/>,
          totalHolesCount,
        ]}
        {totalPlayersCount !== null && [
          <SupervisorAccountIcon className={classes.icons} key={'supervisor'}/>,
          totalPlayersCount,
        ]}
        {totalCartsCount !== null && [
          <Icon path={mdiGolfCart} size={1} className={classes.icons} key={'icon'}/>,
          totalCartsCount,
        ]}
      </Box>,
    ];
  }
}
/* */
export default withStyles(styles, { withTheme: true })(Indicators);
