import omitDeep from 'omit-deep';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { ApolloClient } from 'apollo-client';
import { RetryLink } from 'apollo-link-retry';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';

import store from '_redux';
import config from '_config';

/**
 * GRAPHQL APOLLO CLIENT
 */
const httpLink = createUploadLink({
  uri: `${config.masterBasePath}/graphql`,
});

// return the headers to the context so httpLink can read them
const authLink = setContext((_, { headers }) => {
  const newHeaders = {
    ...headers,
  };
  newHeaders['x-api-token'] = store.getState().app.token || '';
  return { headers: newHeaders };
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: { max: 3 },
});

const errorLink = onError(({ response, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    let hasError = false;
    for (let i = 0; i < graphQLErrors.length; i += 1) {
      const { message, extensions } = graphQLErrors[i];
      switch (extensions && extensions.code) {
        default:
          if (response && !hasError) {
            response.errors = [{ message: message || 'errors.internal' }];
            hasError = true; // only one error
          }
      }
    }
  }
  if (networkError) {
    if (response) response.errors = [{ message: 'errors.network' }];
  }
  return null;
});

/**
  * Damien Levacher <damien@dlcode.fr>
  * 2020-12-08 : Comment useless code ?
  * Not compatible with apollo-upload-client
  * 2020-12-23 : https://stackoverflow.com/a/51380645
  */
//
// https://stackoverflow.com/a/52803291
// const omitTypename = (key, value) => ((key === '__typename') ? undefined : value);

const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    // eslint-disable-next-line no-param-reassign
    operation.variables = omitDeep(operation.variables, '__typename');
    // operation.variables = JSON.parse(
    //   JSON.stringify(operation.variables),
    //   omitTypename,
    // );
  }
  return forward(operation);
});

export default new ApolloClient({
  link: ApolloLink.from([
    authLink,
    omitTypenameLink,
    retryLink,
    errorLink,
    httpLink,
  ]),
  cache: new InMemoryCache(),
});
