import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  Avatar,
  // Button,
  Divider,
  ListItem,
  Collapse,
  Typography,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ContactsIcon from '@material-ui/icons/Contacts';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import NotificationsIcon from '@material-ui/icons/Notifications';

import * as App from '_redux/app';

// The usage of React.forwardRef will no longer be required for react-router-dom v6.
// see https://github.com/ReactTraining/react-router/issues/6056
const AdapterLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);

/* */
const customStyles = makeStyles((theme) => ({
  text: {},
  icon: {},
  link: {
    '& $icon': {
      color: theme.palette.secondary.main,
      fill: theme.palette.secondary.main, // svg
    },
    '&:hover': {
      '& $text, & $icon': {
        color: theme.palette.text.secondary,
        fill: theme.palette.text.secondary, // svg
      },
    },
  },
  selected: {
    '&, &:hover': {
      backgroundColor: theme.palette.secondary.main,
      '& $text, & $icon': {
        color: theme.palette.secondary.contrastText,
        fill: theme.palette.secondary.contrastText, // svg
      },
    },
  },
}));

/* */
const CustomListItem = ({
  icon,
  text,
  iconRight,
  expanded = null,
  ...props
}) => {
  const classes = customStyles();
  const customProps = { ...props };
  if (props.to) {
    customProps.component = AdapterLink;
    customProps.activeClassName = classes.selected;
  }

  return (
    <ListItem
      button
      disableRipple
      className={classes.link}
      {...customProps}>
      {icon && (
        <ListItemIcon className={classes.icon}>
          {icon}
        </ListItemIcon>
      )}
      <ListItemText
        classes={{ primary: classes.text }}
        primary={text} />
      {expanded !== null && (
        expanded
          ? <ExpandLessIcon color="action" />
          : <ExpandMoreIcon color="action" />
      )}
      {iconRight && (
        <ListItemSecondaryAction className={classes.icon}>
          {iconRight}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

/* */
const useStyles = makeStyles((theme) => ({
  toolbar: {
    height: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.common.white,
      ...theme.mixins.toolbar,
    },
  },
  link: {
    '& $icon': {
      color: theme.palette.secondary.main,
      fill: theme.palette.secondary.main, // svg
    },
    '&:hover': {
      '& $text, & $icon': {
        color: theme.palette.text.secondary,
        fill: theme.palette.text.secondary, // svg
      },
    },
  },
  selected: {
    '&, &:hover': {
      backgroundColor: theme.palette.secondary.main,
      '& $text, & $icon': {
        color: theme.palette.secondary.contrastText,
        fill: theme.palette.secondary.contrastText, // svg
      },
    },
  },
  text: {},
  icon: {},
  email: { color: theme.palette.grey[500] },
  identity: { padding: theme.spacing(1) },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    marginBottom: theme.spacing(1),
  },
  logout: {
    display: 'block',
    margin: `${theme.spacing(1)}px auto`,
  },
}));

/* */
const Menu = ({ user, navDrawer, togglePanelNav }) => {
  const classes = useStyles();

  const handleExpand = (type) => () => togglePanelNav(type);
  const isExpanded = (type) => navDrawer.includes(type);

  return (
    <div>
      <div className={classes.toolbar} />
      {(user && user.firstname && user.lastname) && (
        <React.Fragment>
          <div className={classes.identity} >
            <Avatar className={classes.avatar}>
              {App.functions.getUserInitials(user.firstname, user.lastname)}
            </Avatar>
            <Typography
              component="p"
              variant="h6">
              {`${user.firstname} ${user.lastname}`}
            </Typography>
            <Typography
              className={classes.email}
              component="p"
              variant="body2">
              {user.email}
            </Typography>
          </div>
          <Divider />
        </React.Fragment>
      )}
      <List>
        {/* <CustomListItem
          to="/accounts"
          text="Comptes" />
        <CustomListItem
          to="/game-conditions"
          text="Conditions de jeu" />
        <CustomListItem
          to="/transactions"
          text="Transactions" />*/}
        <CustomListItem
          text={'Notifications'}
          icon={<NotificationsIcon />}
          expanded={isExpanded('notifications')}
          onClick={handleExpand('notifications')} />
        <Collapse in={isExpanded('notifications')}>
          <List component="div" disablePadding>
            <CustomListItem
              to="/contact"
              text="Contacts"
              iconRight={<ContactsIcon />} />
            <CustomListItem
              to="/push"
              text="Listes"
              iconRight={<ListAltIcon />} />
            <CustomListItem
              to="/campaign"
              text="Campagnes"
              iconRight={<SendIcon />} />
            <CustomListItem
              to="/stats"
              text="Statistiques"
              iconRight={<EqualizerIcon />} />
            {/*
            <CustomListItem
              to="/automation"
              text="Automation"
              iconRight={<ScheduleIcon />} />
            */}
          </List>
        </Collapse>
        {/* <CustomListItem
          to="/document"
          text="Documents" /> */}
      </List>
    </div>
  );
};

const mapStateToProps = ({ app: { user, navDrawer } }) => ({ user, navDrawer });

export default connect(mapStateToProps, App.actions)(Menu);
