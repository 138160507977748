export default {
  gql: {
    SEARCH_PLAYERS: `
        query(
          $golfId: String!
          $subscriptionId: String
          $player: String
        ) {
          searchCorporatePlayers(golfId: $golfId, subscriptionId: $subscriptionId, player: $player) {
            id
            firstname
            lastname
          }
        }
      `,
    GET_SUBSCRIPTIONS: `
        query(
          $golfId: String!
        ) {
          getCorporateSubscriptions(golfId: $golfId) {
            id
            name
            expiresAt
            startsAt
          }
        }
      `,
    SEARCH_CORPO_BOOKINGS: `
      query(
        $golfId: String!
        $to: String
        $from: String
        $subscriptionId: String
        $playerId: String
      ) {
        searchCorporateBookings(
          golfId: $golfId,
          to: $to,
          from: $from,
          subscriptionId: $subscriptionId,
          playerId: $playerId
        ) {
          price
          bookingDate
          subscriptionName
          playerLastname
          playerFirstname
          playerEmail
        }
      }
    `,
  },
};
