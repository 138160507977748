export default {
  LOGOUT: 'app/LOGOUT',
  CONNECT: 'app/CONNECT',
  NO_GOLF: 'app/NO_GOLF',
  SET_USER: 'app/SET_USER',
  LOAD_GOLF: 'app/LOAD_GOLF',
  LOGIN_FAILED: 'app/LOGIN_FAILED',
  LOGIN_SUCCESS: 'app/LOGIN_SUCCESS',
  TOGGLE_PANEL_NAV: 'app/TOGGLE_PANEL_NAV',
  gql: {
    GET_MY_GOLF: `
      query {
        getMyGolf {
          id
          name
          slug
          city
          postalCode
          email
          latitude
          longitude
          reference
          storeAppVersion
          ios
          android
          propertyId
          options {
            name
            value
          }
        }
      }
    `,
    GET_GOLF: `
      query($id: String!) {
        getGolf(id: $id) {
          id
          name
          city
          postalCode
          email
          latitude
          longitude
          reference
          storeAppVersion
          ios
          android
          propertyId
          options {
            name
            value
          }
        }
      }
    `,
  },
};
