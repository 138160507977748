import actionTypes from './constants';

const initialState = {
  golf: null,
  user: null,
  error: null,
  token: null,
  loading: true,
  navDrawer: [
    'notifications',
  ],
};

export default (state = initialState, action) => {
  // console.log('App reducer : ', action.type);
  switch (action.type) {
    case actionTypes.LOGOUT:
      if (!action.payload && state.user === null) return state;
      return {
        ...initialState,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CONNECT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload,
        loading: true,
      };
    case actionTypes.LOGIN_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case actionTypes.NO_GOLF:
      return {
        ...state,
        golf: false,
        loading: false,
        error: action.payload,
      };
    case actionTypes.LOAD_GOLF: {
      return {
        ...state,
        golf: action.payload,
        loading: false,
      };
    }
    case actionTypes.TOGGLE_PANEL_NAV: {
      let navDrawer;
      const index = state.navDrawer.indexOf(action.payload);
      if (index === -1) {
        navDrawer = [...state.navDrawer, action.payload];
      } else {
        navDrawer = state.navDrawer.filter((el) => el !== action.payload);
      }
      return {
        ...state,
        navDrawer,
      };
    }
    default:
      return state;
  }
};
