import gql from 'graphql-tag';

/* */
const search = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`
      query(
        $query: String
        $golfId: String
      ) {
        listCustomers(
          query: $query
          golfId: $golfId
        ) {
          id
          email
          phone
          lastname
          firstname
          matches {
            golfId
            reference
          }
          lightspeed {
            id
            reference
          }
          attachmentGolf {
            id
            name
          }
        }
      }
    `,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => (data.listCustomers)));

/* */
const get = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`
      query($id: String!) {
        getCustomer(id: $id) {
          id
          email
          phone
          gender
          license
          lastname
          firstname
          createdAt
          dateOfBirth
          address {
            city
            country
            postalCode
            addressLine
            addressLine2
          }
          ffg {
            handicap
            updatedAt
          }
          matches {
            golfId
            reference
            isDefault
          }
          lightspeed {
            id
            reference
          }
        }
      }
    `,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => (data.getCustomer)));

/* */
const set = (client, variables) => (
  client.mutate({
    mutation: gql`
      mutation(
        $email: String!
        $lastname: String!
        $firstname: String!
        $id: String
        $phone: String
        $gender: String
        $license: String
        $dateOfBirth: String
        $address: CustomerAddressInput
      ) {
        setCustomer(
          id: $id
          email: $email
          phone: $phone
          gender: $gender
          license: $license
          address:  $address
          lastname: $lastname
          firstname: $firstname
          dateOfBirth: $dateOfBirth
        ) {
          id
          matches {
            golfId
            reference
          }
        }
      }
    `,
    variables,
  })
    .then((res) => res.data)
    .then((data) => (data.setCustomer)));

export {
  get,
  set,
  search,
};
