import React from 'react';
import classNames from 'classnames';
import * as colors from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
} from '@material-ui/core';

/* */
const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.common.white,
  },
  error: { backgroundColor: colors.red[700] },
  warning: { backgroundColor: colors.amber[500] },
}));

export default ({
  variant,
  children,
  className,
  ...props
}) => {
  const classes = useStyles();
  if (!children) return null;
  return (
    <Paper
      {... props}
      className={classNames(
        classes.paper,
        classes[variant],
        className,
      )}>
      <Typography
        color="inherit"
        variant="subtitle2">
        {children}
      </Typography>
    </Paper>
  );
};
