export default {
  gql: {
    GET_GAME_CONDITIONS: `
      query(
        $id: String!
      ) {
        getGolfGameConditions(
          id: $id
        ) {
          type
          name
          label
          status
          comment
          nameable
          statuses {
            status
            label
          }
        }
      }
    `,
    SET_GAME_CONDITIONS: `
      mutation(
        $id: String!
        $gameConditions: [GameConditionInput]
      ) {
        setGolfGameConditions(
          id: $id
          gameConditions: $gameConditions
        ) {
          success
        }
      }
    `,
    GET_ACCOUNTS: `
      query(
        $id: String!
      ) {
        getGolfAccounts(
          id: $id
        ) {
          id
          lastname
          firstname
          contactEmail
          contactPhone
          reference
        }
      }
    `,
    DELETE_ACCOUNTS: `
      mutation(
        $id: String!
        $accounts: [AccountMatchInput]!
      ) {
        deleteGolfAccounts(
          id: $id
          accounts: $accounts
        ) {
          success
        }
      }
    `,
    SEARCH_TRANSACTIONS: `
      query(
        $type: Int
        $to: String
        $from: String
        $product: Int
        $golfId: String
        $account: String
        $dateType: String
      ) {
        searchTransactions(
          to: $to
          from: $from
          type: $type
          golfId: $golfId
          product: $product
          account: $account
          dateType: $dateType
        ) {
          reference
          type
          date
          serviceDate
          account
          product
          credit
          debit
          letter
        }
      }
    `,
  },
};
