import thunkMiddleware from 'redux-thunk';
import { combineReducers, createStore, applyMiddleware } from 'redux';

import * as app from './app';

const rootReducer = combineReducers({
  ...app.reducers,
  // ...golf.reducers,
});

export default createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware),
);
