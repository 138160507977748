import React from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import { Badge, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import i18n from '_utils/i18n';
import master from '_utils/master';
import * as Client from '_redux/client';
// import * as Contact from '_redux/contact';
import { Screen } from '_components/core';

const mapStateToProps = ({ app: { golf } }) => ({ golf });

export default connect(mapStateToProps)(({ golf, history }) => {
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => Client.api.getAccounts(master, { golfId: golf.id }))
      .then((accounts) => accounts && setItems(accounts))
      .catch(setError)
      .finally(() => setLoading(false));
  }, [golf]);

  /* */
  return (
    <Screen
      error={error}
      layout='fluid'
      title='Documents'
      loading={loading}>
      <MaterialTable
          title=""
          data={items}
          actions={[
            {
              icon: 'preview',
              tooltip: 'Voir',
              onClick: (event, rowData) => history.push(`document/${rowData.id}`),
            },
          ]}
          localization={{
            header: {
              actions: '',
            },
            ...i18n.translations.fr.MaterialTable,
          }}
          options={{
            paging: true,
            pageSize: 25,
            toolbar: true,
            filtering: true,
            showTitle: false,
            showHeader: false,
            actionsColumnIndex: -1,
            pageSizeOptions: [25, 50, 100],
            toolbarButtonAlignment: 'right',
          }}
          columns={[
            { field: 'id', hidden: true },
            { title: 'Prenom', field: 'firstname', defaultSort: 'asc' },
            { title: 'Nom', field: 'lastname' },
            { title: 'Email', field: 'email' },
            { title: 'Tél', field: 'phone' },
          ]}
          components={{
            Action: (props) => (
              <IconButton aria-label="view"
                onClick={() => history.push(`/document/${props.data.id}`)}>
                <Badge color="secondary" badgeContent={props.data.documents?.length || 0}>
                  <VisibilityIcon />
                </Badge>
              </IconButton>
            ),
          }}
        />
    </Screen>
  );
});
