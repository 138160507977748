import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {
  Box,
  Paper,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  Typography,
  InputAdornment,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@material-ui/core';

import master from '_utils/master';
import { Screen } from '_components/core';
import * as Customer from '_redux/customer';

/* */
const useStyles = makeStyles({
  unknown: { backgroundColor: '#fafafa' },
});

/* */
const Customers = ({ history }) => {
  const classes = useStyles();

  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const searchParams = JSON.parse(sessionStorage.getItem('customers_search')) || { query: '' };
  const [query, setQuery] = React.useState(searchParams.query);
  const [debouncedQuery, setDebouncedQuery] = React.useState(searchParams.query);

  const [customers, setCustomers] = React.useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  /* */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /* */
  const handleChangeRowsPerPage = (e) => {
    setPage(0);
    setRowsPerPage(parseInt(e.target.value, 10));
  };

  /* */
  const search = () => {
    setLoading(true);

    sessionStorage.setItem('customers_search', JSON.stringify({ query: debouncedQuery }));

    setTimeout(() => Customer.api.search(master, { query: debouncedQuery || null })
      .then((data) => {
        setPage(0);
        setCustomers(data);
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false)), 1000);
  };

  /* */
  const debounce = React.useCallback(_.debounce((value) => {
    setDebouncedQuery(value.trim());
  }, 1000), []);

  /* */
  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    debounce(value);
  };

  React.useEffect(search, [debouncedQuery]);

  /* */
  const goToAdd = () => history.push('/customers/add');
  const goToEdit = ({ id, matches }) => () => {
    if (matches && matches[0].reference) {
      history.push(`/customers/${id}?tid=${matches[0].reference}`);
    } else {
      history.push(`/customers/${id}`);
    }
  };

  /* */
  return (
    <Screen
      error={error}>
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        flexDirection="row">
        <AccountCircleIcon style={{ marginRight: 8 }} />
        <Typography
          variant="h5"
          component="h2">
          {'Clients'}
        </Typography>
      </Box>
      <Box
        mb={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row">
        <TextField
          margin="dense"
          variant="outlined"
          value={query}
          onChange={handleChange}
          style={{ width: '50%' }}
          placeholder="Recherche..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }} />
        <Button
          color="primary"
          onClick={goToAdd}
          variant="contained">
          {'Ajouter'}
        </Button>
      </Box>
      <TableContainer
        component={Paper}
        style={{ marginTop: 24 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>{'#'}</TableCell>
              <TableCell>{'Nom complet'}</TableCell>
              <TableCell>{'E-mail'}</TableCell>
              <TableCell>{'Téléphone'}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && customers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(({
                id,
                matches,
                lightspeed,
                attachmentGolf,
                ...data
              }, i) => (
                <TableRow
                  hover
                  key={i}
                  onClick={goToEdit({ id, matches, attachmentGolf })}>
                  <TableCell
                    scope="row"
                    component="th"
                    className={(matches && matches.length) ? null : classes.unknown}>
                    {lightspeed && lightspeed.reference}
                    {!lightspeed && matches && matches[0].reference}
                  </TableCell>
                  <TableCell>
                    {data.firstname} {data.lastname}<br />
                    {!matches && attachmentGolf && (
                      <Typography variant="caption" color="primary">
                        {attachmentGolf.name}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>{data.email}</TableCell>
                  <TableCell>{data.phone}</TableCell>
                  <TableCell align="right">
                    <ArrowRightIcon />
                  </TableCell>
                </TableRow>
              ))}
              {!loading && customers.length === 0 && (
                <TableRow>
                  <TableCell size="medium" align="center" colSpan="5">
                    <AccountCircleIcon color="disabled" style={{ color: '#737b83', fontSize: 64 }} />
                    <Typography style={{ color: '#737b83' }} variant="h6">
                      {'Aucun client trouvé.'}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell size="medium" align="center" colSpan="5">
                    <CircularProgress
                      size={16}
                      thickness={4}
                      color="secondary" />
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && customers.length > 0 && (
        <TablePagination
          component="div"
          page={page}
          count={customers.length}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          rowsPerPageOptions={[25, 50, 100]}
          onChangeRowsPerPage={handleChangeRowsPerPage} />
      )}
    </Screen>
  );
};

const mapStateToProps = ({ app }) => ({ ...app });

export default connect(mapStateToProps)(Customers);
