import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Box,
  Grid,
  Paper,
  Radio,
  Button,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import { CompleteCreditCardForm } from '_components/forms';
import { createValidationSchema, validators } from '_utils/validation';
import {
  Alert,
  DialogPayment3DS,
  CreditCardPreview,
} from '_components/elements';

/* */
const validationSchema = createValidationSchema({
  cvv: validators.cvv,
  dueDate: validators.dateFourDigits,
});

/* */
const styles = (theme) => ({
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  panel: {
    alignItems: 'center',
    flexDirection: 'column',
  },
  radio: {
    padding: 0,
    marginRight: theme.spacing(2),
  },
  marginT: {
    marginTop: theme.spacing(2),
  },
});

/* */
class RecurringPayment extends React.Component {
  /* */
  static defaultProps = {
    amount: 50,
    use3DS: true,
    imprint: null,
    session3DS: null, // required
    payWithNewCard: null, // required
    payWithCardImprint: null, // required
  };

  /* */
  constructor(props) {
    super(props);

    this.state = {
      expanded: null,
      // data 3DS
      error: null,
      sessionId: null,
      showIframe: false,
    };

    this.id3D = null;
    this.imprintRequired = true;
    this.form = React.createRef();
  }

  /* */
  componentDidMount() {
    const { imprint, use3DS } = this.props;

    this.setState({ expanded: imprint && 'imprint' });

    // add listener 3ds posted response
    if (use3DS) {
      window.addEventListener('message', this.handleMesssage3DS);
    }
  }

  /* */
  componentDidUpdate(prevProps) {
    const { imprint } = this.props;

    if (prevProps.imprint !== imprint) this.setState({ expanded: imprint && 'imprint' });
  }

  /* */
  componentWillUnmount() {
    const { use3DS } = this.props;

    // clean listener
    if (use3DS) {
      window.removeEventListener('message', this.handleMesssage3DS);
    }
  }

  /* */
  handleChange = (panel) => (event, expanded) => {
    this.setState({ expanded: expanded ? panel : false });
  };

  // Catch iframe 3DS message
  handleMesssage3DS = (event) => {
    if (!event.data.type || event.data.type !== 'response3DS') return;

    const { status, id3D } = event.data;

    if (status === 'success') {
      this.id3D = id3D;
      this.setState({ showIframe: false });
      this.form.current.props.submitForm();
    } else {
      this.handleError3DS();
    }
  }

  /* */
  handleError3DS() {
    this.id3D = null;
    this.form.current.props.setSubmitting(false);
    this.setState({
      showIframe: false,
      error: "Erreur d'authentification 3D Secure.",
    });
  }

  /* */
  renderCardImprint() {
    const {
      classes,
      imprint,
      payWithCardImprint,
    } = this.props;

    return (
      <React.Fragment>
        <Box display="flex" justifyContent="center">
          <CreditCardPreview
            brand={imprint.brand}
            number={imprint.number}
            dueDate={imprint.dueDate} />
        </Box>
        {imprint.isExpired && (
            <Alert
              variant="error"
              className={classes.marginT}>
                {'La carte est expirée.'}
            </Alert>
        )}
        {imprint.isSoonExpired && (
          <Alert
            variant="warning"
            className={classes.marginT}>
              {'La carte arrive bientôt à expiration.'}
          </Alert>
        )}
        {!imprint.isExpired && (
          <Button
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            className={classes.marginT}
            onClick={payWithCardImprint}>
            {'Sélectionner'}
          </Button>
        )}
      </React.Fragment>
    );
  }

  /* */
  renderCardImprintPanel() {
    const { classes } = this.props;

    return (
      <Grid item xs={12} sm={9} md={6}>
        <Paper className={classes.paper}>
          <Typography
            variant="subtitle1"
            component='h2'>
            {'Ma carte enregistrée'}
          </Typography>
          <br />
          {this.renderCardImprint()}
        </Paper>
      </Grid>
    );
  }

  /* */
  getCardInfos = () => this.form.current.getExtraInfos();

  /* */
  onSubmit = (values, actions) => {
    const {
      amount,
      use3DS,
      session3DS,
      payWithNewCard,
    } = this.props;
    const { id3D } = this;

    const newValues = {
      ...values,
      ...this.getCardInfos(),
      ...(use3DS && { id3D }),
    };

    // if no 3DS OR already checked
    if (!use3DS || id3D !== null) {
      payWithNewCard(newValues, actions);
      return;
    }

    // build 3DS sessionId
    const sessionId = amount
      + Number(moment().format('x'))
      + Math.floor(Math.random() * (10 ** 12));

    // store temporarily data
    sessionStorage.setItem(`cart_${sessionId}`, JSON.stringify({
      ...session3DS,
      amount,
      id: sessionId,
      creditCard: {
        cvv: values.cvv,
        number: values.number.replace(/\s/g, ''),
        dueDate: values.dueDate.replace(/\D/g, ''),
      },
    }));

    // load iframe 3DS
    this.setState({
      sessionId,
      error: null,
      showIframe: true,
    });
  }

  /* */
  renderNewCardForm() {
    const { amount } = this.props;

    const { error } = this.state;

    const submitText = 'Enregistrer';
    const submitHintText = `Dans le cadre de la réglementation DSP2, une demande
      d'autorisation seule d'un montant de ${i18n.l('currency', amount / 100)} sera effectué auprès de votre banque.`;

    return (
      <React.Fragment>
        {error && (
          <Alert variant="error">{error}</Alert>
        )}
        <Formik
          initialValues={{
            cvv: '',
            number: '',
            dueDate: '',
          }}
          onSubmit={this.onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={validationSchema}>
          {(props) => (
            <CompleteCreditCardForm
              {...props}
              innerRef={this.form}
              imprintUse="required"
              submitButtonText={submitText}
              submitButtonHintText={submitHintText} />
          )}
        </Formik>
      </React.Fragment>
    );
  }

  /* */
  renderNewCardPanel() {
    const {
      classes,
      imprint,
    } = this.props;
    const { expanded } = this.state;

    if (!imprint) {
      return (
        <Grid item xs={12} sm={9} md={6}>
          <Paper className={classes.paper}>
            {this.renderNewCardForm()}
          </Paper>
        </Grid>
      );
    }

    return (
      <Grid item xs={12} sm={9} md={6}>
        <Accordion
          expanded={expanded === 'other'}
          onChange={this.handleChange('other')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}>
            <Radio
              className={classes.radio}
              checked={expanded === 'other'} />
            <Typography
              variant="subtitle1"
              component='h2'>
              {'Renseigner une nouvelle carte'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.panel}>
            {this.renderNewCardForm()}
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  }

  /* */
  renderPanels() {
    const { imprint } = this.props;

    return (
      <React.Fragment>
        {imprint && this.renderCardImprintPanel()}
        {this.renderNewCardPanel()}
      </React.Fragment>
    );
  }

  /* */
  renderDialog3DS() {
    const { use3DS } = this.props;
    const { showIframe, sessionId } = this.state;

    if (!use3DS || !sessionId) return null;

    return (
      <DialogPayment3DS
        open={showIframe}
        sessionId={sessionId}
        onClose={() => this.handleError3DS()} />
    );
  }

  /* */
  render() {
    return (
      <React.Fragment>
        <Grid
          container
          spacing={5}
          justify="center">
          {this.renderPanels()}
        </Grid>
        {this.renderDialog3DS()}
      </React.Fragment>
    );
  }
}

const PaymentWithRouter = withRouter(RecurringPayment);

export default withStyles(styles)(PaymentWithRouter);
