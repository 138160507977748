import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Paper,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TextField,
  TableContainer,
  TablePagination,
  CircularProgress,
  InputAdornment,
  Tabs,
  Tab,
} from '@material-ui/core';

import i18n from '_utils/i18n';
import masterClient from '_utils/master';
import { Screen } from '_components/core';
import * as Customer from '_redux/customer';
import * as Subscription from '_redux/subscription';
import CorporateMenu from '_screens/CorporateMenu';

/* */
const StyledTableCell = withStyles((theme) => ({
  body: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 14,
  },
}))(TableCell);

/* */
const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
  },
  mr8: { marginRight: 8 },
}));

/* */
const Edit = ({ history, match: { params } }) => {
  const classes = useStyles();

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(true);

  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');

  const [subscription, setSubscription] = useState(null);

  // Les bénéficiaires
  const [allBeneficiaries, setAllBeneficiaries] = useState([]);
  // Les clients filtrés
  const [qResults, setQResults] = useState([]);

  const [rules, setRules] = useState(null);

  const [searching, setSearching] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [panel, setPanel] = React.useState(0);

  /* */
  const handleChangePanel = (event, newValue) => {
    setPanel(newValue);
  };

  /* */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /* */
  const handleChangeRowsPerPage = (e) => {
    setPage(0);
    setRowsPerPage(parseInt(e.target.value, 10));
  };

  const { id = null } = params;

  /* */
  const search = () => {
    if (debouncedQuery.length > 0) {
      Customer.api.search(masterClient, { query: debouncedQuery })
        .then((data) => {
          const qCust = data.filter((c) => !allBeneficiaries
            .find((b) => b.account.id === c.id));
          const qBenef = allBeneficiaries.filter((b) => data
            .find((c) => b.account.id === c.id));

          setPage(0);
          setQResults([...qBenef, ...qCust]);
        })
        .catch((err) => setError(err.message))
        .finally(() => setSearching(false));
    }
  };

  /* */
  const beforeAction = () => {
    setError(null);
    setSuccess(null);
    setButtonLoading(true);
  };

  /* */
  const debounce = React.useCallback(_.debounce((value) => {
    setDebouncedQuery(value.trim());
  }, 1000), []);

  /* */
  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    debounce(value);

    if (value !== debouncedQuery) {
      setSearching(true);
    } else {
      setSearching(false);
    }
  };

  /*
  * Prends en compte un seul type de joueur et ses règles (!)
  */
  const loadRules = (membershipPackage) => Subscription.api
    .getPlayerTypes(masterClient, { membershipPackageId: membershipPackage.id })
    .then((data) => {
      if (data && data[0]) {
        const formattedRules = data[0].rules.map((r) => ({
          name: r.name,
          data: JSON.parse(r.value),
        }));
        setRules(formattedRules);
        return Promise.resolve();
      }
      return Promise.reject();
    })
    .catch(() => setRules([]));

  /* */
  const loadBeneficiaries = () => Subscription.api
    .getBeneficiaries(masterClient, { id })
    .then((data) => {
      // exclu ceux qui ont été désactivés, ne sont pas affichés avec compteur du coup
      const beneficiaries = data.filter((b) => !b.endDate);
      setQuery('');
      setAllBeneficiaries(beneficiaries);
      // setQResults(beneficiaries);
    });

  /* */
  const load = () => {
    Subscription.api.getById(masterClient, { id })
      .then((data) => {
        setSubscription(data);
        return loadRules(data.membershipPackage);
      })
      .then(loadBeneficiaries)
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  };

  /* */
  useEffect(load, []);
  useEffect(search, [debouncedQuery]);

  /* */
  const handleAddBeneficiary = (customerId) => {
    beforeAction();
    Subscription.api.addBeneficiary(masterClient, {
      subscriptionId: id,
      accountId: customerId,
    })
      .catch(() => setError('Une erreur est survenue.'))
      .then(() => setSuccess('Bénéficiaire ajouté avec succès.'))
      .then(loadBeneficiaries)
      .finally(() => setButtonLoading(false));
  };

  /* */
  const handleRemoveBeneficiary = (beneficiaryId) => {
    beforeAction();
    Subscription.api.removeBeneficiary(masterClient, {
      id: beneficiaryId,
    })
      .catch(() => setError('Une erreur est survenue.'))
      .then(() => setSuccess('Bénéficiaire désactivé avec succès.'))
      .then(loadBeneficiaries)
      .finally(() => setButtonLoading(false));
  };

  /* */
  const renderRow = ({ __typename: type, ...data }, index) => {
    // qBeneficiaries
    if (type === 'SubscriptionBeneficiary') {
      const {
        id: beneficiaryId,
        account,
        endDate,
      } = data;

      return (
        <TableRow
          hover
          key={index}>
          <TableCell>
            {account.firstname} {account.lastname}
          </TableCell>
          <TableCell>{account.email}</TableCell>
          <TableCell>{account.phone}</TableCell>
          <TableCell align="right">
            {endDate ? (
              <>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  disabled={buttonLoading}
                  onClick={() => handleAddBeneficiary(account.id)}>
                  {'Réactiver'}
                </Button>
                <br/>
                {/* <Typography variant="caption" color="primary">
                  {`Fin des droits le ${i18n.l('date.formats.default', endDate)}`}
                </Typography> */}
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  disabled={buttonLoading}
                  onClick={() => handleRemoveBeneficiary(beneficiaryId)}>
                  {'Désactiver'}
                </Button>
                <br/>
                {/* <Typography variant="caption" color="primary">
                  {`Début des droits le ${i18n.l('date.formats.default', startDate)}`}
                </Typography> */}
              </>
            )}
          </TableCell>
        </TableRow>
      );
    }

    // qCustomers
    return (
      <TableRow
        hover
        key={index}>
        <TableCell>
          {data.firstname} {data.lastname}
        </TableCell>
        <TableCell>{data.email}</TableCell>
        <TableCell>{data.phone}</TableCell>
        <TableCell align="right">
          <Button
            variant="contained"
            size="small"
            color="primary"
            disabled={buttonLoading}
            onClick={() => handleAddBeneficiary(data.id)}>
            {'Ajouter'}
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  /* */
  const renderListPanel = () => (
    <div>
      <Box
        mb={1}
        display="flex"
        alignItems="center"
        flexDirection="row">
        <TextField
          margin="dense"
          variant="outlined"
          value={query}
          onChange={handleChange}
          style={{ width: '40%', marginRight: 8 }}
          placeholder="Rechercher un bénéficiaire ou client"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }} />
        {query && (
          <Button
            color="primary"
            onClick={() => setQuery('')}>
            {'Tous les bénéficiaires'}
          </Button>
        )}
      </Box>
      {!query && (
        <>
          <Typography gutterBottom color="primary">
            {`Tous les bénéficiaires (${allBeneficiaries.length})`}
          </Typography>
          <TableContainer component={Paper}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell><b>{'Nom complet'}</b></TableCell>
                  <TableCell><b>{'E-mail'}</b></TableCell>
                  <TableCell><b>{'Téléphone'}</b></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allBeneficiaries
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(({
                    id: beneficiaryId,
                    account,
                    endDate,
                  }, i) => (
                    <TableRow
                      hover
                      key={i}>
                      <TableCell>
                        {account.firstname} {account.lastname}
                      </TableCell>
                      <TableCell>{account.email}</TableCell>
                      <TableCell>{account.phone}</TableCell>
                      <TableCell align="right">
                        {endDate ? (
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            disabled={buttonLoading}
                            onClick={() => handleAddBeneficiary(account.id)}>
                            {'Réactiver'}
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            disabled={buttonLoading}
                            onClick={() => handleRemoveBeneficiary(beneficiaryId)}>
                            {'Désactiver'}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  {allBeneficiaries.length === 0 && (
                    <TableRow>
                      <TableCell size="medium" align="center" colSpan="4">
                        <AccountCircleIcon color="disabled" style={{ color: '#737b83', fontSize: 64 }} />
                        <Typography style={{ color: '#737b83' }} variant="h6">
                          {'Aucun bénéficiaire trouvé.'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </TableContainer>
          {allBeneficiaries.length > 0 && (
            <TablePagination
              component="div"
              page={page}
              count={allBeneficiaries.length}
              rowsPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              rowsPerPageOptions={[25, 50, 100]}
              onChangeRowsPerPage={handleChangeRowsPerPage} />
          )}
        </>
      )}
      {query && (
        <>
          {!searching
            && qResults.length > 0
            && qResults.length < 50
            && (
            <Typography gutterBottom color="primary">
              {`Résultats (${qResults.length})`}
            </Typography>
          )}
          <TableContainer
            component={Paper}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell><b>{'Nom complet'}</b></TableCell>
                  <TableCell><b>{'E-mail'}</b></TableCell>
                  <TableCell><b>{'Téléphone'}</b></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {!searching
                  && qResults.length === 50
                  && (
                  <TableRow>
                    <TableCell size="medium" align="center" colSpan="4">
                      <InfoOutlinedIcon color="disabled" style={{ color: '#737b83', fontSize: 64 }} />
                      <Typography style={{ color: '#737b83' }} variant="h6">
                        {'Trop de correspondances. Veuillez affiner votre recherche.'}
                      </Typography>
                      <Typography style={{ color: '#737b83' }} variant="caption">
                        {'Seules les 50 premières sont affichées.'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {!searching &&
                  qResults
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, i) => renderRow(data, i))}
                  {!searching
                    && qResults.length === 0
                    && (
                      <TableRow>
                        <TableCell size="medium" align="center" colSpan="4">
                          <AccountCircleIcon color="disabled" style={{ color: '#737b83', fontSize: 64 }} />
                          <Typography style={{ color: '#737b83' }} variant="h6">
                            {'Aucun résultat.'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  {searching && (
                    <TableRow>
                      <TableCell size="medium" align="center" colSpan="5">
                        <CircularProgress
                          size={16}
                          thickness={4}
                          color="secondary" />
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </TableContainer>
          {!searching && qResults.length > 0 && (
            <TablePagination
              component="div"
              page={page}
              count={qResults.length}
              rowsPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              rowsPerPageOptions={[25, 50, 100]}
              onChangeRowsPerPage={handleChangeRowsPerPage} />
          )}
        </>
      )}
    </div>
  );

  /* */
  const renderRulesPanel = () => {
    if (!rules || !rules.length) return null;

    // Init
    const availableIn = rules.find((r) => r.name === 'available_in');
    const availableOver = rules.find((r) => r.name === 'available_over');
    const maxSlots = rules.find((r) => r.name === 'max_slots');
    const maxSlotsPerPlayer = rules.find((r) => r.name === 'max_slots_per_player');
    const closures = rules.filter((r) => r.name === 'closure');
    const unitPrice = rules.find((r) => r.name === 'unit_price');
    const discount = rules.find((r) => r.name === 'discount');

    const translatedRules = [];
    // Prix unitaire appliqué
    if (unitPrice) {
      translatedRules.push({
        name: 'Tarif réduit',
        value: i18n.l('currency', unitPrice.data.value / 100),
      });
    }
    // Remise appliquée
    if (discount) {
      translatedRules.push({
        name: 'Remise appliquée',
        value: i18n.l('percentage', -1 * discount.data.value),
      });
    }
    // Période autorisée
    if (availableIn && availableOver) {
      translatedRules.push({
        name: 'Période',
        value: availableIn.data.value === 0
          ? `J à J+${availableOver.data.value}`
          : `J+${availableIn.data.value} à J+${availableOver.data.value}`,
      });
    }
    // Quota total
    if (maxSlots) {
      translatedRules.push({
        name: 'Quota total',
        value: maxSlots.data.duration === null
          ? maxSlots.data.value
          : `${maxSlots.data.value} / ${i18n.t(`terms.${maxSlots.data.duration}`)}`,
      });
    }
    // Quota par joueur
    if (maxSlotsPerPlayer) {
      translatedRules.push({
        name: 'Quota par joueur',
        value: maxSlotsPerPlayer.data.duration === null
          ? maxSlotsPerPlayer.data.value
          : `${maxSlotsPerPlayer.data.value} / ${i18n.t(`terms.${maxSlotsPerPlayer.data.duration}`)}`,
      });
    }

    // Exceptions
    const translatedClosures = closures.map((c) => {
      let timeRange = '';
      if (c.data.timeRange[0] === '0:00') {
        timeRange = `jusqu'à ${c.data.timeRange[1]}`;
      }
      if (c.data.timeRange[1] === '0:00') {
        timeRange = `à partir de ${c.data.timeRange[0]}`;
      }
      if (c.data.timeRange[0] !== '0:00' && c.data.timeRange[1] !== '0:00') {
        timeRange = `de ${c.data.timeRange[0]} à ${c.data.timeRange[1]}`;
      }

      const days = c.data.dayOfWeek.length > 1
        ? c.data.dayOfWeek.map((dof) => `${moment(dof, 'E').format('dddd')}`).join(', ')
        : `${moment(c.data.dayOfWeek[0], 'E').format('dddd')}`;

      return {
        days,
        timeRange,
      };
    });

    // Règles simples
    const rulesJSX = translatedRules.map((tr, idx) => (
      <TableRow key={`pt-rules-${idx}`}>
        <TableCell>
          {tr.name}
        </TableCell>
        <TableCell align="right">
          {tr.value}
        </TableCell>
      </TableRow>
    ));

    // Exceptions
    if (translatedClosures.length > 0) {
      rulesJSX.push(
        <TableRow key="pt-rules-exceptions">
          <StyledTableCell size="small" colSpan="4">
            {'Exceptions'}
          </StyledTableCell>
        </TableRow>,
        translatedClosures.map((c, k) => (
          <TableRow key={`pt-rules-exceptions-${k}`}>
            <TableCell>
              {c.days}
            </TableCell>
            <TableCell align="right">
              {c.timeRange}
            </TableCell>
          </TableRow>
        )),
      );
    }

    return (
      <div>
        <TableContainer
          component={Paper}>
          <Table stickyHeader size="small">
            <TableBody>
              <TableRow>
                <StyledTableCell size="small" colSpan="4">
                  {'Réservation de green-fees'}
                </StyledTableCell>
              </TableRow>
              {rulesJSX}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Typography>
          {'En dehors de ces règles, le tarif visteur est appliqué.'}
        </Typography>
      </div>
    );
  };

  /* */
  const getPanelContent = (step) => {
    switch (step) {
      case 0:
        return renderRulesPanel();
      case 1:
      default:
        return renderListPanel();
    }
  };

  /* */
  return (
    <Screen
      layout="fluid"
      error={error}
      loading={loading}
      success={success}
      menu={<CorporateMenu />}
    >
      {subscription && subscription.membershipPackage && (
        <Box mb={2}>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row">
            <IconButton
              color="primary"
              className={classes.mr8}
              onClick={() => history.goBack()}>
              <ArrowBackIcon fontSize="small" />
            </IconButton>
            <Typography
              variant="h5"
              component="h2">
              {subscription.name}
            </Typography>
          </Box>
          <Typography
            variant="h6"
            component="h3">
            {subscription.membershipPackage.membership.name}
          </Typography>
          <Typography>
            {'Du '} {i18n.l('date.formats.default', subscription.startsAt)}
            {' au '}{i18n.l('date.formats.default', subscription.expiresAt)}
          </Typography>
        </Box>
      )}
      <Paper square>
        <Tabs
          value={panel}
          textColor="secondary"
          indicatorColor="secondary"
          onChange={handleChangePanel}
        >
        <Tab label="Les règles" />
        <Tab label="Les bénéficiaires" />
        </Tabs>
      </Paper>
      <br/>
       {getPanelContent(panel)}
    </Screen>
  );
};

const mapStateToProps = ({ app }) => ({ ...app });

export default connect(mapStateToProps)(Edit);
