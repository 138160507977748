import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { Screen } from '_components/core';

/* */
const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: 100,
    marginBottom: theme.spacing(2),
  },
}));

/* */
export default () => {
  const classes = useStyles();

  return (
    <Screen>
      <div className={classes.wrapper}>
        <LockOutlinedIcon
          className={classes.icon}
          fontSize="inherit" />
        <Typography
          component="h1"
          gutterBottom
          variant="h4">
          {'Accès refusé !'}
        </Typography>
        <Typography
          component="p"
          gutterBottom
          variant="subtitle1">
          {'Erreur 403'}
        </Typography>
        <Typography
          component="h2"
          gutterBottom
          variant="h5">
          {'Vous n\'êtes pas autorisé à consulter cette page.'}
        </Typography>
      </div>
    </Screen>
  );
};
