import gql from 'graphql-tag';
import actionTypes from './constants';
import { parseItem } from './functions';

/* */
export const getDocuments = (client, variables, fetchPolicy = 'no-cache') => client.query({
  query: gql`${actionTypes.gql.GET_DOCUMENTS}`,
  fetchPolicy,
  variables,
})
  .then((res) => res.data)
  .then((data) => (data.getDocuments))
  .then((documents) => documents && documents.map(parseItem));

/* */
export const getDocument = (client, variables) => client.query({
  query: gql`${actionTypes.gql.GET_DOCUMENT}`,
  variables,
})
  .then((res) => res.data)
  .then((data) => (data.getDocument))
  .then(parseItem);

/* */
export const addDocument = (client, variables) => client.mutate({
  mutation: gql`${actionTypes.gql.ADD_DOCUMENT}`,
  variables,
})
  .then((res) => res.data)
  .then((data) => (data.addDocument))
  .then(parseItem);

/* */
export const updateDocument = (client, variables) => client.mutate({
  mutation: gql`${actionTypes.gql.UPDATE_DOCUMENT}`,
  variables,
})
  .then(res => res.data)
  .then(data => data.updateDocument)
  .then(parseItem);

// /* */
export const deleteDocument = (client, variables) => client.mutate({
  mutation: gql`${actionTypes.gql.DELETE_DOCUMENT}`,
  variables,
})
  .then(({ data }) => data.deleteDocument)
  .then(parseItem);
