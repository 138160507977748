import moment from 'moment';
import { extendMoment } from 'moment-range';
import helpers from '../../utils/helpers';

const momentt = extendMoment(moment);

/* */
export const formatTime = (time) => `${time.substr(0, 2)}:${time.substr(2, 2)}`;

/* */
export const processCourses = (data) => {
  const initial = {
    date: null,
    course: null,
    openDate: null,
    openCourse: null,
  };

  const courses = data.filter((c) => (c.dates && (c.dates.length > 0)));
  if (courses.length) {
    // get by default or first
    const firstC = courses.find((c) => c.default) || courses[0];
    initial.course = firstC;
    initial.date = momentt(firstC.dates[0].from).format('YYYY-MM-DD');
  }

  const openDates = [];
  const openCourses = data.filter((c) => (c.dayOpen && (c.dayOpen > 0)));
  if (openCourses.length) {
    // get by default or first
    initial.openCourse = openCourses.find((c) => c.default) || openCourses[0];
    initial.openDate = momentt().format('YYYY-MM-DD');

    // openDates
    openCourses.forEach(({ dayOpen = 0 }) => {
      const range = momentt.rangeFromInterval('days', Math.max(0, dayOpen - 1));
      const days = Array.from(range.by('days'));
      days.forEach((d) => {
        if (!openDates.includes(d.format('YYYY-MM-DD'))) {
          openDates.push(d.format('YYYY-MM-DD'));
        }
      });
    });
  }

  const availableDates = [];
  courses.forEach((c) => {
    c.dates.forEach((interval) => {
      const range = momentt.range(
        momentt(interval.from),
        momentt(interval.to),
      );
      const days = Array.from(range.by('days'));
      days.forEach((d) => {
        if (!availableDates.includes(d.format('YYYY-MM-DD'))) {
          availableDates.push(d.format('YYYY-MM-DD'));
        }
      });
    });
  });

  availableDates.sort((a, b) => ((momentt(a).isBefore(b)) ? -1 : 1));

  return {
    courses,
    initial,
    openDates,
    openCourses,
    availableDates,
  };
};

/* */
export const checkCourseAvailability = (course, date) => {
  let available = false;
  if (!course) return available;

  course.dates.some((interval) => {
    const range = momentt.range(
      momentt(interval.from),
      momentt(interval.to),
    );
    if (momentt(date).within(range)) {
      available = true;
      return true;
    }
    return false;
  });
  return available;
};

/* */
export const checkCourseOpen = (course, date) => {
  if (!course || !course.dayOpen) return false;

  const range = momentt.rangeFromInterval('days', Math.max(0, course.dayOpen - 1), momentt().startOf('day'));
  return momentt(date).within(range);
};

/* */
export const processSchedule = (type, data) => {
  let processData = {};
  if (type === 'course') {
    processData = helpers.array.toObject(data, 'time');
  }
  if (type === 'accessories') {
    data.forEach((d) => {
      processData[d.id] = helpers.array.toObject(d.schedule, 'time');
    });
  }
  return processData;
};

/* */
const getBestPrices = (accessories, combinations = [], n = 0, indexes = [], path = []) => {
  accessories[n].forEach((prices, idx) => {
    if (indexes.includes(idx)) return;
    const newPath = [...path, prices];
    if (newPath.length !== accessories.length) {
      getBestPrices(accessories, combinations, n + 1, [...indexes, idx], newPath);
    } else {
      const total = newPath.reduce((accumulator, current) =>
        accumulator + parseInt(current.price, 10), 0);
      combinations.push({ prices: newPath, total });
    }
  });
  if (n === 0) {
    combinations.sort((a, b) => a.total - b.total);
    // console.log(combinations);
    return combinations[0];
  }
  return combinations;
};

/* */
export const getDetailedSchedule = (schedules, params) => {
  const details = {};
  const noPlayers = params.players.length;

  // let cpt = 0;
  Object.keys(schedules.course).forEach((timeslot) => {
    // cpt += 1;
    // if (cpt > 1) return;

    const { privatizationAllowed = false } = schedules.course[timeslot];
    const availability = parseInt(schedules.course[timeslot].availability, 10);
    // if (noPlayers > availability) return;

    // Init
    details[timeslot] = {
      free: Boolean(availability >= noPlayers),
      privatizationAllowed,
      others: [],
      players: [],
      availability,
      accessories: [],
      total: {
        price: 0,
        discount: 0,
        initPrice: 0,
      },
    };

    // Set times
    details[timeslot].time = schedules.course[timeslot].time;
    details[timeslot].time9in = schedules.course[timeslot].time9in;
    details[timeslot].time9out = schedules.course[timeslot].time9out;
    // Course other players
    details[timeslot].others = schedules.course[timeslot].players;

    // Course players prices
    if (params.players.length > 0) {
      schedules.course[timeslot].prices.forEach((p, i) => {
        // Get player infos
        const { firstname, lastname, email } = params.players[i];
        // Add to details
        details[timeslot].players.push({
          tid: p.player,
          firstname,
          lastname,
          email,
          price: p.price,
          discount: p.discount,
          initPrice: p.initPrice,
        });

        // Total
        details[timeslot].total.price += p.price;
        details[timeslot].total.initPrice += p.initPrice;
      });
    }

    // Check quantity & calculate best price for each accessory
    // Deficiency = lack of quantity on at least one accessory
    const deficiency = {
      lines: [],
      total: 0,
    };

    const accsKeys = Object.keys(params.accessories);
    if (accsKeys.length > 0) {
      const allPrices = [];
      const accessoryIds = [];

      accsKeys.forEach((id) => {
        let { qty } = params.accessories[id];
        // Check schedule accessories
        if (id in schedules.accessories && timeslot in schedules.accessories[id]) {
          // Check deficiency
          const availableQty = parseInt(schedules.accessories[id][timeslot].availability, 10);
          if (qty > availableQty) {
            // Set deficiency
            deficiency.total += qty - availableQty;
            // Save missing accessories
            deficiency.lines.push({
              id,
              name: params.accessories[id].name,
              qty: qty - availableQty,
            });
            qty = availableQty;
          }

          for (let i = 1; i <= qty; i += 1) {
            accessoryIds.push(id);
            allPrices.push(schedules.accessories[id][timeslot].prices);
          }
        } else {
          deficiency.total += qty;
          deficiency.lines.push({
            id,
            name: params.accessories[id].name,
            qty,
          });
        }
      });

      if (!!allPrices.length && !!accessoryIds.length) {
        // Calculate best price
        const bestPrices = getBestPrices(allPrices);
        bestPrices.prices.forEach((el, i) => {
          // Remember accessory id
          const accessoryId = accessoryIds[i];

          // Subtotals
          const subtotals = {
            price: el.initPrice * 1,
            initPrice: el.price * 1,
          };

          // Add to details
          details[timeslot].accessories.push({
            id: accessoryId,
            name: params.accessories[accessoryId].name,
            qty: 1,
            discount: el.discount,
            price: subtotals.price,
            initPrice: subtotals.initPrice,
            player: el.player,
          });

          // Total
          details[timeslot].total.price += subtotals.price;
          details[timeslot].total.initPrice += subtotals.initPrice;
        });
      }
    }
    // Set deficiency
    details[timeslot].deficiency = deficiency;

    // Calculate total discount
    if (details[timeslot].total.price !== details[timeslot].total.initPrice) {
      const discountDec = details[timeslot].total.initPrice - details[timeslot].total.price;
      details[timeslot].total.discount =
        Math.round((discountDec / details[timeslot].total.initPrice) * 100);
      // console.log(discountDec, details[timeslot].total.discount);
    }

    // console.log(details[timeslot]);
  });

  return details;
};
