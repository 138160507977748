import 'moment/locale/fr';
import moment from 'moment';
import { Formik } from 'formik';
import * as qs from 'query-string';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  List,
  Paper,
  Select,
  ListItem,
  MenuItem,
  InputLabel,
  Typography,
  Button,
  FormControl,
  ListItemText,
} from '@material-ui/core';

import master from '_utils/master';
import * as School from '_redux/school';
import { Screen } from '_components/core';
import { RegisterStudentForm } from '_components/forms';
import { createValidationSchema, validators } from '_utils/validation';

import { ages, levels } from './shared';

/* */
const formatTime = (time) => {
  const hour = time.split(':');
  return `${hour[0]}H${hour[1]}`;
};

/* */
const validationSchema = createValidationSchema({
  phone: validators.required,
  lastname: validators.required,
  firstname: validators.required,
  // dateOfBirth: validators.required,
});

const mapStateToProps = ({ app: { golf } }) => ({ golf });

/* */
const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(1),
  },
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
}));

/* */
const days = {
  1: 'Lundi',
  2: 'Mardi',
  3: 'Mercredi',
  4: 'Jeudi',
  5: 'Vendredi',
  6: 'Samedi',
  0: 'Dimanche',
};

/* */
const displayLesson = (lesson) => {
  const dayOfWeek = moment(lesson.slot.date, 'x').format('d');
  const day = days[dayOfWeek];
  const time = formatTime(lesson.slot.startHour);
  return `${day} - ${time} - ${lesson.teacher} - ${ages[lesson.age]} - ${levels[lesson.level]}`;
};

export default connect(mapStateToProps)(({ golf, location }) => {
  const classes = useStyles();

  const queryParams = qs.parse(location.search);
  const {
    tid = '',
    lastname = null,
    firstname = null,
  } = queryParams;

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(true);

  const [teachers, setTeachers] = useState([]);

  const [filterDay, setFilterDay] = useState('');
  const [filterLevel, setFilterLevel] = useState('');
  const [filterTeacher, setFilterTeacher] = useState('');

  const [lessons, setLessons] = useState([]);
  const [filteredLessons, setFilteredLessons] = useState([]);

  const [students, setStudents] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState('');
  const [registrations, setRegistrations] = useState([]);

  /* */
  const handleFilterChange = (event) => {
    const el = event.target;
    const { name, value } = el;
    // console.log(value);

    if (name === 'filterLevel') {
      setFilterLevel(value || '');
    } else if (name === 'filterTeacher') {
      setFilterTeacher(value || '');
    } else if (name === 'filterDay') {
      setFilterDay(value || '');
    }
  };

  /* */
  const loadRegistrations = (params) => {
    if (!params.tids) return;
    School.api.getStudentRegistrations(master, params)
      .then((res) => {
        setRegistrations(res);
        // console.log(res);
      });
  };

  /* */
  const loadStudents = (lessonSlotId) => {
    School.api.getLessonStudents(master, {
      ids: [lessonSlotId],
    }).then((res) => {
      setStudents(res);
    });
  };

  /* */
  useEffect(() => {
    // ...?tid=Mxx,Gxx
    const tids = tid.split(',');
    loadRegistrations({ golfId: golf.id, tids });
  }, [tid, golf]);

  /* */
  useEffect(() => {
    // console.log('filter !');
    if (!lessons || lessons.length === 0) return;

    let filtered = lessons;
    if (filterLevel) {
      filtered = filtered.filter((l) => l.age === filterLevel);
    }
    if (filterTeacher) {
      filtered = filtered.filter((l) => l.teacherId === filterTeacher);
    }
    if (filterDay) {
      filtered = filtered.filter((l) => moment(l.slot.date, 'x').format('e') === filterDay);
    }
    setSelectedLesson('');
    setFilteredLessons(filtered);
  }, [filterLevel, filterTeacher, filterDay, lessons]);

  /* */
  const handleSelectedLessonChange = (event) => {
    setSelectedLesson(event.target.value);
  };

  /* */
  useEffect(() => {
    // console.log('students !');
    if (selectedLesson) {
      loadStudents(selectedLesson);
    }
  }, [selectedLesson]);

  /* */
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const tids = tid.split(',');
    // console.log('submitting...', values);
    School.api.addLessonStudent(master, {
      id: selectedLesson,
      student: {
        ...values,
        tid: tids[0],
      },
    })
      .then(() => {
        // console.log(res);
        resetForm();
        setSubmitting(false);
        loadStudents(selectedLesson);
        loadRegistrations({ golfId: golf.id, tids });
        setSuccess('Élève ajouté avec succès');
      })
      .catch((e) => setError(e.message));
  };

  /* */
  useEffect(() => {
    // console.log('teachers !');
    School.api.getTeachers(master, {
      golfId: golf.id,
    }).then((res) => {
      setTeachers(res);
      setLoading(false);
    });
  }, [golf]);

  /* */
  useEffect(() => {
    // console.log('lesson !');
    School.api.getLessons(master, { golfId: golf.id })
      .then((res) => {
        // console.log(res);
        setLessons(res);
        setFilteredLessons(res);
      });
  }, [golf]);

  return (
    <Screen
      layout="fluid"
      error={error}
      success={success}
      loading={loading}
      title="EDG">
      {registrations.length > 0 && (
        <Box mb={4}>
          <Typography
            variant="h5"
            component="h2">
            {'Déjà inscrit'}
          </Typography>
          <br />
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Paper>
                <List disablePadding>
                  {registrations.map((r, k) => (
                    <ListItem divider key={k}>
                      <ListItemText
                        key={r.id}
                        primary={displayLesson(r)} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box
        mb={2}
        display="flex"
        justifyContent="space-between">
        <Typography
          variant="h5"
          component="h2">
          {'Inscription à un cours'}
        </Typography>
      </Box>
      <Box position="absolute" right="0" top="0">
        <Button variant="outlined" href={'/lessons/listing'} target="_blank">
          {'Listing des inscrits'}
        </Button>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item lg={4} xs={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="filterDay-label">{'Jour'}</InputLabel>
                  <Select
                    fullWidth
                    label="Jour"
                    name="filterDay"
                    value={filterDay}
                    variant="outlined"
                    labelId="filterDay-label"
                    onChange={handleFilterChange}>
                    <MenuItem value="">{'Tous les jours'}</MenuItem>
                    <MenuItem value="0">{'Lundi'}</MenuItem>
                    <MenuItem value="1">{'Mardi'}</MenuItem>
                    <MenuItem value="2">{'Mercredi'}</MenuItem>
                    <MenuItem value="3">{'Jeudi'}</MenuItem>
                    <MenuItem value="4">{'Vendredi'}</MenuItem>
                    <MenuItem value="5">{'Samedi'}</MenuItem>
                    <MenuItem value="6">{'Dimanche'}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={4} xs={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="filterLevel-label">{'Âge'}</InputLabel>
                  <Select
                    fullWidth
                    label="Âge"
                    defaultValue=""
                    name="filterLevel"
                    variant="outlined"
                    value={filterLevel}
                    labelId="filterLevel-label"
                    onChange={handleFilterChange}>
                    <MenuItem value="">{'Tous les âges'}</MenuItem>
                    {Object.keys(ages).map((a, idx) => (
                      <MenuItem key={idx} value={a}>{ages[a]}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={4} xs={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="filterTeacher-label">{'Professeur'}</InputLabel>
                  <Select
                    fullWidth
                    label="Professeur"
                    variant="outlined"
                    name="filterTeacher"
                    value={filterTeacher}
                    labelId="filterTeacher-label"
                    onChange={handleFilterChange}>
                    <MenuItem value="">{'Tous les professeurs'}</MenuItem>
                    {teachers && teachers.map((t, i) => (
                      <MenuItem key={i} value={t.id}>{t.firstname} {t.lastname}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={12} xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="selectedLesson-label">
                    {'Sélectionnez un cours'}
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Sélectionnez un cours"
                    name="selectedLesson"
                    labelId="selectedLesson-label"
                    variant="outlined"
                    value={selectedLesson}
                    onChange={handleSelectedLessonChange}>
                    <MenuItem value="">{'-'}</MenuItem>
                    {filteredLessons && filteredLessons.map((l, i) => (
                      <MenuItem key={i} value={l.nextSlot}>
                        {displayLesson(l)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
        </Grid>
      </Grid>
      <br /><br />
      {selectedLesson && (
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Formik
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={validationSchema}
              initialValues={{
                phone: '',
                lastname: lastname || '',
                firstname: firstname || '',
                dateOfBirth: '',
              }}>
              {(props) => <RegisterStudentForm {...props} />}
            </Formik>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              <Typography
                gutterBottom
                component="h2"
                color="primary"
                variant="subtitle1">
                {'Liste des inscrits'}
              </Typography>
              <List>
                {students.map((s, i) => (
                  <ListItem key={i}>
                    {s.lastname} {s.firstname} {s.tid ? `(${s.tid})` : ''}
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Screen>
  );
});
