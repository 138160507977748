import * as array from './array';
import * as object from './object';
import * as string from './string';
import * as date from './date';

export default {
  date,
  array,
  object,
  string,
};
