/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, Typography,
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import TableContainer from '@material-ui/core/TableContainer';
import master from '_utils/master';
import * as Booking from '_redux/booking';
import * as Client from '_redux/client';
import * as Customer from '_redux/customer';
import moment from 'moment';
import TeeSheetRow from './TeeSheetRow';
import SetPlayers from '../../../screens/TeeSheet/SetPlayers';
/* */
const styles = (theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  paperTable: {
    height: 'calc(100% - 280px)',
    width: '100%',
    position: 'absolute',
  },
  header: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    margin: 3,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
  picker: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
  wrapper: {
    border: '0, solid, #FFFFFF',
  },
  datePicker: {
    justifySelf: 'center',
  },
  dateSelector: {
    marginTop: 5,
    marginBottom: 5,
    padding: 2,
    backgroundColor: '#EEEEEE',
    width: '100%',
    paddingLeft: 8,
    paddingRight: 8,
    height: 40,
  },
  inputPaper: {
    color: '#000000',
  },
  menuPaper: {
    maxHeight: 100,
  },
  select: {
    paddingRight: 4,
    marginRight: 10,
    '&::before': {
      display: 'none',
    },
  },
});
/* */
class TeeSheets extends Component {
  static defaultProps = {
    loading: false,
    onChange: () => null,
    selectedDate: {
      month: moment().month(),
      day: moment().date(),
      year: moment().year(),
    },
    rowList: [],
    selectedHour: 0,
    dayStartingTime: { hours: 9, minutes: 0, seconds: 0 },
    dayEndingTime: { hours: 18, minutes: 0, seconds: 0 },
    timePerHole: { hours: 0, minutes: 10, seconds: 0 },
    page: 0,
    rowsPerPage: 25,
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.openPlayerSearch = this.openPlayerSearch.bind(this);
    this.state = {
      save: false,
      isOpen: false,
      playerSearch: false,
      players: [],
      accessoriesList: [],
      isPrivatized: 0,
    };
    this.myRef = React.createRef();
  }

  scrollToTime = () => {
    this.myRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.myRef.current) {
      this.scrollToTime();
    }
  }

  /* */
  handleClick(e) {
    const newValue = !e;
    this.setState({ isOpen: newValue });
  }

  /* */
  openPlayerSearch(e) {
    const newValue = !e;
    this.setState({ playerSearch: newValue });
  }

  save = async () => {
    const {
      courses,
      selectedCourse,
      selectedHour,
      selectedDate,
      selectedGolf,
      isPrivatized,
      setReload,
      bookings,
    } = this.props;
    const {
      players,
    } = this.state;
    this.setState({ save: true });
    if (players && players[0]) {
      const newPlayers = [];
      // eslint-disable-next-line no-async-promise-executor, no-unused-vars
      const playersBook = (player) => new Promise(async (resolve, reject) => {
        let playerTid = (
          player.matches &&
          player.matches.length > 0 &&
          player.matches[player.matches.findIndex((match) =>
            match.golfId === selectedGolf.id)] &&
          player.matches[player.matches.findIndex((match) =>
            match.golfId === selectedGolf.id)].reference) || null;
        if (!player.matches.some((item) => item.golfId === selectedGolf.id)) {
          await Client.api.matchAccount(master, {
            id: player.id,
            golfId: selectedGolf.id,
          });
          playerTid = await Customer.api.get(master, {
            id: player.id,
          }).then((r) => r.matches[r.matches.findIndex((match) =>
            match.golfId === selectedGolf.id)].reference);
        }
        const result = await Booking.api.bookingBook(master, {
          nb: 1,
          date: moment(`${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`),
          time: selectedHour,
          time9in: bookings[bookings.findIndex((booking) =>
            booking.time === selectedHour)].time9in,
          lockCode: '0',
          accountId: player.id || '',
          golf: selectedGolf,
          owner: {
            tid: playerTid,
            name: `${player.firstname} ${player.lastname}`,
            email: player.email || '',
            phone: player.phone || '',
          },
          total: {
            initPrice: 0,
            discount: 0,
            price: 0,
            currency: '€',
          },
          course: {
            id: courses[selectedCourse].id,
            type: courses[selectedCourse].type,
            name: courses[selectedCourse].name,
          },
          players: [
            {
              tid: playerTid,
              price: 0,
              initPrice: 0,
              firstname: player.firstname || '',
              lastname: player.lastname || '',
              email: player.email || '',
            },
          ],
          accessories: [], // accessoriesList
          isPrivatized,
          forceAnonymous: 0,
        });
        await Booking.api.bookingConfirm(master, {
          id: result.id,
        });
        resolve();
      });
      players.map((player) => !bookings[bookings.findIndex((booking) =>
        booking.time === selectedHour)].players.some((actualPlayer) =>
        actualPlayer.tid === player.tid) && newPlayers.push(player));
      for (const newPlayer of newPlayers) {
        await playersBook(newPlayer);
      }
      setReload({ reload: true, withLoading: false });
      this.setState({ isOpen: false, playerSearch: false, save: false });
    } else {
      this.setState({ isOpen: false, playerSearch: false, save: false });
    }
  }

  cancel = () => {
    this.setState({
      isOpen: false,
      playerSearch: false,
      accessoriesList: [],
      players: [],
    });
  }

  cancelSelectedPlayers = () => {
    this.setState({
      playerSearch: false,
      accessoriesList: [],
      players: [],
    });
  }

  openPlayerList = (n) => {
    this.setState({ isOpen: true, players: n.players });
    this.props.onChangeHour(n.time);
  }

  addPlayer = (newPlayer) => {
    const { setError, bookings } = this.props;
    this.setState({ save: true });
    if (this.playerExist(newPlayer)) {
      this.setState({ players: this.state.players.filter((item) => item.tid !== newPlayer.tid) });
    } else if (this.state.players.length < 4) {
      if (!newPlayer.id) {
        setError('Ce joueur n\'existe pas');
      } else if (bookings.some((book) =>
        book.players.some((player) =>
          player.tid === newPlayer.tid))) {
        setError('Une réservation existe déjà pour ce joueur dans la même journée');
      } else {
        this.setState({ players: this.state.players.concat(newPlayer) });
      }
    }
    this.setState({ save: false });
  }

  playerExist = (newPlayer) => {
    const {
      players,
    } = this.state;
    let exist = 0;
    // eslint-disable-next-line no-return-assign
    players.map((player) => (player.tid === newPlayer.tid ? exist = 1 : null));
    return exist === 1;
  }

  removePlayer = (player) => {
    const {
      courses,
      selectedCourse,
      setError,
      selectedGolf,
      selectedDate,
      selectedHour,
      setReload,
    } = this.props;
    if (player && player.tid) {
      Promise.resolve()
        .then(() => Booking.api.bookingEdit(master, {
          id: null,
          accountId: 'b96b0f30-8de1-11e9-ad1a-b99f764e87a3', //compte tech@primagolf.fr
          date: moment(`${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`),
          time: selectedHour,
          golf: {
            id: selectedGolf.id,
          },
          course: {
            id: courses[selectedCourse].id,
            type: courses[selectedCourse].type,
            name: courses[selectedCourse].name,
          },
          players: [{
            tid: player.tid,
            email: player.email || '',
            lastname: player.lastname,
            todo: 'cancel',
            firstname: player.firstname,
            bookingId: player.bookingId || null,
          }],
          accessories: [],
        }))
        .catch((error) => setError(error))
        .finally(() => [
          setReload({ reload: true, withLoading: false }),
        ]);
      this.setState({ players: this.state.players.filter((item) => item.tid !== player.tid) });
    }
  }

  render = () => {
    const {
      golf,
      error,
      setError,
      bookings,
      classes,
      loading,
      selectedHour,
      selectedDate,
      searchLoading,
      searchedPlayer,
      playerResult,
    } = this.props;
    const { isOpen, playerSearch, save } = this.state;
    if (loading) return null;

    return (
      <React.Fragment>
        <Slide in={!loading} key={'table'} direction={'up'} mountOnEnter unmountOnExit>
          <Paper className={classes.paperTable}>
            <Box style={{
              height: 'calc(100% - 5px)',
              borderRadius: '5px',
              position: 'absolute',
              width: '100%',
            }} hidden={isOpen}>
              <TableContainer component={Box} style={{ maxHeight: '100%' }} display={'flex'} flex={1}>
                <Table stickyHeader aria-label="sticky table" id='teeSheet' style={{ width: '100%' }}>
                  <TableHead>
                    <TableRow style={{ width: '100%', textAlign: 'center', userSelect: 'none' }}>
                      <TableCell style={{ width: '5%', textAlign: 'center', fontWeight: 'bold' }}>Horaire</TableCell>
                      <TableCell style={{ width: '5%', textAlign: 'center', fontWeight: 'bold' }}>Prix</TableCell>
                      <TableCell style={{ width: '20%', textAlign: 'center', fontWeight: 'bold' }}>Joueur 1</TableCell>
                      <TableCell style={{ width: '20%', textAlign: 'center', fontWeight: 'bold' }}>Joueur 2</TableCell>
                      <TableCell style={{ width: '20%', textAlign: 'center', fontWeight: 'bold' }}>Joueur 3</TableCell>
                      <TableCell style={{ width: '20%', textAlign: 'center', fontWeight: 'bold' }}>Joueur 4</TableCell>
                      <TableCell style={{
                        width: '5%', textAlign: 'center', fontWeight: 'bold', display: 'none',
                      }}>Location</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bookings && bookings.map((n, index) => (
                      <TeeSheetRow
                        key={index}
                        data={n}
                        index={index}
                        selectedDate={selectedDate}
                        myRef={this.myRef}
                        openPlayerList={this.openPlayerList}/>
                    ))}
                    {!bookings && (
                      <TableRow>
                        <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                          <Typography>Parcour Fermé</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        </Slide>
        <SetPlayers
          error={error}
          onSave={save}
          setError={setError}
          golfId={golf.id}
          isOpen={isOpen}
          playerSearch={playerSearch}
          selectedHour={selectedHour}
          bookings={bookings}
          searchedPlayer={searchedPlayer}
          searchLoading={searchLoading}
          playerExist={this.playerExist}
          playerResult={playerResult}
          save={this.save}
          cancel={this.cancel}
          players={this.state.players}
          addPlayer={this.addPlayer}
          removePlayer={this.removePlayer}
          openPlayerList={this.openPlayerList}
          openPlayerSearch={this.openPlayerSearch}
          cancelSelectedPlayers={this.cancelSelectedPlayers}
        />
      </React.Fragment>
    );
  }
}
/* */
export default withStyles(styles, { withTheme: true })(TeeSheets);
