import main from './main';
import errors from './errors';
import formats from './formats';
import countries from './countries';

export default {
  ...main,
  ...errors,
  ...formats,
  ...countries,
};
