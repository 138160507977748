import gql from 'graphql-tag';

/* */
const getBrand = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`
      query(
        $number: String!
      ) {
        payboxGetBrand(
          number: $number
        ) {
          PAYS
          MARQUE
          PRODUIT
        }
      }
    `,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => (data.payboxGetBrand)));

/* */
const createSubscriber = (client, variables) => (
  client.mutate({
    mutation: gql`
      mutation(
        $amount: Int!
        $uuid: String!
        $reference: String!
        $creditCard: PayboxCreditCard!
        $id3D: String
      ) {
        payboxSubscribe(
          id3D: $id3D
          uuid: $uuid
          amount: $amount
          reference: $reference
          creditCard: $creditCard
        ) {
          REFABONNE
          PORTEUR
        }
    }
    `,
    variables,
  })
    .then((res) => res.data)
    .then((data) => (data.payboxSubscribe)));

/* */
const getCreditCards = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`
      query(
        $accountId: String!
      ) {
        getAccountCreditCards(
          accountId: $accountId
        ) {
          id
          name
          type
          brand
          token
          number
          dueDate
          country
        }
      }
    `,
    fetchPolicy,
    variables,
  })
    .then((res) => res.data)
    .then((data) => (data.getAccountCreditCards)));

/* */
const setCreditCard = (client, variables) => (
  client.mutate({
    mutation: gql`
      mutation(
        $id: String!
        $name: String
        $type: String
        $brand: String
        $token: String!
        $number: String!
        $country: String
        $dueDate: String!
        $accountId: String!
      ) {
        setAccountCreditCard(
          id: $id
          name: $name
          type: $type
          brand: $brand
          token: $token
          number: $number
          dueDate: $dueDate
          country: $country
          accountId: $accountId
        ) {
          id
          name
          type
          brand
          token
          number
          dueDate
          country
        }
      }
    `,
    variables,
  })
    .then((res) => res.data)
    .then((data) => (data.setAccountCreditCard)));

/* */
export {
  getBrand,
  createSubscriber,
  setCreditCard,
  getCreditCards,
};
