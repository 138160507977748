/* eslint no-template-curly-in-string: 0 */
export default {
  errors: {
    server: {
      access_denied: 'Accès restreint',
      invalid_token: 'Accès refusé',
      invalid_credentials: 'Accès refusé',
      invalid_key: 'Accès refusé',
      no_key_provided: 'Accès refusé',
    },
    yup: {
      required: 'Obligatoire',
      custom: {
        low_complexity: 'Au moins 1 chiffre, 1 majuscule et 1 minuscule.',
        mismatched_passwords: 'Les mots de passe ne correspondent pas.',
        mismatched_emails: 'Les emails ne correspondent pas.',
      },
      string: {
        email: 'Adresse mail invalide',
        due_date: "Date d'expiration invalide",
        min: 'Doit contenir ${min} caractères minimum',
        type: 'Une chaine de caractères est attendue.',
        required: 'Obligatoire',
      },
      number: {
        type: 'Un chiffre est attendu.',
        integer: 'Un entier est attendu.',
      },
      boolean: {
        type: 'Vrai/faux est attendu.',
      },
      array: {
        type: 'Un tableau est attendu.',
      },
    },
    ws: 'Impossible de se connecter au golf',
    network: 'Connexion réseau interrompue',
    internal: 'Une erreur est survenue.',
    something_wrong: 'Une erreur est survenue.',
    not_found: 'Non trouvé.',
    access_denied: 'Accès restreint',
    user: {
      not_found: 'Non trouvé.',
      create: 'Une erreur est survenue lors de la création.',
      already_registered: 'Cet identifiant est déjà utilisé.',
      password_confirm: 'La confirmation de mot de passe ne correspond pas.',
      logout: 'Déconnexion impossible',
    },
    validation: {
      phone: 'Numéro de téléphone incorrect.',
      email: 'Adresse email incorrecte.',
      id: 'Identifiant invalide.',
      date: 'Date invalide.',
      number: 'Nombre incorrect.',
      string: 'Chaine de caractère incorrecte.',
      IP: 'Adresse IP invalide.',
    },
    customer: {
      prima_ws: 'La mise à jour dans Prima a échoué.',
      lightspeed_create: 'La création dans Lightspeed a échoué',
      lightspeed_update: 'La modification dans Lightspeed a échoué',
    },
  },
  // PAYBOX
  PBX_DEFAULT: 'Une erreur est survenue : refus de paiement.',
  PBX00001: 'La connexion au centre d\'autorisation a échoué.',
  PBX00201: 'Paiement réalisé sans authentification 3D-Secure.',
  PBX00007: 'Date invalide.',
  PBX00008: 'Date de fin de validité incorrecte.',
  PBX00013: 'Montant hors des plafonds définis.',
  PBX00020: 'Cryptogramme visuel non présent.',
  PBX00021: 'Carte non autorisée.',
  PBX00022: 'Plafond atteint.',
  // PAYBOX : centre d'autorisation
  PBX00105: 'Ne pas honorer.',
  PBX00141: 'Carte perdue.',
  PBX00143: 'Carte volée.',
  PBX00151: 'Provision insuffisante ou crédit dépassé.',
  PBX00154: 'Date de validité de la carte dépassée.',
  PBX00157: 'Transaction non permise à ce porteur.',
  PBX00159: 'Suspicion de fraude.',
  PBX00175: 'Nombre d’essais code confidentiel dépassé.',
};
