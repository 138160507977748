import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Paper,
  Typography,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import master from '_utils/master';
import * as LoyaltyCard from '_redux/loyaltyCard';

/* */
const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  submitButton: {
    marginTop: theme.spacing(1),
  },
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
}));

/* */
const LeClubCard = ({ customer }) => {
  const classes = useStyles();
  const cardNumberInputRef = React.createRef();
  /* */
  const [error, setError] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  /* */
  const [searchType, setSearchType] = useState('email');
  const [loyaltyCard, setLoyaltyCard] = useState(null); // Carte LeClub Golf

  /* */
  const assignLoyaltyCard = (data) => {
    const params = {
      accountId: customer.id,
      loyaltyCard: {
        type: 1,
        externalId: data.externalId,
        expirationDate: data.cardExpirationDate,
        program: data.program,
        number: data.cardNumber,
      },
    };

    return LoyaltyCard.api.assign(master, params)
      .then((result) => setLoyaltyCard(result))
      .catch(() => { throw new Error('Une erreur est survenue.'); });
  };

  /* */
  const unassignLoyaltyCard = () => {
    setError(null);
    setWaiting(true);
    setSearchType('card');
    LoyaltyCard.api.unassign(master, { id: loyaltyCard.id })
      .then(() => setLoyaltyCard(null))
      .catch(() => setError('Une erreur est survenue.'))
      .finally(() => setWaiting(false));
  };

  /* */
  const searchFromLeClub = () => {
    const query = (searchType === 'email')
      ? customer.email
      : cardNumberInputRef.current.value.trim();
    // const customerId;

    setError(null);
    setWaiting(true);
    LoyaltyCard.api.searchFromLeClub(master, { query })
      .catch(() => { throw new Error('Service injoignable.'); })
      .then((data) => {
        if (data.length > 0) {
          // on assigne automatiquement
          return assignLoyaltyCard(data[0]);
        }
        if (searchType === 'card' && data.length === 0) {
          throw new Error('Aucun résultat.');
        }

        // on switch de méthode et affiche le formulaire
        return setSearchType('card');
      })
      .catch((e) => setError(e.message))
      .finally(() => setWaiting(false));
  };

  /* */
  const load = () => {
    // On récupère la carte LeClub (type = 1)
    LoyaltyCard.api.findAllBy(master, { accountId: customer.id, type: 1 })
      .then((r) => {
        if (r.length > 0) {
          return setLoyaltyCard(r[0]);
        }
        // Si pas d'email, on affiche le formulaire de recherche par n° de carte
        if (!String(customer.email).includes('@')) {
          return setSearchType('card');
        }
        return searchFromLeClub();
      })
      .finally(() => setContentLoading(false));
  };

  React.useEffect(load, []);

  if (!customer) return null;

  /* */
  const renderAssignForm = () => {
    if (loyaltyCard) return null;

    return (
      <Box>
        <Typography>
          {'Pas de compte associé.'}
        </Typography>
        {searchType === 'card' &&
          <Box mb={1}>
            <TextField
              fullWidth
              name="cardNumber"
              label="Numéro de carte"
              className={classes.textField}
              inputRef={cardNumberInputRef}
              InputLabelProps={{ shrink: true }} />
            <Button
              fullWidth
              size="small"
              color="primary"
              variant="outlined"
              disabled={waiting}
              onClick={searchFromLeClub}
              className={classes.submitButton}>
              {waiting
                ? <CircularProgress color="inherit" size={18} />
                : 'Associer'}
            </Button>
          </Box>
        }
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
      </Box>
    );
  };

  /* */
  const renderLoyaltyCard = () => {
    if (!loyaltyCard) return null;

    return (
      <Box>
        <Typography gutterBottom>
          {'N° de compte:'} <b>{loyaltyCard.externalId}</b>
        </Typography>
        {loyaltyCard.number ? (
          <Box>
            <Typography>
              {'Type de carte: '} <b>{loyaltyCard.program}</b>
            </Typography>
            <Typography>
              {'N° de carte: '} <b>{loyaltyCard.number}</b>
            </Typography>
            <Typography>
              {'Expire le: '} <b>{moment(loyaltyCard.expirationDate).format('DD/MM/YYYY')}</b>
            </Typography>
          </Box>
        ) : (
          <Typography variant="body2">
            {'Aucune carte détectée.'}
          </Typography>
        )}
        <Button
          fullWidth
          size="small"
          color="primary"
          variant="outlined"
          onClick={unassignLoyaltyCard}
          className={classes.submitButton}>
          {'Dissocier'}
        </Button>
      </Box>
    );
  };

  /* */
  const renderContent = () => {
    if (contentLoading) {
      return (<CircularProgress color="secondary" size={24} />);
    }

    return (loyaltyCard) ? renderLoyaltyCard() : renderAssignForm();
  };

  /* */
  return (
    <Paper className={classes.paper}>
      <Typography
        gutterBottom
        component="h2"
        color="primary"
        variant="subtitle1">
        {'LeClub Golf'}
      </Typography>
      {renderContent()}
    </Paper>
  );
};

export default LeClubCard;
