import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  Box,
  IconButton,
  Typography,
} from '@material-ui/core';

import master from '_utils/master';
import { Screen } from '_components/core';
import * as Customer from '_redux/customer';
import { EditCustomerForm } from '_components/forms';
import { createValidationSchema, validators } from '_utils/validation';

/* */
const validationSchema = createValidationSchema({
  email: validators.email,
  lastname: validators.required,
  firstname: validators.required,
});

/* */
const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(3),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  mr8: { marginRight: 8 },
}));

/* */
const Add = ({ history }) => {
  const classes = useStyles();
  const [error, setError] = React.useState(null);

  const onSubmit = (values, { setSubmitting }) => {
    const {
      city,
      country,
      postalCode,
      addressLine,
      addressLine2,
      ...others
    } = values;

    const variables = {
      ...others,
      id: null,
      address: {
        city,
        country,
        postalCode,
        addressLine,
        addressLine2,
      },
    };
    // console.log(variables);
    setError(null);

    // Promise.resolve()
    Customer.api.set(master, variables)
      .then((customer) => {
        const { id, matches } = customer;
        const { reference } = matches[0];
        history.replace(`/customers/${id}?tid=${reference}`);
      })
      .catch((e) => {
        setError(e.message);
        setSubmitting(false);
      });
  };

  /* */
  return (
    <Screen error={error}>
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        flexDirection="row">
        <IconButton
          color="primary"
          className={classes.mr8}
          onClick={() => history.goBack()}>
          <ArrowBackIcon fontSize="small" />
        </IconButton>
        <Typography
          variant="h5"
          component="h2">
          {'Nouveau client'}
        </Typography>
      </Box>
      <Formik
        initialValues={{
          // profile
          email: '',
          phone: '',
          gender: '',
          license: '',
          lastname: '',
          firstname: '',
          dateOfBirth: '',
          // address
          city: '',
          country: '',
          postalCode: '',
          addressLine: '',
          addressLine2: '',
        }}
        onSubmit={onSubmit}
        component={EditCustomerForm}
        validationSchema={validationSchema} />
    </Screen>
  );
};

const mapStateToProps = ({ app }) => ({ ...app });

export default connect(mapStateToProps)(Add);
