import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import ListIcon from '@material-ui/icons/List';
import TodayIcon from '@material-ui/icons/Today';
import RefreshIcon from '@material-ui/icons/Refresh';
import PaymentIcon from '@material-ui/icons/Payment';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import WarningIcon from '@material-ui/icons/Warning';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Link,
  Paper,
  Table,
  Button,
  Select,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import i18n from '_utils/i18n';
import master from '_utils/master';
import * as Radar from '_redux/radar';
import { Screen } from '_components/core';
import { PaymentStateChip } from '_components/elements';

let allCharges = [];
let allInstallments = [];

const CustomTableCell = withStyles((theme) => ({
  head: {
    padding: '6px 10px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
  },
  body: {
    fontSize: 12,
    padding: '6px 10px',
  },
}))(TableCell);

/* */
const useStyles = makeStyles(() => ({
  mr8: { marginRight: 8 },
}));

/* */
const DashboardStripe = ({ golf, token }) => {
  const classes = useStyles();
  /* */
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const [charges, setCharges] = React.useState([]);
  const [summary, setSummary] = React.useState(null);
  const [installments, setInstallments] = React.useState([]);

  const [year, setYear] = React.useState(moment().get('year'));
  const [month, setMonth] = React.useState(moment().get('month'));

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  /* */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /* */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /* */
  const handleChangeMonth = (value) => () => {
    let newYear = year;
    let newMonth = month + value;

    if (newMonth >= 12) {
      newMonth = 0;
      newYear += 1;
    } else if (newMonth <= -1) {
      newMonth = 11;
      newYear -= 1;
    }
    setYear(newYear);
    setMonth(newMonth);
  };

  /* */
  const load = () => {
    setLoading(true);
    setPage(0);

    const params = {
      golfId: golf.id,
      from: moment([year, month, 1]).format('YYYY-MM-DD'),
      to: moment([year, month, 1]).endOf('month').format('YYYY-MM-DD'),
    };

    setTimeout(() => Promise.all([
      Radar.api.listStripeCharges(master, params),
      Radar.api.listStripeInstallments(master, params),
    ])
      .then((data) => {
        const sumCharges = data[0]
          .reduce((acc, { status, amount }) => {
            const idx = acc.findIndex((s) => s.status === status);
            if (idx >= 0) {
              acc[idx].counter += 1;
              acc[idx].amount += amount;
            } else {
              acc.push({ status, amount, counter: 1 });
            }

            return acc;
          }, [])
          .sort((a, b) => b.amount - a.amount);

        const sumInstallments = data[1]
          .reduce((acc, { state, amount }) => {
            const idx = acc.findIndex((s) => s.state === state);
            if (idx >= 0) {
              acc[idx].counter += 1;
              acc[idx].amount += amount;
            } else {
              acc.push({ state, amount, counter: 1 });
            }

            return acc;
          }, [])
          .sort((a, b) => b.amount - a.amount);

        const sumMatchs = data[1]
          .reduce((acc, { matchedAt, chargeId /* , state */ }) => {
            // if (state === 'due') return acc;
            const status = (chargeId || matchedAt) ? 'matched' : 'unmatched';
            const idx = acc.findIndex((s) => s.status === status);
            if (idx >= 0) {
              acc[idx].counter += 1;
            } else {
              acc.push({ status, counter: 1 });
            }

            return acc;
          }, [])
          .sort((a, b) => b.counter - a.counter);

        [allCharges, allInstallments] = data;

        setSummary({
          charges: sumCharges,
          matching: sumMatchs,
          installments: sumInstallments,
        });
        setCharges(allCharges);
        setInstallments(allInstallments);
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false)), 1000);
  };

  /* */
  const filter = (params) => () => {
    let filteredCharges = allCharges;
    let filteredInstallments = allInstallments;

    if (params.includes('matched')) {
      filteredCharges = filteredCharges.filter((c) => c.installmentId || c.matchedAt);
      filteredInstallments = filteredInstallments.filter((i) => i.chargeId || i.matchedAt);
    } else if (params.includes('unmatched')) {
      filteredCharges = filteredCharges.filter((c) => !c.installmentId && !c.matchedAt);
      filteredInstallments = filteredInstallments.filter((i) => !i.chargeId && !i.matchedAt);
    }

    if (params.includes('paid')) {
      filteredCharges = filteredCharges.filter((c) => c.status === 'succeeded');
      filteredInstallments = filteredInstallments.filter((i) => i.state === 'paid');
    } else if (params.includes('failed')) {
      filteredCharges = filteredCharges.filter((c) => c.status === 'failed');
      filteredInstallments = filteredInstallments.filter((i) => i.state === 'failed');
    }

    setCharges(filteredCharges);
    setInstallments(filteredInstallments);
    setPage(0);
  };

  React.useEffect(load, []);

  const years = [2020, 2021, 2022, 2023];
  const months = i18n
    .translations[i18n.locale]
    .date
    .month_names
    .slice(1)
    .map((m) => m.toUpperCase());

  /* */
  return (
    <Screen error={error}>
      <Box
        mb={2}
        display="flex"
        justifyContent="space-between">
        <Box
          display="flex"
          alignItems="center">
          <TrackChangesIcon className={classes.mr8} />
          <Typography
            variant="h5"
            component="h2">
            {'Tableau de bord'}
          </Typography>
        </Box>
        <Box>
          <IconButton
            color="primary"
            className={classes.mr8}
            onClick={handleChangeMonth(-1)}>
            <ChevronLeftIcon fontSize="small" />
          </IconButton>
          <Select
            native
            value={month}
            className={classes.mr8}
            onChange={(event) => setMonth(parseInt(event.target.value, 10))}>
            {months.map((m, i) => <option key={i} value={i}>{m}</option>)}
          </Select>
          <Select
            native
            value={year}
            className={classes.mr8}
            onChange={(event) => setYear(parseInt(event.target.value, 10))}>
            {years.map((y) => <option key={y} value={y}>{y}</option>)}
          </Select>
          <IconButton
            color="primary"
            className={classes.mr8}
            onClick={handleChangeMonth(1)}>
            <ChevronRightIcon fontSize="small" />
          </IconButton>
          <Button
            size="small"
            color="primary"
            onClick={load}
            variant="contained"
            startIcon={<RefreshIcon />}>
            {'Rafraichir'}
          </Button>
        </Box>
      </Box>
      {summary && (
        <Grid container spacing={2}>
          <Grid item xs={6} md={4}>
            <Paper>
              <Box
                px={1}
                py={1}
                display="flex"
                alignItems="center">
                <TodayIcon className={classes.mr8} />
                <Typography variant="h6">
                  {'Échéances'}
                </Typography>
              </Box>
              <List dense disablePadding>
                {summary.installments.map((s, i) => (
                  <ListItem divider key={i}>
                    <ListItemText
                      secondary={s.counter}
                      primary={i18n.t(`stripe.installmentState.${s.state}`)} />
                    <Typography>
                      {i18n.l('currency', s.amount / 100)}
                    </Typography>
                  </ListItem>
                ))}
                <ListItem>
                  <ListItemText primary={'Total'} />
                  <Typography>
                    {i18n.l('currency', summary.installments
                      .reduce((acc, v) => acc + v.amount, 0) / 100)}
                  </Typography>
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item xs={6} md={4}>
            <Paper>
              <Box
                px={1}
                py={1}
                display="flex"
                alignItems="center">
                <ListIcon className={classes.mr8} />
                <Typography variant="h6">
                  {'Matching'}
                </Typography>
              </Box>
              <List dense disablePadding>
                {summary.matching.map((s, i) => (
                  <ListItem divider key={i}>
                    <ListItemText primary={i18n.t(`stripe.matching.${s.status}`)} />
                    <Typography>{s.counter}</Typography>
                  </ListItem>
                ))}
              </List>
              <Button
                fullWidth
                color="primary"
                onClick={filter([])}>
                {'Tous'}
              </Button>
              <Button
                fullWidth
                color="primary"
                onClick={filter(['unmatched'])}>
                {'Orphelins'}
              </Button>
              <Button
                fullWidth
                color="primary"
                onClick={filter(['matched', 'paid'])}>
                {'Rapprochés payés'}
              </Button>
              <Button
                fullWidth
                color="primary"
                onClick={filter(['matched', 'failed'])}>
                {'Rapprochés en échec'}
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={6} md={4}>
            <Paper>
              <Box
                px={1}
                py={1}
                display="flex"
                alignItems="center">
                <PaymentIcon className={classes.mr8} />
                <Typography variant="h6">
                  {'Paiements'}
                </Typography>
              </Box>
              <List dense disablePadding>
                {summary.charges.map((s, i) => (
                  <ListItem divider key={i}>
                    <ListItemText
                      secondary={s.counter}
                      primary={i18n.t(`stripe.chargeStatus.${s.status}`)} />
                    <Typography>
                      {i18n.l('currency', s.amount / 100)}
                    </Typography>
                  </ListItem>
                ))}
                <ListItem>
                  <ListItemText primary={'Total'} />
                  <Typography>
                    {i18n.l('currency', summary.charges
                      .reduce((acc, v) => acc + v.amount, 0) / 100)}
                  </Typography>
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      )}
      <br />
      <br />
      <Typography
        gutterBottom
        variant="h6"
        component="h3">
        {'Échéances'}
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <CustomTableCell>{'Date'}</CustomTableCell>
              <CustomTableCell colSpan={2}>{'Client'}</CustomTableCell>
              <CustomTableCell>{'Prestation'}</CustomTableCell>
              <CustomTableCell>{'Statut'}</CustomTableCell>
              <CustomTableCell align="right">{'Montant'}</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && installments
              .map((data, i) => (
                <TableRow key={i}>
                  <CustomTableCell>
                    {moment(data.dueDate, 'x').format('DD/MM')}
                  </CustomTableCell>
                  <CustomTableCell>
                    {data.accountReference}
                  </CustomTableCell>
                  <CustomTableCell>
                    {data.customer ? (
                      <Link
                        rel="noopener"
                        target="_blank"
                        href={`/stripe/debtor/${data.accountReference}?token=${token}`}>
                        {`${data.customer.firstname} ${data.customer.lastname}`}
                      </Link>
                    ) : '-'}
                  </CustomTableCell>
                  <CustomTableCell>
                    {data.name}
                  </CustomTableCell>
                  <CustomTableCell>
                    <PaymentStateChip type="installment" state={data.state} />
                  </CustomTableCell>
                  <CustomTableCell align="right" style={{ fontWeight: 600 }}>
                    {i18n.l('currency', data.amount / 100)}
                  </CustomTableCell>
                </TableRow>
              ))}
              {!loading && installments.length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan="6"
                    size="medium"
                    align="center">
                    <WarningIcon color="disabled" style={{ color: '#737b83', fontSize: 64 }} />
                    <Typography style={{ color: '#737b83' }} variant="h6">
                      {'Aucune échéance trouvée.'}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan="6"
                    size="medium"
                    align="center">
                    <CircularProgress
                      size={16}
                      thickness={4}
                      color="secondary" />
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <br />
      <Typography
        gutterBottom
        variant="h6"
        component="h3">
        {'Paiements'}
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <CustomTableCell />
              <CustomTableCell>{'Date'}</CustomTableCell>
              <CustomTableCell colSpan={2}>{'Client'}</CustomTableCell>
              <CustomTableCell>{'Prestation'}</CustomTableCell>
              <CustomTableCell>{'Statut'}</CustomTableCell>
              <CustomTableCell align="right">{'Montant'}</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && charges
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data, i) => {
                const outcome = JSON.parse(data.outcome) || null;
                const metadata = JSON.parse(data.metadata) || null;

                return (
                  <TableRow key={i}>
                    <CustomTableCell>
                      {data.installmentId && (
                        <SyncAltIcon fontSize="small" />
                      )}
                    </CustomTableCell>
                    <CustomTableCell>
                      {moment(data.createdAt).format('DD/MM')}
                    </CustomTableCell>
                    <CustomTableCell>
                      {data.accountReference}
                    </CustomTableCell>
                    <CustomTableCell>
                      {data.customer && (
                        <Link
                          rel="noopener"
                          target="_blank"
                          href={`/stripe/debtor/${data.accountReference}?token=${token}`}>
                          {`${data.customer.firstname} ${data.customer.lastname}`}
                        </Link>
                      )}
                      {!data.customer && metadata && (
                        metadata.full_name
                      )}
                    </CustomTableCell>
                    <CustomTableCell>
                      {data.description || '-'}<br />
                      {data.status !== 'succeeded' && outcome && (
                        <Typography variant="caption" style={{ color: '#e53935' }}>
                          {outcome.network_status} / {outcome.reason}
                        </Typography>
                      )}
                    </CustomTableCell>
                    <CustomTableCell>
                      <PaymentStateChip type="charge" state={data.status} />
                    </CustomTableCell>
                    <CustomTableCell align="right" style={{ fontWeight: 600 }}>
                      {i18n.l('currency', data.amount / 100)}
                    </CustomTableCell>
                  </TableRow>
                );
              })}
              {!loading && charges.length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan="7"
                    size="medium"
                    align="center">
                    <WarningIcon color="disabled" style={{ color: '#737b83', fontSize: 64 }} />
                    <Typography style={{ color: '#737b83' }} variant="h6">
                      {'Aucun paiement trouvé.'}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan="7"
                    size="medium"
                    align="center">
                    <CircularProgress
                      size={16}
                      thickness={4}
                      color="secondary" />
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && charges.length > 0 && (
        <TablePagination
          component="div"
          page={page}
          count={charges.length}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          rowsPerPageOptions={[25, 50, 100]}
          onChangeRowsPerPage={handleChangeRowsPerPage} />
      )}
    </Screen>
  );
};

const mapStateToProps = ({ app }) => ({ ...app });

export default connect(mapStateToProps)(DashboardStripe);
