import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import { makeStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {
  Box,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
  CircularProgress,
  FormControl,
  Select
} from '@material-ui/core';

import i18n from '_utils/i18n';
import master from '_utils/master';
import { Screen } from '_components/core';
import * as Subscription from '_redux/subscription';
import CorporateMenu from '_screens/CorporateMenu';

/* */
const List = ({ history, golf }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [subscriptions, setSubscriptions] = useState([]);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);

  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");


  /* */
  const load = () => {
    setLoading(true);

    Subscription.api.search(master, { golfId: golf.id })
      .then((data) => {
        setSubscriptions(data);
        const sortedYears = data.map((d) => [d.startsAt.substring(0, 4), d.expiresAt.substring(0, 4)])
          .flat()
          .sort((a, b) => b - a);
        setYears([...new Set(sortedYears)]);
        setSelectedYear(sortedYears[0]);
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  };

  useEffect(load, []);

  /* */
  useEffect(() => {
    if (selectedYear === "") {
      setFilteredSubscriptions(subscriptions);
    } else {
      setFilteredSubscriptions(subscriptions.filter((s) => s.name.includes(selectedYear)));
      // s.startsAt.substring(0, 4) === selectedYear || s.expiresAt.substring(0, 4) === selectedYear
    }
  }, [selectedYear, subscriptions]);

  /* */
  const handleYearChange = (e) => setSelectedYear(e.target.value);

  /* */
  const goToEdit = (id) => () => history.push(`/corporations/${id}`);

  /* */
  return (
    <Screen
      layout="fluid"
      error={error}
      loading={loading}
      title='Reporting'
      menu={<CorporateMenu />}
    >
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        flexDirection="row">
        <Typography
          variant="h5"
          component="h2">
          {'Gestion des contrats corpo'}
        </Typography>
      </Box>
      <FormControl variant="outlined">
        <Select
          native
          label="Année"
          variant="outlined"
          value={selectedYear}
          onChange={handleYearChange}
        >
          <option key={0} value={""}>Tous</option>
          {years.map((y) => <option key={y} value={y}>{y}</option>)}
        </Select>
      </FormControl>
      <TableContainer
        component={Paper}
        style={{ marginTop: 24 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>{'Entreprise'}</TableCell>
              <TableCell>{'Bénéficiaires'}</TableCell>
              <TableCell>{'Début'}</TableCell>
              <TableCell>{'Fin'}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && filteredSubscriptions
              .map(({
                id,
                account,
                membershipPackage,
                ...data
              }, i) => (
                <TableRow
                  hover
                  key={i}
                  onClick={goToEdit(id)}>
                  <TableCell
                    scope="row"
                    component="th">
                    {data.name}<br/>
                    <Typography variant="caption">{membershipPackage.membership.name}</Typography>
                  </TableCell>
                  <TableCell>{data.countActiveBeneficiaries}</TableCell>
                  <TableCell>
                    {i18n.l('date.formats.default', data.startsAt)}
                  </TableCell>
                  <TableCell>
                    {i18n.l('date.formats.default', data.expiresAt)}
                  </TableCell>
                  <TableCell align="right">
                    <ArrowRightIcon />
                  </TableCell>
                </TableRow>
              ))}
              {!loading && subscriptions.length === 0 && (
                <TableRow>
                  <TableCell size="medium" align="center" colSpan="5">
                    <AccountCircleIcon color="disabled" style={{ color: '#737b83', fontSize: 64 }} />
                    <Typography style={{ color: '#737b83' }} variant="h6">
                      {'Aucun contrat trouvé.'}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell size="medium" align="center" colSpan="5">
                    <CircularProgress
                      size={16}
                      thickness={4}
                      color="secondary" />
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Screen>
  );
};

const mapStateToProps = ({ app }) => ({ ...app });

export default connect(mapStateToProps)(List);
