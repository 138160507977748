import React from 'react'; import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { SnackbarProvider } from 'notistack';
import { frFR } from '@material-ui/core/locale';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
//
import * as Screens from '_screens';
import defaultTheme from '_config/defaultTheme.json';
//
import Bootstrap from './Bootstrap';

const theme = defaultTheme;
if (window.innerWidth <= 480) {
  theme.typography.fontSize = (theme.typography.fontSize || 14) * 0.8;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const muiTheme = createMuiTheme(theme, frFR);

/* */
const App = () => (
  <QueryClientProvider client={queryClient}>
    <MuiThemeProvider theme={muiTheme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/3ds" component={Screens.ThreeDS.SubmitForm} />
            <Route exact path="/3ds/response" component={Screens.ThreeDS.Response} />
            <Route component={Bootstrap} />
          </Switch>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </SnackbarProvider>
    </MuiThemeProvider>
  </QueryClientProvider>
);

export default App;
