import gql from 'graphql-tag';
import actionTypes from './constants';

export const searchPlayers = (client, variables, fetchPolicy = 'no-cache') => client.query({
  query: gql`${actionTypes.gql.SEARCH_PLAYERS}`,
  fetchPolicy,
  variables,
})
  .then((res) => res.data)
  .then((data) => (data.searchCorporatePlayers));

export const getSubscriptions = (client, variables, fetchPolicy = 'no-cache') => client.query({
  query: gql`${actionTypes.gql.GET_SUBSCRIPTIONS}`,
  fetchPolicy,
  variables,
}).then((res) => res.data)
  .then((data) => (data.getCorporateSubscriptions));

export const searchCorpoBookings = (client, variables, fetchPolicy = 'no-cache') => client.query({
  query: gql`${actionTypes.gql.SEARCH_CORPO_BOOKINGS}`,
  fetchPolicy,
  variables,
}).then((res) => res.data)
  .then((data) => (data.searchCorporateBookings));
