import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  Box,
  Grid,
  Paper,
  IconButton,
  Typography,
} from '@material-ui/core';

import i18n from '_utils/i18n';
import master from '_utils/master';
import { Screen } from '_components/core';
import * as Customer from '_redux/customer';
import LeClubCard from '_components/LeClubCard';
import { EditCustomerForm } from '_components/forms';
import { createValidationSchema, validators } from '_utils/validation';

/* */
const getValidationSchema = (match) => createValidationSchema({
  lastname: validators.required,
  firstname: validators.required,
  ...(match && { email: validators.email }),
});

/* */
const initFormValues = (customer) => {
  let { address, dateOfBirth } = customer;
  if (!address) {
    address = {
      city: null,
      country: null,
      postalCode: null,
      addressLine: null,
      addressLine2: null,
    };
  }

  if (dateOfBirth) {
    dateOfBirth = moment(dateOfBirth, 'x').format('DD/MM/YYYY');
  }

  return _.mapValues({
    dateOfBirth,
    ..._.pick(customer, [
      'email',
      'phone',
      'lastname',
      'firstname',
      'license',
      'gender',
    ]),
    ..._.pick(address, [
      'city',
      'country',
      'postalCode',
      'addressLine',
      'addressLine2',
    ]),
  }, (v) => _.defaultTo(v, ''));
};

/* */
const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  mr8: { marginRight: 8 },
}));

/* */
const Edit = ({ golf, history, match: { params } }) => {
  const classes = useStyles();
  /* */
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  /* */
  const [customer, setCustomer] = React.useState(null);
  const [match, setMatch] = React.useState(null);

  const { id = null } = params;

  /* */
  const load = () => {
    Customer.api.get(master, { id })
      .then((cust) => {
        const m = Customer.functions.getMatch(cust, golf);
        if (m) {
          setMatch(m);
        }
        setCustomer(cust);
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  };

  React.useEffect(load, []);
  /* */
  const onSubmit = (values, { setSubmitting }) => {
    // const withLightspeed = !match && customer.lightspeed.reference;
    const message = match ? 'Enregistré.' : 'Récupéré avec succès.';

    const {
      city,
      country,
      postalCode,
      addressLine,
      addressLine2,
      ...others
    } = values;

    const variables = {
      ...others,
      id,
      address: {
        city,
        country,
        postalCode,
        addressLine,
        addressLine2,
      },
    };
    // console.log(variables);
    setError(null);
    setSuccess(null);

    // Promise.resolve()
    Customer.api.set(master, variables)
      .then((cust) => {
        if (!match) {
          const { matches } = cust;
          const { reference } = matches[0];
          history.replace(`/customers/${id}?tid=${reference}`);
        }
        load();
        setSuccess(message);
      })
      .catch((e) => setError(e.message))
      .finally(() => setSubmitting(false));
  };

  if (!customer) return null;

  // check FFG data
  const defaultFfg = {
    handicap: null,
    updatedAt: null,
  };
  const { ffg = defaultFfg } = customer;
  const { handicap, updatedAt } = ffg;

  // console.log(customer);

  /* */
  return (
    <Screen
      error={error}
      loading={loading}
      success={success}>
      <Box
        mb={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-between">
        <Box
          display="flex"
          alignItems="center"
          flexDirection="row">
          <IconButton
            color="primary"
            className={classes.mr8}
            onClick={() => history.goBack()}>
            <ArrowBackIcon fontSize="small" />
          </IconButton>
          <Typography
            variant="h5"
            component="h2">
            {`${customer.firstname} ${customer.lastname}`}
          </Typography>
        </Box>
        {!match && (
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row">
            <Typography variant="caption" color="primary">
              {'Lecture seule'}
            </Typography>
          </Box>
        )}
      </Box>
      <Grid
        container
        spacing={3}
        direction='row'>
        <Grid item xs={12} sm={12} md={6}>
          <Paper className={classes.paper}>
            <Typography
              component="h2"
              variant="subtitle1"
              color="primary">
              {'Référence'}
            </Typography>
            {match && (
              <Typography>
                {'Prima:'} <b>{match.reference}</b>
              </Typography>
            )}
            {customer.lightspeed && (
              <Typography>
                {'Lightspeed: '} <b>{customer.lightspeed.reference}</b>
              </Typography>
            )}
          </Paper>
          {handicap && (
            <Paper className={classes.paper}>
              <Box
                flex={1}
                display="flex"
                alignItems="center"
                flexDirection="row"
                justifyContent="space-between">
                <Box>
                  <Typography
                    gutterBottom
                    component="h2"
                    variant="subtitle1"
                    color="primary">
                    {'FFG'}
                  </Typography>
                  {updatedAt && (
                    <Typography>
                      {`Index mis à jour le ${i18n.l('date.formats.default', moment(updatedAt, 'x').format('YYYY-MM-DD'))}`}
                    </Typography>
                  )}
                </Box>
                <Typography variant="h5" color="primary" component="span">
                  {handicap.toFixed(1)}
                </Typography>
              </Box>
            </Paper>
          )}
        </Grid>
        {match && (
          <Grid item xs={12} sm={12} md={6}>
            <LeClubCard customer={customer} />
          </Grid>
        )}
        </Grid>
        <Formik
          onSubmit={onSubmit}
          enableReinitialize={true}
          validationSchema={getValidationSchema(match)}
          initialValues={initFormValues(customer)}>
        {(props) => <EditCustomerForm readOnly={!match} {...props} />}
      </Formik>
    </Screen>
  );
};

const mapStateToProps = ({ app }) => ({ ...app });

export default connect(mapStateToProps)(Edit);
