import actionTypes from './constants';
import helpers from '../../utils/helpers';

/**
* Search params
*/
export const setSearchText = (text) => (dispatch) => dispatch({
  type: actionTypes.search.SET_TEXT,
  payload: text,
});

/**
 *
 */
export const setGolf = (golf) => (dispatch) => {
  const payload = helpers.object.deepClone(golf);
  payload.options = {};
  golf.options.forEach((o) => {
    payload.options[o.name] = helpers.string.parseJSON(o.value);
  });
  dispatch({
    type: actionTypes.search.SET_GOLF,
    payload,
  });
};

/**
 *
 */
export const setDate = (date) => (dispatch) => dispatch({
  type: actionTypes.search.SET_DATE,
  payload: date,
});

/**
 *
 */
export const setCourse = (course) => (dispatch) => dispatch({
  type: actionTypes.search.SET_COURSE,
  payload: course,
});

/* */
export const addPlayer = (player) => (dispatch) => dispatch({
  type: actionTypes.search.ADD_PLAYER,
  payload: player,
});

/* */
export const replacePlayer = (player, index = null) => (dispatch) => dispatch({
  type: actionTypes.search.REPLACE_PLAYER,
  payload: player,
  index,
});

/* */
export const adjustPlayers = (number) => (dispatch) => dispatch({
  type: actionTypes.search.ADJUST_PLAYERS,
  payload: number,
});

/**
 *
 */
export const addAccessory = (accessory) => (dispatch) => dispatch({
  type: actionTypes.search.ADD_ACCESSORY,
  payload: accessory,
});

/**
 *
 */
export const removeAccessory = (accessory) => (dispatch) => dispatch({
  type: actionTypes.search.REMOVE_ACCESSORY,
  payload: accessory,
});

/**
 *
 */
export const reset = () => (dispatch) => dispatch({
  type: actionTypes.search.RESET,
});

/**
* Data store
*/
export const setGolfsList = (golfs) => (dispatch) => dispatch({
  type: actionTypes.data.SET_GOLFS_LIST,
  payload: golfs,
});

/**
 *
 */
export const setCoursesList = (courses) => (dispatch) => dispatch({
  type: actionTypes.data.SET_COURSES_LIST,
  payload: courses,
});

/**
 *
 */
export const setDatesList = (dates) => (dispatch) => dispatch({
  type: actionTypes.data.SET_DATES_LIST,
  payload: dates,
});

/**
 *
 */
export const setAccessoriesList = (accessories) => (dispatch) => dispatch({
  type: actionTypes.data.SET_ACCESSORIES_LIST,
  payload: accessories,
});

/**
 *
 */
export const setBooking = (details) => (dispatch) => dispatch({
  type: actionTypes.data.SET_BOOKING,
  payload: details,
});

/**
 *
 */
export const updateBooking = (details) => (dispatch) => dispatch({
  type: actionTypes.data.UPDATE_BOOKING,
  payload: details,
});

/**
 *
 */
export const detailBooking = (details) => (dispatch) => dispatch({
  type: actionTypes.data.DETAIL_BOOKING,
  payload: details,
});
