import React, { useState, useEffect } from 'react';
import moment from 'moment';
import * as qs from 'query-string';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TextField,
  StepLabel,
  Step,
  Stepper,
  StepContent,
  FormControl,
  InputAdornment,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  CircularProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import i18n from '_utils/i18n';
import masterClient from '_utils/master';
import { Screen } from '_components/core';
import * as Customer from '_redux/customer';
import * as Subscription from '_redux/subscription';

/* */
const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
  },
}));

/* */
const steps = [
  'Selectionner une formule',
  'Ajouter des options',
  'Paramétrer le paiement',
  'Confirmer',
];

/* */
const getMembershipPrice = (membership, paymentMethod) => {
  if (paymentMethod === 'in_installments') {
    return membership.amount + membership.fees;
  }
  return membership.amount;
};

/* */
const CreateOffer = ({ golf, location }) => {
  const classes = useStyles();

  const queryParams = qs.parse(location.search);
  const { account_id: accountId = null } = queryParams;

  const defaultStartsAt = moment().add(1, 'd').format('YYYY-MM-DD');
  const defaultExpiresAt = moment().add(1, 'y').format('YYYY-MM-DD');

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [account, setAccount] = useState(null);

  const [activeStep, setActiveStep] = useState(0);

  const [options, setOptions] = useState([]);
  const [memberships, setMemberships] = useState([]);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedMembership, setSelectedMembership] = useState(null);

  const [startsAt, setStartsAt] = useState(defaultStartsAt);
  const [expiresAt, setExpiresAt] = useState(defaultExpiresAt);
  const [paymentMethod, setPaymentMethod] = useState('in_installments');

  const [addedOptions, setAddedOptions] = useState([]);
  const [customOptionAmount, setCustomOptionAmount] = useState(0);
  const [customMembershipAmount, setCustomMembershipAmount] = useState('');

  const [paymentPlan, setPaymentPlan] = useState('monthly');
  const [firstInstallmentDate, setFirstInstallmentDate] = useState(moment(startsAt).format('YYYY-MM-DD'));
  const [paymentSchedule, setPaymentSchedule] = useState([]);

  /* */
  const loadData = (year) => {
    // Reset
    setAddedOptions([]);
    setSelectedOption(null);
    setSelectedMembership(null);

    // Get liste des abonnements et options (compl + accessoires)
    return Promise.all([
      Subscription.api.searchMemberships(masterClient, { year }),
      Subscription.api.searchOptions(masterClient, { year }),
    ])
      .then((data) => {
        setOptions(data[1]);
        setMemberships(data[0]);
      });
  };

  /* */
  const calculateTotalAmount = () => {
    if (!selectedMembership) return 0;

    return (customMembershipAmount * 100)
      + addedOptions.reduce((counter, opt) => counter + opt.amount, 0);
  };

  /* */
  const calculateBaseAmount = () => {
    if (!selectedMembership) return 0;

    return getMembershipPrice(selectedMembership, paymentMethod)
      + addedOptions.reduce((counter, opt) => counter + opt.amount, 0);
  };

  /* */
  const calculatePaymentSchedule = () => {
    if (activeStep !== 2) return;

    let schedule;
    if (paymentMethod === 'full') {
      schedule = [];
    } else {
      const additionalAmountOnFirstInstallment = addedOptions.reduce((counter, opt) => {
        if (!opt.isPayableInInstallments) return counter + opt.amount;
        return counter;
      }, 0);

      const scheduledAmount = calculateTotalAmount() - additionalAmountOnFirstInstallment;
      schedule = Subscription.functions.calculatePaymentSchedule({
        startsAt,
        expiresAt,
        paymentPlan,
        scheduledAmount,
        firstInstallmentDate,
        additionalAmountOnFirstInstallment,
        dayOfMonth: moment(firstInstallmentDate).date(),
      });
    }
    setPaymentSchedule(schedule);
  };

  /* */
  const load = () => {
    if (!accountId) {
      setError('Bad request');
      return;
    }

    Customer.api.get(masterClient, { id: accountId })
      .then((data) => {
        if (!data) throw new Error('Client non trouvé.');

        const match = Customer.functions.getMatch(data, golf);
        if (!match) throw new Error('Client non trouvé.');

        setAccount(data);
      })
      .then(() => {
        const year = moment(startsAt).year();
        return loadData(String(year));
      })
      .then(() => setLoading(false))
      .catch((e) => setError(e.message));
  };

  /* */
  useEffect(calculatePaymentSchedule, [
    paymentPlan,
    firstInstallmentDate,
    activeStep,
  ]);

  /* */
  useEffect(load, []);

  /* */
  useEffect(() => {
    if (!selectedMembership) {
      setCustomMembershipAmount('');
    } else {
      setCustomMembershipAmount(getMembershipPrice(selectedMembership, paymentMethod) / 100);
    }
  }, [selectedMembership, paymentMethod]);

  /* */
  useEffect(() => {
    if (selectedOption) {
      setCustomOptionAmount(selectedOption.amount / 100);
    }
  }, [selectedOption]);

  /* */
  const onChangeStartsAt = (e) => {
    const currentYear = moment(startsAt).year();
    const newYear = moment(e.target.value).year();
    // console.log(currentYear, newYear);

    setStartsAt(e.target.value);
    setFirstInstallmentDate(e.target.value);
    if (currentYear !== newYear) {
      loadData(String(newYear));
    }
  };

  /* */
  const showCustomDiff = () => {
    if (!selectedMembership || selectedMembership.amount === 0) return null;

    const customAmount = customMembershipAmount * 100; // cast
    const baseAmount = getMembershipPrice(selectedMembership, paymentMethod);

    const diff = customAmount - baseAmount;
    const percent = (diff / baseAmount) * 100;

    if (diff !== 0) {
      return (
        <Box display="flex" flexDirection="column" style={{ marginLeft: 16 }}>
          <Typography variant="caption">
            {i18n.l('currency', diff / 100)}
          </Typography>
          <Typography variant="caption">
            {i18n.l('percentage', percent)}
          </Typography>
        </Box>
      );
    }

    return null;
  };

  // Stepper navigation
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Add/remove option in membership package
  const addOption = () => {
    setAddedOptions([
      ...addedOptions,
      { ...selectedOption, amount: customOptionAmount * 100 },
    ]);
  };
  const removeOption = (idx) => {
    setAddedOptions(addedOptions.filter((opt, i) => idx !== i));
  };

  /* */
  const handleSubmit = () => {
    const { reference: tid } = Customer.functions.getMatch(account, golf);

    const params = {
      startsAt,
      accountId,
      expiresAt,
      paymentSchedule,
      membershipPackage: JSON.stringify({
        beneficiaries: [tid],
        membership: {
          name: selectedMembership.name,
          amount: customMembershipAmount * 100,
          pgbNumcotis: selectedMembership.pgbNumcotis,
        },
        products: addedOptions.map((o) => ({
          name: o.name,
          type: o.type,
          amount: o.amount,
          pgbCode: o.pgbCode,
          beneficiary: tid,
        })),
      }),
      baseAmount: calculateBaseAmount(),
      totalAmount: calculateTotalAmount(),
      paymentPlan: (paymentMethod === 'in_installments') ? paymentPlan : paymentMethod,
    };

    setSubmitLoading(true);
    Subscription.api.create(masterClient, params)
      .then(handleNext)
      .catch((e) => setError(e.message))
      .finally(() => setSubmitLoading(false));
  };

  /* */
  const renderPackageStep = () => {
    return (
      <div>
        <Box
          display="flex"
          flexDirection="row">
          <TextField
            label="Date de début"
            type="date"
            variant="outlined"
            value={startsAt}
            onChange={onChangeStartsAt}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }} />
          <TextField
            label="Date de fin"
            type="date"
            variant="outlined"
            value={expiresAt}
            onChange={(e) => {
              setExpiresAt(e.target.value);
            }}
            className={classes.textField}
            style={{ marginLeft: 16 }}
            InputLabelProps={{
              shrink: true,
            }} />
        </Box>
        <Autocomplete
          disableClearable
          options={memberships}
          value={selectedMembership}
          onChange={(event, newValue) => {
            setSelectedMembership(newValue);
          }}
          style={{ width: 500 }}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.pgbNumcotis === value.pgbNumcotis}
          renderOption={(option) => (
            <Box display="flex" flexDirection="row" justifyContent="space-between" width={1}>
              <Typography>{option.name}</Typography>
              <Typography>{i18n.l('currency', getMembershipPrice(option, paymentMethod) / 100)}</Typography>
            </Box>
          )}
          renderInput={(params) => <TextField {...params} label="Formule" variant="outlined" className={classes.textField} />}
        />
        <FormControl fullWidth component="div" style={{ marginBottom: 16 }}>
          <RadioGroup
            row
            defaultValue="end"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}>
            <FormControlLabel
              value="in_installments"
              control={<Radio color="primary" />}
              label="Échelonné" />
            <FormControlLabel
              value="full"
              control={<Radio color="primary" />}
              label="Comptant" />
          </RadioGroup>
        </FormControl>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center">
          <TextField
            label="Tarif"
            type="number"
            className={classes.textField}
            value={customMembershipAmount}
            onChange={(e) => setCustomMembershipAmount(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">{'€'}</InputAdornment>,
            }}
            variant="outlined"
            style={{ width: 200 }} />
          {showCustomDiff()}
        </Box>
        {selectedMembership
          && selectedMembership.fees > 0
          && paymentMethod === 'in_installments'
          && (
            <Typography color="primary" variant="caption">
              {`Frais de prélèvement : ${i18n.l('currency', selectedMembership.fees / 100)}`}
            </Typography>
          )}
      </div>
    );
  };

  /* */
  const renderOptionsStep = () => {
    return (
      <div>
        <Box
          mb={3}
          display="flex"
          flexDirection="row">
          <Autocomplete
            disableClearable
            options={options}
            value={selectedOption}
            onChange={(event, newValue) => {
              setSelectedOption(newValue);
            }}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.type === value.type
                && option.pgbCode === value.pgbCode}
            renderOption={(option) => (
              <Box display="flex" flexDirection="row" justifyContent="space-between" width={1}>
                <Typography>{option.name}</Typography>
                <Typography>{i18n.l('currency', option.amount / 100)}</Typography>
              </Box>
            )}
            style={{ marginRight: 16, width: 400 }}
            renderInput={(params) => <TextField {...params} label="Option" variant="outlined" />} />
          {selectedOption && (
            <TextField
              label="Tarif"
              type="number"
              value={customOptionAmount}
              onChange={(e) => setCustomOptionAmount(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{'€'}</InputAdornment>,
              }}
              variant="outlined"
              style={{ marginRight: 16, width: 150 }} />
          )}
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={addOption}
            disabled={!selectedOption}>
            {'Ajouter'}
          </Button>
        </Box>
        <hr />
        <br />
        {addedOptions.length > 0 ? (
          <div>
            {addedOptions.map((opt, idx) => (
              <Box
                mb={1}
                key={idx}
                display="flex"
                flexDirection="row"
                alignItems="center">
                <Button size="small" variant="outlined" color="primary" style={{ marginRight: 16 }} onClick={() => removeOption(idx)}>{'Supprimer'}</Button>
                <Typography style={{ marginRight: 24, width: 250 }}>{opt.name}</Typography>
                <Typography style={{ marginRight: 16 }}>{i18n.l('currency', opt.amount / 100)}</Typography>
                {!opt.isPayableInInstallments && (
                  <Typography variant="caption" color="primary">{'Non échelonnable'}</Typography>
                )}
              </Box>
            ))}
            <br />
            <Typography>{'Total : '}<b>{i18n.l('currency', addedOptions.reduce((counter, opt) => counter + opt.amount, 0) / 100)}</b></Typography>
            <Typography>
              {'Total à régler : '}
              <b>{i18n.l('currency', calculateTotalAmount() / 100)}</b></Typography>
          </div>
        ) : (
          <Typography>{'Aucune option sélectionnée.'}</Typography>
        )}
      </div>
    );
  };

  /* */
  const renderPaymentStep = () => {
    return (
      <div>
        {paymentMethod === 'full' ? (
          <Typography gutterBottom variant="h6">{'Paiement comptant'}</Typography>
        ) : (
          <Typography gutterBottom variant="h6">{'Échéancier'}</Typography>
        )}
        <Typography gutterBottom>{'À régler : '}<b>{i18n.l('currency', calculateTotalAmount() / 100)}</b></Typography>
        {paymentMethod === 'in_installments' && (
          <div>
            <br/>
            <FormControl fullWidth component="div">
              <FormLabel>{'Nombre de mensualités'}</FormLabel>
                <RadioGroup
                  row
                  defaultValue="end"
                  value={paymentPlan}
                  onChange={(e) => setPaymentPlan(e.target.value)}>
                <FormControlLabel
                  value="monthly"
                  control={<Radio color="primary" />}
                  label="Auto" />
                <FormControlLabel
                  value="3_month"
                  control={<Radio color="primary" />}
                  label="3" />
                <FormControlLabel
                  value="6_month"
                  control={<Radio color="primary" />}
                  label="6" />
                <FormControlLabel
                  value="10_month"
                  control={<Radio color="primary" />}
                  label="10" />
              </RadioGroup>
            </FormControl>
            <TextField
              label="Première échéance le"
              type="date"
              variant="outlined"
              defaultValue={firstInstallmentDate}
              onChange={(event) => {
                setFirstInstallmentDate(event.target.value);
              }}
              className={classes.textField}
              style={{ marginRight: 16 }}
              InputLabelProps={{
                shrink: true,
              }} />
            <Paper style={{ maxWidth: 500 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>
                      {'Date'}
                    </TableCell>
                    <TableCell align="right">
                      {'Montant'}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentSchedule.map((l, i) =>
                    <TableRow key={i}>
                      <TableCell>
                        {i + 1}.
                      </TableCell>
                      <TableCell>
                        {i18n.l('date.formats.default', l.dueDate)}
                      </TableCell>
                      <TableCell align="right">
                        {i18n.l('currency', l.amount / 100)}
                      </TableCell>
                    </TableRow>)}
                </TableBody>
              </Table>
            </Paper>
          </div>
        )}
      </div>
    );
  };

  /* */
  const renderSummaryStep = () => {
    return (
      <div>
        <Typography gutterBottom color="primary">{'Formule'}</Typography>
        <Typography>
          {'Du '} {i18n.l('date.formats.default', startsAt)}
          {' au '}{i18n.l('date.formats.default', expiresAt)}
        </Typography>
        <Typography>{selectedMembership.name}</Typography>
        <Typography><b>{i18n.l('currency', customMembershipAmount)}</b></Typography>
        <br/>
        <Typography gutterBottom color="primary">{'Options'}</Typography>
        {addedOptions.length > 0 ? (
          <div>
            {addedOptions.map((opt, idx) => (
              <Box
                key={idx}
                display="flex"
                flexDirection="row">
                <Typography>{`${opt.name} - ${i18n.l('currency', opt.amount / 100)}`}</Typography>
              </Box>
            ))}
            <Typography><b>{i18n.l('currency', addedOptions.reduce((counter, opt) => counter + opt.amount, 0) / 100)}</b></Typography>
          </div>
        ) : (
          <Typography>{'Aucune option sélectionnée.'}</Typography>
        )}
        <br/>
        <Typography gutterBottom color="primary">{'Paiement'}</Typography>
        {paymentMethod === 'full' ? (
          <Typography>{'Comptant'}</Typography>
        ) : (
          <>
            <Typography>{'Échelonné en '}<b>{paymentSchedule.length}</b>{' mensualités'}</Typography>
            <Typography>{'Première échéance le '}<b>{i18n.l('date.formats.default', firstInstallmentDate)}</b></Typography>
          </>
        )}
        <Typography>{'Total à régler : '}<b>{i18n.l('currency', calculateTotalAmount() / 100)}</b></Typography>
        <br />
        {paymentMethod === 'scheduled' && (
          <Paper style={{ maxWidth: 500 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>
                    {'Date'}
                  </TableCell>
                  <TableCell align="right">
                    {'Montant'}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentSchedule.map((l, i) =>
                  <TableRow key={i}>
                    <TableCell>
                      {i + 1}.
                    </TableCell>
                    <TableCell>
                      {i18n.l('date.formats.default', l.dueDate)}
                    </TableCell>
                    <TableCell align="right">
                      {i18n.l('currency', l.amount / 100)}
                    </TableCell>
                  </TableRow>)}
              </TableBody>
            </Table>
          </Paper>
        )}
      </div>
    );
  };

  /* */
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return renderPackageStep();
      case 1:
        return renderOptionsStep();
      case 2:
        return renderPaymentStep();
      case 3:
      default:
        return renderSummaryStep();
    }
  };

  /* */
  const renderStepper = () => {
    return (
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>
              {index === 0 && (
                <React.Fragment>
                  {activeStep > 0 ? (
                    <Box>
                      <Typography variant="body2" color="primary">
                        {'Du '}{i18n.l('date.formats.default', startsAt)}
                        {' au '}{i18n.l('date.formats.default', expiresAt)}
                      </Typography>
                      <Typography variant="body2" color="primary">{selectedMembership.name}</Typography>
                      <Typography variant="body2" color="primary"><b>{i18n.l('currency', customMembershipAmount)}</b></Typography>
                    </Box>
                  ) : label}
                </React.Fragment>
              )}
              {index === 1 && (
                <React.Fragment>
                  {activeStep > 1 ? (
                    <Box>
                      {addedOptions.length > 0 ? (
                        <Typography variant="body2" color="primary">{addedOptions.length}{' option(s)'}</Typography>
                      ) : (
                        <Typography variant="body2" color="primary">{'Aucune option'}</Typography>
                      )}
                      <Typography variant="body2" color="primary"><b>{i18n.l('currency', addedOptions.reduce((counter, opt) => counter + opt.amount, 0) / 100)}</b></Typography>
                    </Box>
                  ) : label}
                </React.Fragment>
              )}
              {index === 2 && (
                <React.Fragment>
                  {activeStep > 2 ? (
                    <Box>
                      {paymentMethod === 'full' ? (
                        <Typography variant="body2" color="primary">{'Paiement comptant'}</Typography>
                      ) : (
                        <div>
                          <Typography variant="body2" color="primary">{'Paiement échelonné'}</Typography>
                          <Typography variant="body2" color="primary">{'En '}<b>{paymentSchedule.length}</b>{' mensualités'}</Typography>
                        </div>
                      )}
                      <Typography variant="body2" color="primary"><b>{i18n.l('currency', calculateTotalAmount() / 100)}{' à régler'}</b></Typography>
                    </Box>
                  ) : label}
                </React.Fragment>
              )}
              {index === 3 && (
                <React.Fragment>
                  {activeStep > 3 ? (
                    <Typography variant="body2" color="primary">{'Proposition envoyée par e-mail'}<br /><b>{account.email}</b></Typography>
                  ) : label}
                </React.Fragment>
              )}
              {index > 3 && label}
            </StepLabel>
            <StepContent>
              <Box sx={{ mb: 2 }}>
                <div>
                  {index !== steps.length - 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={!selectedMembership}
                      onClick={handleNext}>
                      {'Continuer'}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={submitLoading}
                      onClick={handleSubmit}>
                      {submitLoading ? <CircularProgress color="secondary" size={24} /> : 'Envoyer'}
                    </Button>
                  )}
                  {index > 0 && (
                    <Button
                      color="primary"
                      size="small"
                      disabled={index === 0}
                      onClick={handleBack}>
                      {'Retour'}
                    </Button>
                  )}
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    );
  };

  /* */
  return (
    <Screen error={error} loading={loading}>
      <Box mb={2}>
        {account && (
          <Typography
            gutterBottom
            variant="h5"
            component="h2">
            {`${account.firstname} ${account.lastname} / ${account.email}`}
          </Typography>
        )}
        <Typography
          variant="h6"
          component="h3">
          {'Proposer une offre d\'abonnement'}
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item lg={3} md={4}>
          {renderStepper()}
        </Grid>
        <Grid item lg={9} md={8}>
          {getStepContent(activeStep)}
        </Grid>
      </Grid>
    </Screen>
  );
};

const mapStateToProps = ({ app }) => ({ ...app });

export default connect(mapStateToProps)(CreateOffer);
