export default {
  date: {
    abbr_day_names: [
      'dim',
      'lun',
      'mar',
      'mer',
      'jeu',
      'ven',
      'sam',
    ],
    abbr_month_names: [
      null,
      'jan.',
      'fév.',
      'mar.',
      'avr.',
      'mai',
      'juin',
      'juil.',
      'août',
      'sept.',
      'oct.',
      'nov.',
      'déc.',
    ],
    day_names: [
      'dimanche',
      'lundi',
      'mardi',
      'mercredi',
      'jeudi',
      'vendredi',
      'samedi',
    ],
    formats: {
      default: '%d/%m/%Y',
      short: '%a %e %b',
      long: '%A %e %B',
      short_m: '%e %b',
      short_y: '%a %e %b %Y',
      long_y: '%A %e %B %Y',
    },
    month_names: [
      null,
      'janvier',
      'février',
      'mars',
      'avril',
      'mai',
      'juin',
      'juillet',
      'août',
      'septembre',
      'octobre',
      'novembre',
      'décembre',
    ],
  },
  time: {
    am: 'am',
    formats: {
      default: '%d %B %Y %Hh %Mmin %Ss',
      long: '%A %d %B %Y %Hh%M',
      short: '%d %b %Hh%M',
      short_m: '%H:%M',
      short_y: '%d/%m/%Y %Hh%M',
    },
    pm: 'pm',
  },
  number: {
    currency: {
      format: {
        delimiter: ' ',
        format: '%n%u',
        precision: 2,
        separator: ',',
        significant: false,
        strip_insignificant_zeros: false,
        unit: '€',
      },
    },
    percentage: {
      format: {
        delimiter: '',
        format: '%n%',
      },
    },
    format: {
      delimiter: ' ',
      precision: 2,
      separator: ',',
      significant: false,
      strip_insignificant_zeros: false,
    },
  },
};
