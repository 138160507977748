import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Link } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import i18n from '_utils/i18n';

/* */
const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: 100,
    marginBottom: theme.spacing(2),
  },
}));

/* */
export default ({ error, retry, back }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <ErrorOutlineIcon
        className={classes.icon}
        fontSize="inherit" />
      <Typography
        component="h1"
        gutterBottom
        variant="h4">
        {'Oups !'}
      </Typography>
      <Typography
        component="p"
        gutterBottom
        variant="subtitle1">
        {i18n.t(error.message || error)}
      </Typography>
      {retry && (
        <Button
          component="h2"
          gutterBottom
          onClick={retry}
          variant="h5">
          {'Essayer a nouveau'}
        </Button>
      )}
      {back && (
        <Link href={back}>{'Retour'}</Link>
      )}
    </div>
  );
};
