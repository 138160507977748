import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Chip,
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';
/* */
import helpers from '_utils/helpers';

const PERIODS = helpers.date.getPeriods();

/* */
const useStyles = makeStyles((theme) => ({
  root: {
    height: 24,
    fontSize: 11,
    borderRadius: 12,
    margin: '0 6px 6px 0',
  },
  textField: {
    marginRight: theme.spacing(3),
  },
}));

/* */
export default ({
  values,
  typeOptions,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  productOptions,
}) => {
  const classes = useStyles();

  /* */
  const setDateRangeInput = (period) => () => {
    setFieldValue('to', PERIODS[period][1]);
    setFieldValue('from', PERIODS[period][0]);
  };

  /* */
  return (
    <React.Fragment>
      <Box
        mb={2}
        display="flex"
        flexDirection="row">
        <TextField
          select
          name="dateType"
          label="A la date de"
          onChange={handleChange}
          value={values.dateType}
          className={classes.textField}
          SelectProps={{ native: true }}
          InputLabelProps={{ shrink: true }}>
          <option value="date">{'Opération'}</option>
          <option value="serviceDate">{'Prestation'}</option>
        </TextField>
        <TextField
          label="Du"
          name="from"
          value={values.from}
          onChange={handleChange}
          placeholder="jj/mm/aaaa"
          InputLabelProps={{ shrink: true }} />
        <TextField
          label="Au"
          name="to"
          value={values.to}
          onChange={handleChange}
          placeholder="jj/mm/aaaa"
          className={classes.textField}
          InputLabelProps={{ shrink: true }} />
        <Box>
          <Box>
            <Chip
              color="primary"
              label="Aujourd'hui"
              classes={{ root: classes.root }}
              onClick={setDateRangeInput('today')} />
            <Chip
              color="primary"
              label="Cette semaine"
              classes={{ root: classes.root }}
              onClick={setDateRangeInput('thisWeek')} />
            <Chip
              color="primary"
              label="Ce mois"
              classes={{ root: classes.root }}
              onClick={setDateRangeInput('thisMonth')} />
            <Chip
              color="primary"
              label="Cette année"
              classes={{ root: classes.root }}
              onClick={setDateRangeInput('thisYear')} />
          </Box>
          <Box>
            <Chip
              label="Hier"
              classes={{ root: classes.root }}
              onClick={setDateRangeInput('yesterday')} />
            <Chip
              label="La semaine dernière"
              classes={{ root: classes.root }}
              onClick={setDateRangeInput('lastWeek')} />
            <Chip
              label="Le mois dernier"
              classes={{ root: classes.root }}
              onClick={setDateRangeInput('lastMonth')} />
            <Chip
              label="L'année dernière"
              classes={{ root: classes.root }}
              onClick={setDateRangeInput('lastYear')} />
          </Box>
        </Box>
      </Box>
      <Box
        mb={2}
        display="flex"
        flexDirection="row">
        <TextField
          select
          name="type"
          label="Type"
          style={{ flex: 1 }}
          value={values.type}
          onChange={handleChange}
          className={classes.textField}
          SelectProps={{ native: true }}>
          <option value="0">{'Tous'}</option>
          {Object.keys(typeOptions)
            .map((i) => <option key={i} value={i}>{typeOptions[i]}</option>)}
        </TextField>
        <TextField
          select
          name="product"
          label="Produit"
          style={{ flex: 1 }}
          value={values.product}
          onChange={handleChange}
          className={classes.textField}
          SelectProps={{ native: true }}>
          <option value="0">{'Tous'}</option>
          {Object.keys(productOptions)
            .map((i) => <option key={i} value={i}>{productOptions[i]}</option>)}
        </TextField>
        <TextField
          name="account"
          label="Client (contient)"
          style={{ flex: 1 }}
          value={values.account}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          placeholder="Nom ou prénom" />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end">
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitting}>
          {isSubmitting
            ? <CircularProgress color="inherit" size={24} />
            : 'Rechercher'}
        </Button>
      </Box>
    </React.Fragment>
  );
};
