import axios from 'axios';
import store from '_redux';
import config from '_config';

// ----------------------------------------------------------------------------
// REST API
// ----------------------------------------------------------------------------
const instance = axios.create({
  baseURL: config.masterBasePath,
});

function getOptions({ headers, ...options }) {
  const context = headers || {};
  const {
    token,
  } = store.getState().app;
  if (token) context['x-api-token'] = token;
  return {
    headers: context,
    ...options,
  };
}

const api = (url, method, options) => instance({
  url,
  method,
  ...options,
})
  .catch((error) => {
    if (error.response) {
      // if (typeof error.response.data === 'object') {
      //   return error.response.data;
      // }
      throw new Error('errors.something_wrong');
    } else if (error.request) {
      throw new Error('errors.network');
    }
    throw new Error('errors.internal');
  })
  .then((res) => {
    const { data } = res;
    switch (res.headers && res.headers['content-type']) {
      case 'application/json':
        if (data && data.status === 'success') {
          return data.data;
        }
        throw new Error((res && res.data && res.data.error) || 'errors.something_wrong');
      default:
        return data;
    }
  });

export default {
  api,
  get: (url, params, headers) => api(url, 'GET', getOptions({ params, headers })),
  post: (url, data, headers) => api(url, 'POST', getOptions({ data, headers })),
  put: (url, data, headers) => api(url, 'PUT', getOptions({ data, headers })),
  delete: (url, params, headers) => api(url, 'DELETE', getOptions({ params, headers })),
  blob: (url, params, options = {}) => api(url, 'GET', getOptions({ params, responseType: 'blob', ...options })),
};
