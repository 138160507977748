import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Done,
  Clear,
  ReportProblemOutlined,
} from '@material-ui/icons';

import i18n from '_utils/i18n';

/* */
const useStyles = makeStyles(() => ({
  chipError: { color: '#b71c1c', backgroundColor: '#ffcdd2' },
  chipDefault: { color: '#212121', backgroundColor: '#EEEEEE' },
  chipSuccess: { color: '#1B5E20', backgroundColor: '#C8E6C9' },
  chipWarning: { color: '#E65100', backgroundColor: '#FFCC80' },
}));

/* */
export default ({ type, state }) => {
  const classes = useStyles();

  let icon;
  let className = classes.chipDefault;
  let label = 'stripe.installmentState.due';

  if (type === 'charge') {
    if (state === 'succeeded') {
      icon = <Done />;
      className = classes.chipSuccess;
      label = 'stripe.chargeStatus.succeeded';
    } else if (state === 'failed') {
      icon = <Clear />;
      className = classes.chipError;
      label = 'stripe.chargeStatus.failed';
    }
  } else if (state === 'paid') {
    icon = <Done />;
    className = classes.chipSuccess;
    label = 'stripe.installmentState.paid';
  } else if (state === 'failed') {
    icon = <Clear />;
    className = classes.chipError;
    label = 'stripe.installmentState.failed';
  } else if (state === 'cancelled') {
    icon = <Clear />;
    className = classes.chipDefault;
    label = 'stripe.installmentState.cancelled';
  } else if (state === 'unpaid') {
    icon = <ReportProblemOutlined />;
    className = classes.chipWarning;
    label = 'stripe.installmentState.unpaid';
  }

  return (
    <Chip
      icon={icon}
      size="small"
      color="primary"
      label={i18n.t(label)}
      className={className} />
  );
};
