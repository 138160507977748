import React from 'react';
import { connect } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Box, Fab, Typography } from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import master from '_utils/master';
import helpers from '_utils/helpers';
import { Screen } from '_components/core';
import * as Settings from '_redux/settings';

/* */
const columns = [
  {
    name: 'lastname',
    label: 'Nom',
    options: {
      filter: false,
    },
  },
  {
    name: 'firstname',
    label: 'Prénom',
    options: {
      filter: false,
    },
  },
  {
    name: 'contactEmail',
    label: 'E-mail',
    options: {
      sort: false,
      filter: false,
    },
  },
  {
    name: 'reference',
    label: 'Référence',
    options: {
      sort: false,
      customFilterListOptions: {
        render: (v) => `Référence : ${v}`,
      },
      filterOptions: {
        names: ['M', 'G'],
        logic: (location, filters) => {
          if (filters.length) {
            return !location.includes(filters[0]);
          }
          return false;
        },
      },
    },
  },
];

/* */
const options = {
  // header
  selectableRowsHeader: false,
  // toolbar
  print: false,
  searchOpen: true,
  viewColumns: false,
  downloadOptions: {
    separator: ';',
    filename: 'comptes.csv',
  },
  // pagination
  rowsPerPage: 25,
  rowsPerPageOptions: [25, 50, 100],
  // translations
  textLabels: i18n.translations.fr.MUIDataTable,
};

/* */
const Accounts = ({ golf }) => {
  /* */
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [accounts, setAccounts] = React.useState([]);

  /* */
  const load = () => {
    setLoading(true);

    Settings.api.getAccounts(master, { id: golf.id })
      .then(setAccounts)
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  };

  React.useEffect(load, []);

  /* */
  const onRowsDelete = ({ data }) => {
    const deleted = data.map((d) => ({
      ...helpers.object.filterByKeys(accounts[d.dataIndex], ['id', 'reference']),
    }));

    const params = { id: golf.id, accounts: deleted };

    setError(null);
    setSuccess(null);

    Settings.api.deleteAccounts(master, params)
      .then(() => setSuccess('Données supprimées.'))
      .catch((e) => setError(e.message))
      .finally(load);
  };

  /* */
  return (
    <Screen
      error={error}
      loading={loading}
      success={success}>
      <Box
        mb={4}
        display="flex"
        alignItems='center'
        flexDirection="column">
        <Typography
          gutterBottom
          variant="h5"
          align="center"
          component="h2">
          {'Comptes PrimaWeb Services'}
        </Typography>
        <Fab aria-label="Rafraichir" onClick={load}>
          <RefreshIcon />
        </Fab>
      </Box>
      <MUIDataTable
        data={accounts}
        columns={columns}
        options={{
          ...options,
          onRowsDelete,
        }} />
    </Screen>
  );
};

const mapStateToProps = ({ app }) => ({ ...app });

export default connect(mapStateToProps)(Accounts);
