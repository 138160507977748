import gql from 'graphql-tag';

import helpers from '_utils/helpers';
import actionTypes from './constants';

// -----------------------------------------------------------------------------
// LOGIN EVENTS
// -----------------------------------------------------------------------------

/* */
export const connect = () => (dispatch) => dispatch({
  type: actionTypes.CONNECT,
});

/* */
export const autoLogin = (email, token) => (dispatch) => dispatch({
  type: actionTypes.LOGIN_SUCCESS,
  payload: token,
});

/* */
// export const setUser = user => dispatch => dispatch({
//   type: actionTypes.SET_USER,
//   payload: user,
// });

/* */
export const logout = (err) => (dispatch) => dispatch({
  type: actionTypes.LOGOUT,
  payload: err,
});

// -----------------------------------------------------------------------------
// API KEY - TEMP
// -----------------------------------------------------------------------------

/* */
export const session = (client, token) => (dispatch) => {
  dispatch({
    type: actionTypes.LOGIN_SUCCESS,
    payload: token,
  });
  client.query({
    query: gql`${actionTypes.gql.GET_MY_GOLF}`,
  })
    .then((res) => res.data)
    .then(({ getMyGolf }) => ({ golf: getMyGolf }))
    .then(({ golf }) => {
      if (golf) {
        const payload = helpers.object.deepClone(golf);
        payload.options = {};
        golf.options.forEach((o) => {
          payload.options[o.name] = helpers.string.parseJSON(o.value);
        });
        dispatch({
          type: actionTypes.LOAD_GOLF,
          payload,
        });
      } else {
        dispatch({
          type: actionTypes.NO_GOLF,
        });
      }
    })
    .catch((err) => dispatch({
      type: actionTypes.NO_GOLF,
      payload: err.message,
    }));
};

// -----------------------------------------------------------------------------
// GOLF
// -----------------------------------------------------------------------------

/* */
export const loadGolf = (client, variables, fetchPolicy = 'no-cache') => (dispatch) => (
  client.query({
    query: gql`${actionTypes.gql.GET_GOLF}`,
    fetchPolicy,
    variables,
  })
    .then((res) => res.data)
    .then(({ getGolf }) => ({ golf: getGolf }))
    .then(({ golf }) => {
      if (golf) {
        const payload = helpers.object.deepClone(golf);
        payload.options = {};
        golf.options.forEach((o) => {
          payload.options[o.name] = helpers.string.parseJSON(o.value);
        });
        dispatch({
          type: actionTypes.LOAD_GOLF,
          payload,
        });
      } else {
        dispatch({
          type: actionTypes.NO_GOLF,
        });
      }
    })
    .catch((err) => dispatch({
      type: actionTypes.NO_GOLF,
      payload: err.message,
    })));

/* */
export const noGolf = () => (dispatch) => dispatch({
  type: actionTypes.NO_GOLF,
});

// -----------------------------------------------------------------------------
// NAVIGATION DRAWER
// -----------------------------------------------------------------------------

/* */
export const togglePanelNav = (panel) => (dispatch) => dispatch({
  type: actionTypes.TOGGLE_PANEL_NAV,
  payload: panel,
});
