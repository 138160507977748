import React from 'react';
import MaskedInput from 'react-text-mask';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Checkbox,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
  FormControlLabel,
} from '@material-ui/core';
import master from '_utils/master';
import * as Paybox from '_redux/paybox';
import { CreditCardPreview } from '_components/elements';

/* */
const securityImg = 'https://static.prima.golf/images/payment/up2pay.png';

/* */
const styles = (theme) => ({
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
  },
  submitButton: {
    marginTop: theme.spacing(4),
  },
  securityText: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  marginT: {
    marginTop: theme.spacing(1),
  },
});

/* */
const DueDateMaskedInput = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/]}
    />
  );
};

const initialState = {
  type: null,
  number: '',
  brand: null,
  dueDate: '',
  waiting: false,
  isValid: false,
  saveImprint: false,
};

/* */
class CompleteCreditCardForm extends React.Component {
  /* */
  static defaultProps = {
    imprintUse: 'disabled',
  }

  /* */
  state = { ...initialState };

  extraInfos = {};

  /* */
  isValid() {
    const { imprintUse } = this.props;
    const { saveImprint, isValid } = this.state;

    return (imprintUse === 'required') ? (saveImprint && isValid) : isValid;
  }

  /* */
  reset() {
    this.setState(initialState);
  }

  /* */
  getExtraInfos() {
    const { saveImprint } = this.state;

    return {
      saveImprint,
      ...this.extraInfos,
    };
  }

  /* */
  getBrand(number) {
    if (number.length < 10) return;

    this.setState({
      brand: null,
      waiting: true,
      isValid: false,
    });

    Paybox.api.getBrand(master, { number })
      .then((res) => {
        const brand = Paybox.functions.getBrandName(res.MARQUE);
        this.setState({
          brand,
          isValid: true,
        });
        this.extraInfos = {
          brand,
          type: res.PRODUIT,
          country: res.PAYS,
        };
        this.props.setErrors({ number: null });
      })
      .catch(() => this.props.setErrors({ number: 'Numéro de carte inconnu' }))
      .finally(() => this.setState({ waiting: false }));
  }

  /* */
  handleDateInputChange = (e) => {
    this.props.handleChange(e);
    this.setState({ dueDate: e.target.value });
  }

  /* */
  handleNumberInputChange = (e) => {
    this.props.handleChange(e);
    this.setState({ number: e.target.value });
  }

  /* */
  handleNumberInputBlur = (e) => {
    this.props.handleBlur(e);
    this.getBrand(e.target.value);
  }

  /* */
  render() {
    const {
      errors,
      values,
      classes,
      imprintUse,
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
      submitButtonText,
      submitButtonHintText,
    } = this.props;

    const {
      brand,
      number,
      dueDate,
      waiting,
      saveImprint,
    } = this.state;

    return (
      <React.Fragment>
        <div className={classes.cardContainer}>
          <CreditCardPreview
            brand={brand}
            number={number}
            dueDate={dueDate} />
        </div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="number"
              variant="outlined"
              value={values.number}
              label="Numéro de carte"
              error={!!(errors.number)}
              onBlur={this.handleNumberInputBlur}
              onChange={this.handleNumberInputChange}
              helperText={
                waiting ? 'Vérification du numéro...' : errors.number
              }
              InputProps={{
                endAdornment:
                  waiting ? (
                    <InputAdornment position="end">
                      <CircularProgress color="inherit" size={16} />
                    </InputAdornment>
                  ) : null,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              required
              fullWidth
              name="dueDate"
              variant="outlined"
              onBlur={handleBlur}
              label="Expire à fin"
              value={values.dueDate}
              error={!!(errors.dueDate)}
              onChange={this.handleDateInputChange}
              InputProps={{
                inputComponent: DueDateMaskedInput,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              required
              fullWidth
              name="cvv"
              label="CVV"
              value={values.cvv}
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              error={!!(errors.cvv)}
              helperText="3 derniers chiffres présents au dos"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          {imprintUse !== 'disabled' && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value="1"
                    name="saveImprint"
                    color="secondary"
                    checked={saveImprint}
                    onChange={(e) => this.setState({ saveImprint: e.target.checked })} />
                }
                label="J'autorise que mes coordonnées bancaires soient sauvegardées sur la plateforme sécurisée de notre banque partenaire."
              />
            </Grid>
          )}
        </Grid>
        <Button
          fullWidth
          size="large"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          className={classes.submitButton}
          disabled={isSubmitting || !this.isValid()}>
          {isSubmitting ? <CircularProgress color="inherit" size={24} /> : submitButtonText}
        </Button>
        {submitButtonHintText && (
          <Typography
            component="p"
            align="center"
            variant="caption"
            className={classes.marginT}>
            {submitButtonHintText}
          </Typography>
        )}
        <div className={classes.securityText}>
          <Typography component="span">
            {'Sécurisé par'}
          </Typography>
          <img alt="CA E-transactions" height={82} src={securityImg} />
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(CompleteCreditCardForm);
