import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/core/styles';
import { Box, Paper, Typography } from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import master from '_utils/master';
import helpers from '_utils/helpers';
import { Screen } from '_components/core';
import * as Settings from '_redux/settings';
import { TransactionsSearchForm } from '_components/forms';

/* */
const styles = (theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
});

/* */
const TYPES = {
  1: 'Achat',
  2: 'Remboursement',
  3: 'Versement',
  4: 'Contestation / litige',
};

/* */
const PRODUCTS = {
  1: 'Green-fee',
  2: 'Location',
  // 3: 'Réduction',
  4: 'Compétition',
  5: 'Événement',
  6: 'Practice',
  7: 'École de golf',
  8: 'Compte client',
  10: 'Cotisation',
};

const formatRow = (row) => {
  const newRow = [...row];
  newRow[0] = moment(row[0]).format('DD/MM/YYYY');
  newRow[2] = TYPES[row[2]];
  newRow[3] = PRODUCTS[row[3]];
  newRow[5] = moment(row[5]).format('DD/MM/YYYY');
  if (row[6] !== null) newRow[6] = i18n.l('number', row[6] / 100);
  if (row[8] !== null) newRow[8] = i18n.l('number', row[8] / 100);
  if (row[7] !== null) newRow[7] = helpers.string.counterToLetters(row[7]);

  return newRow;
};

/* */
const options = {
  selectableRows: 'none',
  // toolbar
  print: false,
  filter: false,
  search: false,
  viewColumns: false,
  // pagination
  rowsPerPage: 25,
  rowsPerPageOptions: [25, 50, 100],
  // translations
  textLabels: i18n.translations.fr.MUIDataTable,
  // download
  downloadOptions: {
    separator: ',',
    filename: 'export.csv',
  },
  onDownload: (buildHead, buildBody, columns, data) => {
    const formattedData = [];
    data.forEach(({ index, data: row }) => {
      formattedData[index] = {
        index,
        data: formatRow(row),
      };
    });
    // console.log(formattedData);
    // return false;
    return buildHead(columns) + buildBody(formattedData);
  },
};

const initialValues = {
  type: '0',
  account: '',
  product: '0',
  dateType: 'date',
  to: moment().format('DD/MM/YYYY'),
  from: moment().subtract(1, 'months').format('DD/MM/YYYY'),
};

/* */
class Transactions extends React.Component {
  /* */
  state = {
    // UI
    error: null,
    success: null,
    loading: false,
    // Data
    transactions: [],
  };

  /* */
  columns = [
    {
      name: 'date',
      label: "Date d'opération",
      options: {
        customBodyRender: (value) => moment(value).format('DD/MM/YYYY'),
      },
    },
    {
      name: 'reference',
      label: 'Référence',
      options: {
        sort: false,
        customBodyRender: (value) => value || null,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        sort: false,
        customBodyRender: (value) => TYPES[value] || null,
      },
    },
    {
      name: 'product',
      label: 'Produit',
      options: {
        sort: false,
        customBodyRender: (value) => PRODUCTS[value] || null,
      },
    },
    {
      name: 'account',
      label: 'Client',
    },
    {
      name: 'serviceDate',
      label: 'Date de prestation',
      options: {
        customBodyRender: (value) => moment(value).format('DD/MM/YYYY'),
      },
    },
    {
      name: 'debit',
      label: 'Débit',
      options: {
        customBodyRender: (value) => value && i18n.l('number', value / 100),
        setCellProps: () => ({ className: this.props.classes.right }),
      },
    },
    {
      name: 'letter',
      label: 'Lettre',
      options: {
        customBodyRender: (value) => value && helpers.string.counterToLetters(value),
        setCellProps: () => ({ className: this.props.classes.right }),
      },
    },
    {
      name: 'credit',
      label: 'Crédit',
      options: {
        customBodyRender: (value) => value && i18n.l('number', value / 100),
        setCellProps: () => ({ className: this.props.classes.right }),
      },
    },
  ];

  /* */
  componentDidMount() {
    this.searchTransactions(initialValues)
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  /* */
  searchTransactions = (values) => {
    const { golf } = this.props;

    this.setState({ loading: true });

    const params = {
      dateType: values.dateType,
    };
    if (values.to) {
      params.to = moment(values.to, 'DD/MM/YYYY')
        .format('YYYY-MM-DD');
    }
    if (values.from) {
      params.from = moment(values.from, 'DD/MM/YYYY')
        .format('YYYY-MM-DD');
    }
    if (values.type !== '0') {
      params.type = Number(values.type);
    }
    if (values.product !== '0') {
      params.product = Number(values.product);
    }
    if (values.account !== '') {
      params.account = values.account;
    }

    return Settings.api.searchTransactions(master, { golfId: golf.id, ...params })
      .then((transactions) => {
        this.setState({ transactions });
      })
      .catch((err) => this.setState({ error: err.message }));
  }

  /* */
  handleSubmit = (values, { setSubmitting }) => {
    this.searchTransactions(values)
      .finally(() => {
        setSubmitting(false);
        this.setState({ loading: false });
      });
  }

  /* */
  render() {
    const {
      error,
      loading,
      success,
      transactions,
    } = this.state;
    const { classes } = this.props;

    return (
      <Screen
        error={error}
        success={success}>
        <Box
          mb={4}
          display="flex"
          alignItems='center'
          flexDirection="column">
          <Typography
            gutterBottom
            variant="h5"
            align="center"
            component="h2">
            {'Transactions PrimaWeb Services'}
          </Typography>
        </Box>
        <Paper className={classes.paper}>
          <Formik
            onSubmit={this.handleSubmit}
            initialValues={initialValues}
            render={(props) => <TransactionsSearchForm
              {...props}
              typeOptions={TYPES}
              productOptions={PRODUCTS} />}
          />
        </Paper>
        {!loading && (
          <MUIDataTable
            columns={this.columns}
            options={options}
            data={transactions} />
        )}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app }) => ({ ...app });

const StyledComponent = withStyles(styles)(Transactions);

export default connect(mapStateToProps)(StyledComponent);
