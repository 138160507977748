import React from 'react';
import _ from 'lodash/fp/lang';
import * as qs from 'query-string';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import config from '_config';
import * as App from '_redux/app';
import master from '_utils/master';
import Router from '_config/router';
import * as Screens from '_screens';

/* */
class Bootstrap extends React.PureComponent {
  /* */
  componentDidMount() {
    const query = this.props.location && qs.parse(this.props.location.search);
    if (query && query.token) {
      this.props.session(master, query.token);
    } else if (config.token) { // dev only
      this.props.session(master, config.token);
    } else {
      this.props.noGolf();
    }
  }

  /* */
  componentDidUpdate(prevProps) {
    const { golf } = this.props;

    if (golf && !_.isEqual(golf, prevProps.golf)) {
      document.title = (golf && golf.name) || 'prima.golf';
    }
  }

  /* */
  renderRoutes() {
    const {
      golf,
      error,
      loading,
    } = this.props;

    if (error) return <Screens.Public.Unauthorized />;
    if (loading) return <Screens.Public.AppLoading />;

    const routerProps = { golf };
    return (<Router {...routerProps} />);
  }

  /* */
  render() {
    const { golf } = this.props;

    // Other loading
    if (golf === null) return <Screens.Public.AppLoading />;

    if (!golf) return <Screens.Public.NotFound />;

    return (
      <BrowserRouter>
        {this.renderRoutes()}
      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({ app }) => ({ ...app });

export default connect(
  mapStateToProps,
  App.actions,
)(Bootstrap);
