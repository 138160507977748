/* */
export const ucfirst = (str) => {
  if (str.length > 0) {
    return str[0].toUpperCase() + str.substring(1);
  }
  return str;
};

/* */
export const parseJSON = (value) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

/* */
export const parseStr = (string) => {
  const obj = {};
  string.split('&').forEach((d) => {
    const [name, value] = d.split('=');
    obj[name] = value;
  });
  return obj;
};

/* */
export const avoidNull = (value) => (value === null ? '' : value);

/* */
export const handleError = (message) => String(message)
  .replace(/GraphQL error:/gi, '')
  .replace(/(Network error:.*)/gi, 'errors.network')
  .replace(/Error:/gi, '')
  .trim();

/* */
export const humanFileSize = (size) => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / Math.pow(1024, i)).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
};

/* */
export const fileInfo = (filename) => {
  const a = `${filename}`.split('.');
  if (a.length === 1 || (a[0] === '' && a.length === 2)) {
    return {
      ext: '',
      name: a,
    };
  }
  const ext = a.pop();
  return {
    ext,
    name: a.join('.'),
  };
};

/* */
export const counterToLetters = (counter) => {
  const min = 65;
  const max = 90;
  const numberOfLetters = 26;

  const d = Math.floor(counter / numberOfLetters);
  const r = counter % numberOfLetters;

  let codes = [];
  if (d > 0) {
    if (r > 0) {
      codes = [...Array(d)].map(() => min);
    } else {
      codes = [...Array(d - 1)].map(() => min);
    }
  }

  if (r === 0) {
    codes.push(max);
  } else {
    codes.push(r + min - 1);
  }

  return String.fromCharCode(...codes);
};
