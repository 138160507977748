import React from 'react';
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import * as Screens from '_screens';
import { getAuthorization } from '_utils/security';

/* */
export default ({ golf }) => {
  const createRoute = (path, Component) => {
    const { isAuthorized, redirect403 } = getAuthorization(path, { golf });

    const ComponentDeny = redirect403
      ? (<Redirect to={redirect403} />)
      : (<Screens.Public.Unauthorized />);

    return (
      <Route
        exact
        path={path}
        render={(props) => (isAuthorized ? <Component {...props} /> : ComponentDeny)} />
    );
  };

  return (
    <Switch>
      <Route exact path="/" component={() => <Redirect to='/game-conditions' />} />
      {/* Base */}
      {createRoute('/accounts', Screens.Settings.Accounts)}
      {createRoute('/covid-certificate', Screens.Settings.CovidCertificate)}
      {createRoute('/transactions', Screens.Finance.Transactions)}
      {createRoute('/game-conditions', Screens.Settings.GameConditions)}

      {/* Notifications */}
      {createRoute('/contact', Screens.Contact.List)}
      {createRoute('/push', Screens.PushList.List)}
      {createRoute('/push/:id', Screens.PushList.View)}
      {createRoute('/campaign', Screens.PushCampaign.List)}
      {createRoute('/campaign/:id', Screens.PushCampaign.View)}
      {createRoute('/stats', Screens.Application.Dashboard)}

      <Route path="/automation" component={Screens.Public.ComingSoon} />

      {/* Documents */}
      {createRoute('/document', Screens.Document.List)}
      {createRoute('/document/:id', Screens.Document.View)}

      {/* Customers UGolf */}
      {createRoute('/customers', Screens.Customer.List)}
      {createRoute('/customers/add', Screens.Customer.Add)}
      {createRoute('/customers/:id', Screens.Customer.Edit)}
      {createRoute('/customers/:id/recurring-payment', Screens.Customer.ConfirmRecurPayment)}

      {/* Stripe UGolf */}
      {createRoute('/stripe/dashboard', Screens.Radar.Dashboard)}
      {createRoute('/stripe/debtor/:accountReference', Screens.Radar.Debtor)}

      {/* Landing pages */}
      <Route exact path="/landing/documents" component={Screens.Landing.Document} />
      <Route exact path="/landing/notifications" component={Screens.Landing.Notification} />

      {/* Booking pages */}
      <Route exact path="/teesheet" component={Screens.TeeSheet.Home} />

      {/* School pages */}
      <Route exact path="/lessons/register" component={Screens.School.Register} />
      <Route exact path="/lessons/listing" component={Screens.School.Students} />

      {/* Subscriptions */}
      <Route exact path="/subscriptions/portfolio" component={Screens.Subscription.Portfolio} />
      <Route exact path="/subscriptions/create" component={Screens.Subscription.CreateOffer} />

      {/* PrimAccess */}
      <Route exact path="/primaccess/autorefill" component={Screens.PrimAccess.AutoRefillList} />

      {/* Corporations */}
      {createRoute('/corporations/list', Screens.Corporation.List)}
      {createRoute('/corporations/report', Screens.Corporation.Report)}
      {createRoute('/corporations/:id', Screens.Corporation.Edit)}

      <Route component={Screens.Public.NotFound} />
    </Switch>
  );
};
