export default {
  gql: {
    GET_REFILL_STATUS: `
        query(
          $golfId: String!
        ) {
          PA_getRefillStatusByGolf(golfId: $golfId) {
            id
            product
            status
            createdAt
            account {
              id
              firstname
              lastname
            }
          }
        }
      `,
    SET_REFILL_STATUS: `
      mutation(
        $accountId: String!
        $golfId: String!
        $status: Boolean!
        $product: String
      ) {
        PA_setRefillStatus(
          accountId: $accountId
          golfId: $golfId
          status: $status
          product: $product
        ) {
          id
          product
          status
          createdAt
          account {
            id
            firstname
            lastname
          }
        }
      }
    `,
  },
};
