import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as qs from 'query-string';

import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/core/styles';
import { Box, Paper, Typography } from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import master from '_utils/master';
import { Screen } from '_components/core';
import { CorpoBookingSearchForm } from '_components/forms';
import * as Corporation from '_redux/corporation';
import CorporateMenu from '_screens/CorporateMenu';

/* */
const styles = (theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
});

const formatRow = (row) => {
  const newRow = [...row];
  newRow[0] = moment(row[0]).format('DD/MM/YYYY HH:mm');
  newRow[5] = (row[5] ? `${i18n.l('number', row[5] / 100)}€` : '0,00€');
  return newRow;
};

/* */
const options = {
  selectableRows: 'none',
  // toolbar
  print: false,
  filter: false,
  search: false,
  viewColumns: false,
  // pagination
  rowsPerPage: 25,
  rowsPerPageOptions: [25, 50, 100],
  // translations
  textLabels: i18n.translations.fr.MUIDataTable,
  // download
  downloadOptions: {
    separator: ',',
    filename: 'Export réservations corporate.csv',
  },
  onDownload: (buildHead, buildBody, columns, data) => {
    const formattedData = [];
    data.forEach(({ index, data: row }) => {
      formattedData[index] = {
        index,
        data: formatRow(row),
      };
    });
    return buildHead(columns) + buildBody(formattedData);
  },
};

let initialValues = {
  player: { firstname: 'Tous', lastname: '', id: '0' },
  subscription: '',
  to: moment().format('DD/MM/YYYY'),
  from: moment().subtract(1, 'months').format('DD/MM/YYYY'),
};

class Report extends React.Component {
  /* */
  state = {
    // UI
    error: null,
    success: null,
    bookingsLoading: false,
    openPlayers: false,
    subscriptionOptions: [],
    playersOptions: [],
    playersLoading: false,
    // Data
    bookings: [],
  };

  debounceFilteredPlayers = _.debounce((params) => this.loadFilteredPlayers(params), 1000);

  /* */
  columns = [
    {
      name: 'bookingDate',
      label: 'Date de réservation',
      options: {
        customBodyRender: (value) => moment(value).format('DD/MM/YYYY HH:mm'),
      },
    },
    {
      name: 'playerLastname',
      label: 'Nom',
    },
    {
      name: 'playerFirstname',
      label: 'Prénom',
    },
    {
      name: 'playerEmail',
      label: 'Email',
    },
    {
      name: 'subscriptionName',
      label: 'Contrat',
    },
    {
      name: 'price',
      label: 'Prix de la réservation',
      options: {
        customBodyRender: (value) => (value ? `${i18n.l('number', value / 100)}€` : '0,00€'),
      },
    },
  ];

  /* */
  componentDidMount() {
    const { location } = this.props;
    const queryParams = qs.parse(location.search);
    const { subscriptionId = null } = queryParams;
    initialValues = {
      ...initialValues,
      subscription: subscriptionId || '',
    };
    this.searchCorpoBookings(initialValues)
      .finally(() => {
        this.setState({ bookingsLoading: false });
      });
    this.loadCorporateSubscriptions();
  }

  /* */
  setOpenPlayers = (open) => {
    this.setState({ openPlayers: open });
  };

  /* */
  loadCorporateSubscriptions = () => {
    const { golf } = this.props;
    return Corporation.api.getSubscriptions(master, { golfId: golf.id })
      .then((subscriptions) => {
        this.setState({
          subscriptionOptions: subscriptions.map((item) => ({
            id: item.id,
            name: `${item.name} (${moment(item.startsAt).format('DD/MM/YYYY')} - ${moment(item.expiresAt).format('DD/MM/YYYY')})`,
          })),
        });
      })
      .catch((err) => this.setState({ error: err.message }));
  }

  /* */
  loadFilteredPlayers = ({ player, values }) => {
    this.setState({ playersLoading: true }, () => {
      const { golf } = this.props;
      const params = {
        golfId: golf.id,
      };
      if (values.subscription !== '0') {
        params.subscriptionId = values.subscription;
      }
      if (player) {
        params.player = player;
      }
      return Corporation.api.searchPlayers(master, { ...params })
        .then((playersOptions) => {
          playersOptions.splice(0, 0, { firstname: 'Tous', lastname: '', id: '0' });
          this.setState({
            playersLoading: false,
            playersOptions,
          });
        })
        .catch((err) => this.setState({ error: err.message }));
    });
  }

  /* */
  searchCorpoBookings = (values) => {
    const { golf } = this.props;

    this.setState({ bookingsLoading: true });

    const params = {
      dateType: values.dateType,
    };
    if (values.to) {
      params.to = moment(values.to, 'DD/MM/YYYY')
        .format('YYYY-MM-DD');
    }
    if (values.from) {
      params.from = moment(values.from, 'DD/MM/YYYY')
        .format('YYYY-MM-DD');
    }
    if (values.player && values.player.id !== '0') {
      params.playerId = values.player.id;
    }
    if (values.subscription !== '0') {
      params.subscriptionId = values.subscription;
    }

    return Corporation.api.searchCorpoBookings(master, { golfId: golf.id, ...params })
      .then((bookings) => {
        this.setState({ bookings });
      })
      .catch((err) => this.setState({ error: err.message }));
  }

  /* */
  handleSubmit = (values, { setSubmitting }) => {
    this.searchCorpoBookings(values)
      .finally(() => {
        setSubmitting(false);
        this.setState({ bookingsLoading: false });
      });
  }

  /* */
  render() {
    const {
      error,
      bookingsLoading,
      playersLoading,
      playersOptions,
      openPlayers,
      success,
      bookings,
      subscriptionOptions,
    } = this.state;
    const { classes } = this.props;

    return (
      <Screen
        error={error}
        success={success}
        title='Reporting'
        layout="fullWidth"
        menu={<CorporateMenu />}
      >
        <Box
          mb={4}
          display="flex"
          alignItems='center'
          flexDirection="column">
          <Typography
            gutterBottom
            variant="h5"
            align="center"
            component="h2">
            {'Suivi des réservations corpo'}
          </Typography>
        </Box>
        <Paper className={classes.paper}>
          <Formik
            onSubmit={this.handleSubmit}
            initialValues={initialValues}
            enableReinitialize
            render={(props) =>
              <CorpoBookingSearchForm
                {...props}
                openPlayers={openPlayers}
                setOpenPlayers={this.setOpenPlayers}
                subscriptionOptions={subscriptionOptions}
                playersOptions={playersOptions}
                playersLoading={playersLoading}
                loadFilteredPlayers={this.debounceFilteredPlayers}
              />
            } />
        </Paper>
        {!bookingsLoading && (
          <MUIDataTable
            columns={this.columns}
            options={options}
            data={bookings} />
        )}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app }) => ({ ...app });

const StyledComponent = withStyles(styles)(Report);

export default connect(mapStateToProps)(StyledComponent);
