export default {
  gql: {
    GET_METRICS: `
        query(
          $propertyId: Int!
          $startDate: String!
          $endDate: String
        ) {
          getMetrics(
            propertyId: $propertyId
            startDate: $startDate
            endDate: $endDate
          ) {
            activeUsers {
              Android {
                countries {
                  countryId
                  country
                  value
                }
                total
              }
              iOS {
                countries {
                  countryId
                  country
                  value
                }
                total
              }
              Web {
                countries {
                  countryId
                  country
                  value
                }
                total
              }
              total
            }
            newUsers {
              Android {
                countries {
                  countryId
                  country
                  value
                }
                total
              }
              iOS {
                countries {
                  countryId
                  country
                  value
                }
                total
              }
              Web {
                countries {
                  countryId
                  country
                  value
                }
                total
              }
              total
            }
          }
        }
      `,
  },
};
