import i18n from 'i18n-js';
import fr from './fr';

i18n.locale = 'fr';
i18n.fallbacks = true;
i18n.defaultLocale = 'fr';
i18n.translations = { fr };

i18n.t = (str, opts) => i18n.translate(str, { defaultValue: str, ...opts });

export default i18n;
