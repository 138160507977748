import React from 'react';
import { connect } from 'react-redux';
import fileDownload from 'js-file-download';
import { makeStyles } from '@material-ui/core/styles';
import {
  Fab,
  List,
  Paper,
  Avatar,
  Dialog,
  Button,
  Tooltip,
  ListItem,
  TextField,
  IconButton,
  Typography,
  DialogTitle,
  ListItemText,
  DialogActions,
  DialogContent,
  InputAdornment,
  ListItemAvatar,
  CircularProgress,
  ListItemSecondaryAction,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import PhotoIcon from '@material-ui/icons/Photo';
import AudioIcon from '@material-ui/icons/MusicNote';
import DownloadIcon from '@material-ui/icons/GetApp';
import VideoIcon from '@material-ui/icons/MovieFilter';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import DescriptionIcon from '@material-ui/icons/Description';

import i18n from '_utils/i18n';
import master from '_utils/api';
import * as Client from '_redux/client';
import apolloClient from '_utils/master';
import { Screen } from '_components/core';
import * as Document from '_redux/document';

import Upload from './Upload';

/* */
const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(2),
  },
  center: {
    padding: theme.spacing(),
  },
  dialog: {
    backgroundColor: theme.palette.background.default,
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(),
    right: theme.spacing(),
    color: theme.palette.grey[500],
  },
}));

const FileIcon = ({ type, tooltip }) => {
  if (type.match(/image\/*/i)) return <PhotoIcon />;
  if (type.match(/video\/*/i)) return <VideoIcon />;
  if (type.match(/audio\/*/i)) return <AudioIcon />;
  return (
    <Tooltip title={tooltip}>
      <DescriptionIcon />
    </Tooltip>
  );
};

const mapStateToProps = ({ app: { golf } }) => ({ golf });

export default connect(mapStateToProps)(({
  golf,
  match,
  history,
}) => {
  const classes = useStyles();

  const { id = null } = match.params;

  /* */
  const [open, setOpen] = React.useState(false);
  const [account, setAccount] = React.useState(null);
  const [confirm, setConfirm] = React.useState(null);
  const [filename, setFilename] = React.useState(null);
  const [documents, setDocuments] = React.useState([]);
  const [updating, setUpdating] = React.useState(null);
  const [deleting, setDeleting] = React.useState(null);
  const [downloading, setDownloading] = React.useState(null);
  // Screen
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  /* */
  React.useEffect(() => {
    setLoading(true);
    Client.api.getAccount(apolloClient, { id })
      .then((data) => {
        if (!data) throw new Error('Client non trouvé.');

        setAccount(data);

        return Document.api.getDocuments(apolloClient, { ownerId: data.id });
      })
      .then(setDocuments)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [golf.id, id]);

  const handleClose = () => {
    setOpen(false);
  };
  const closeConfirm = () => {
    setConfirm(null);
  };
  const closeFilename = () => {
    setFilename(null);
  };

  const handleUpload = (files) => {
    if (files?.length) {
      return Promise.all(files.map((file) => Document.api.addDocument(apolloClient, {
        file,
        readerId: account.id,
        // ownerId: golf.id,
      })))
        .then((docs) => setDocuments([
          ...(docs || []),
          ...(documents || []),
        ]))
        .then(() => setSuccess('Fichier enregistré'))
        .catch(setError)
        .finally(() => setOpen(false));
    }
    return null;
  };

  const handleUpdate = () => {
    if (filename && filename.name && filename.name.length) {
      setUpdating(filename.id);
      return Document.api.updateDocument(apolloClient, {
        id: filename.id,
        filename: `${filename.name}.${filename.ext}`,
      })
        .then((doc) => setDocuments(documents.map((d) => ((d.id === doc.id) ? doc : d))))
        .then(() => setSuccess('Fichier modifié'))
        .catch(setError)
        .finally(() => {
          setFilename(null);
          setUpdating(null);
        });
    }
    return null;
  };

  const handleDelete = () => {
    if (confirm) {
      setDeleting(confirm.id);
      return Document.api.deleteDocument(apolloClient, {
        id: confirm.id,
      })
        .then(() => setDocuments(documents.filter((d) => d.id !== confirm.id)))
        .then(() => setSuccess('Fichier supprimé'))
        .catch(setError)
        .finally(() => {
          setConfirm(null);
          setDeleting(null);
        });
    }
    return null;
  };

  const download = (d) => {
    setDownloading(d.id);
    master.blob(`document/${d.id}`)
      .then((data) => fileDownload(data, d.filename))
      .catch(setError)
      .finally(() => setDownloading(null));
  };

  const renderDocuments = () => {
    if (!documents?.length) {
      return (
        <Typography variant="body2" align="center" className={classes.center}>
          {account ? 'Aucun fichier' : "Le client n'a pas de compte web." }
        </Typography>
      );
    }
    return (
      <List dense>
        {(documents || []).map((d) => (
          <ListItem key={d.id}>
            <ListItemAvatar>
              <Avatar>
                <FileIcon type={d.mimetype} tooltip={i18n.l('date.formats.default', d.createdAt)} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              {d.filename}
            </ListItemText>
            <ListItemSecondaryAction>
              <IconButton aria-label="Download" onClick={() => download(d)}>
                {(downloading === d.id)
                  ? <CircularProgress color="secondary" size={24} />
                  : <DownloadIcon />
                }
              </IconButton>
              {(d.ownerId === golf.id) && (
                <IconButton aria-label="Change name" onClick={() => setFilename(d)}>
                  {(updating === d.id)
                    ? <CircularProgress color="secondary" size={24} />
                    : <EditIcon />
                  }
                </IconButton>
              )}
              {(d.ownerId === golf.id) && (
                <IconButton aria-label="Delete" onClick={() => setConfirm(d)}>
                  {(deleting === d.id)
                    ? <CircularProgress color="secondary" size={24} />
                    : <DeleteIcon />
                  }
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Screen
      error={error}
      layout='fluid'
      loading={loading}
      success={success}
      title={account ? `${account.lastname} ${account.firstname}` : 'Documents'}
      onBackPress={() => history.goBack()}>
      <Paper>
        <Typography variant="h6" className={classes.title}>
          {'Liste des fichiers'}
        </Typography>
        {renderDocuments()}
      </Paper>
      <br />
      <br />
      {account && (
        <Fab
          color="secondary"
          aria-label="Ajouter un document"
          onClick={() => setOpen(true)}>
          <AddIcon />
        </Fab>
      )}
      <br />
      <br />

      <Upload
        open={open}
        handleError={setError}
        handleClose={handleClose}
        handleUpload={handleUpload}
      />

      <Dialog
        fullWidth
        open={Boolean(confirm)}
        onClose={closeConfirm}
        classes={{ paperFullWidth: classes.dialog }}
      >
        <DialogTitle>
          {'Confirmation de suppression'}
          <IconButton
            color="inherit"
            aria-label="Fermer"
            onClick={closeConfirm}
            className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {`Supprimer le document '${confirm?.filename}' ?`}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={closeConfirm}>
            {'Annuler'}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleDelete}
            disabled={Boolean(deleting)}
            >
            {deleting
              ? <CircularProgress color="secondary" size={24} />
              : 'Supprimer'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        open={Boolean(filename)}
        onClose={closeFilename}
        classes={{ paperFullWidth: classes.dialog }}
      >
        <DialogTitle>
          {'Renomer le fichier'}
          <IconButton
            color="inherit"
            aria-label="Fermer"
            onClick={closeFilename}
            className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            required
            fullWidth
            name='name'
            value={filename?.name || ''}
            onChange={({ target }) => setFilename({
              ...filename,
              name: target.value,
            })}
            InputProps={{
              endAdornment: <InputAdornment position="end">.{filename?.ext}</InputAdornment>,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={closeFilename}>
            {'Annuler'}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleUpdate}
            disabled={Boolean(updating)}
            >
            {updating
              ? <CircularProgress color="secondary" size={24} />
              : 'Enregistrer'}
          </Button>
        </DialogActions>
      </Dialog>
    </Screen>
  );
});
