import helpers from '_utils/helpers';

// ----------------------------------------------------------------------------
// PROMISES
// ----------------------------------------------------------------------------
/* */
export function parseItem(item) {
  if (!item) return null;
  const info = helpers.string.fileInfo(item.filename);
  return {
    ...item,
    ...info,
  };
}
