import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-query';
import {
  Box,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';

import i18n from '_utils/i18n';
import Menu from '_screens/Menu';
import master from '_utils/master';
import * as Push from '_redux/push';
import { Screen } from '_components/core';
import moment from 'moment';

/* */
const useStyles = makeStyles((theme) => ({
  textField: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const mapStateToProps = ({ app: { golf } }) => ({ golf });

export default connect(mapStateToProps)(({ golf, history }) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const { isLoading, isError, data } = useQuery(
    ['campaigns', { golfId: golf.id }],
    () =>
      Push.api.getPushCampaigns(master, { golfId: golf.id }).then((res) =>
        res.map(({
          accounts, sentAt, createdAt, updatedAt, scheduledAt, ...rest
        }) => ({
          ...rest,
          sentAt: sentAt && new Date(sentAt),
          createdAt: createdAt && new Date(createdAt),
          updatedAt: updatedAt && new Date(updatedAt),
          accounts: accounts && accounts.map((account) => account.id),
          sentAtTime: scheduledAt ? i18n.l('time.formats.short_y', new Date(scheduledAt)) :
            sentAt && i18n.l('time.formats.short_y', new Date(sentAt)),
          createdAtTime:
            createdAt && i18n.l('time.formats.short_y', new Date(createdAt)),
          updatedAtTime:
            updatedAt && i18n.l('time.formats.short_y', new Date(updatedAt)),
        }))),
  );
  /* */
  const [openDialog, setOpenDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // New campaign
  const [name, setName] = useState('');
  const [date, setDate] = useState('');

  function handleOpenDialog() {
    setName('');
    setDate(moment().format('YYYY-MM-DD HH:mm'));
    setOpenDialog(true);
  }

  function handleCreateCampaign() {
    setIsSubmitting(true);
    Push.api
      .setPushCampaign(master, {
        name,
        body: '',
        title: '',
        scheduledAt: moment(date).format('YYYY-MM-DD HH:mm'),
        data: JSON.stringify({ link: '' }),
        golfId: golf.id,
      })
      .then(({ id }) => history.push(`/campaign/${id}`))
      .catch((e) => setError(e))
      .finally(() => setIsSubmitting(false));
  }
  /* */
  return (
    <Screen
      error={isError || error}
      layout="fluid"
      title="Campagnes"
      loading={isLoading}
      menu={<Menu />}
    >
      <Box mb={2}>
        <Button color="primary" variant="contained" onClick={handleOpenDialog}>
          Créer une campagne
        </Button>
      </Box>
      <MaterialTable
        data={data}
        title="Campagnes"
        options={{
          paging: true,
          pageSize: 10,
          toolbar: false,
          filtering: true,
          selection: false,
          showTitle: false,
          showHeader: false,
          toolbarButtonAlignment: 'right',
          pageSizeOptions: [10, 50, 100, 150, 200],
          maxBodyHeight: '80vh',
        }}
        columns={[
          { field: 'id', hidden: true },
          { title: 'Nom', field: 'name' },
          {
            title: 'Date de création',
            field: 'createdAtTime',
            defaultSort: 'desc',
            customSort: (a, b) => a.createdAt - b.createdAt,
          },
          {
            title: 'Date de modification',
            field: 'updatedAtTime',
            customSort: (a, b) => a.updatedAt - b.updatedAt,
          },
          {
            title: "Date d'envoi",
            field: 'sentAtTime',
            customSort: (a, b) => a.sentAt - b.sentAt,
          },
        ]}
        onRowClick={(event, item) => history.push(`/campaign/${item.id}`)}
      />

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{'Créer une nouvelle campagne'}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            required
            name="name"
            value={name}
            margin="normal"
            variant="outlined"
            label="Nom de la campagne"
            className={classes.textField}
            onChange={(e) => {
              setName(e.currentTarget.value);
            }}
            helperText="Choisissez un nom pour votre campagne qui vous permettra de la retrouver facilement. ex: 'Promotion de rentrée'"
          />
          <TextField
            fullWidth
            name="date"
            margin="normal"
            variant="outlined"
            type="datetime-local"
            value={date || moment().format('YYYY-MM-DD HH:mm')}
            onChange={(e) => {
              setDate(e.currentTarget.value);
            }}
            className={classes.textField}
            label="Programmé ?"
            InputLabelProps={{
              shrink: true,
            }}
            helperText="Date et heure de programmation (ne pas modifier pour un envois immédiat)."
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            color="primary"
            style={{ float: 'left' }}
          >
            {'Fermer'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            onClick={handleCreateCampaign}
          >
            {'Enregistrer'}
          </Button>
        </DialogActions>
      </Dialog>
    </Screen>
  );
});
