/* eslint-disable no-alert */
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {
  Box,
  Link,
  Paper,
  Table,
  Button,
  Select,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
  CircularProgress,
} from '@material-ui/core';

import i18n from '_utils/i18n';
import master from '_utils/master';
import * as Radar from '_redux/radar';
import { Screen } from '_components/core';
import { PaymentStateChip } from '_components/elements';

// const initialState = {
//   payer: null,
//   charges: [],
//   debtor: null,
//   otherDebtors: [],
//   installments: [],
// };
// const reducer = (state, ({ charges, installments })) => {
//   return ({})
// };

/* */
const useStyles = makeStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
  },
  mr8: { marginRight: 8 },
  error: { color: '#e53935' },
  success: { color: '#43A047' },
}));

/* */
const DebtorStripe = ({ golf, match }) => {
  const classes = useStyles();
  const payerInputRef = React.createRef();
  const installmentInputRef = React.createRef();

  /* */
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [processing, setProcessing] = React.useState(false);

  const [charges, setCharges] = React.useState([]);
  const [installments, setInstallments] = React.useState([]);

  // Installment suggestions for matching with charge
  const [dues, setDues] = React.useState([]);
  const [manualMatching, setManualMatching] = React.useState({
    charge: null,
    installments: [],
  });

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [payer, setPayer] = React.useState(null);
  const [debtor, setDebtor] = React.useState(null);
  const [otherDebtors, setOtherDebtors] = React.useState([]);
  const [potentialPayers, setPotentialPayers] = React.useState([]);

  const { accountReference = null } = match.params;

  /* */
  const load = () => {
    setLoading(true);

    const params = {
      golfId: golf.id,
      accountReference,
    };

    // on récupère toutes les échéances qu'il doit payer
    // les siennes + celles dont il est le payeur
    setTimeout(() => Promise.all([
      Radar.api.listStripeCharges(master, params),
      Radar.api.listStripeInstallments(master, params),
    ])
      .then(([allCharges, allInstallments]) => {
        // filtre sur ses échéances
        const hisInstallments = allInstallments
          .filter((i) => i.accountReference === accountReference);

        // retrouver le client (par ses échéances ou ses paiements)
        const { customer: me } = hisInstallments.find((i) => i.customer)
          || allCharges.find((i) => i.accountReference === accountReference)
          || { customer: null };

        if (!me) {
          throw new Error('errors.user.not_found');
        }

        // filtre les échéances dûes au moment t (due ou failed)
        const unpaid = hisInstallments.filter((i) => i.state === 'failed' || i.state === 'unpaid');
        // setMatchable(Boolean(hisDues.length));

        // retrouve les clients dont il est le payeur
        const others = Object.values(allInstallments
          .reduce((acc, item) => {
            if (item.customer
                && item.customer.id !== me.id
                && typeof acc[item.customer.id] === 'undefined') {
              acc[item.customer.id] = {
                ...item.customer,
                reference: item.accountReference,
              };
            }
            return acc;
          }, {}));

        // retrouve le payeur dans les paiements
        const foundPayer = allCharges.find((c) => c.customer.id !== me.id);
        const myPayer = foundPayer && {
          ...foundPayer.customer,
          reference: foundPayer.accountReference,
        };

        setDebtor(me);
        setDues(unpaid);
        setPayer(myPayer);
        setCharges(allCharges);
        setOtherDebtors(others);
        setInstallments(hisInstallments);
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false)), 1000);
  };

  /* */
  const suggestMatching = (charge) => () => {
    const found = dues.filter((i) => Math.abs(charge.amount - i.amount) < 2);
    setManualMatching({ charge, installments: found });
    setDialogOpen(true);
  };

  /* */
  const matchCharge = () => {
    const { charge } = manualMatching;

    if (window.confirm('Êtes-vous sûr ?')) {
      setProcessing(true);
      Radar.api.matchChargeWithInstallment(master, {
        golfId: golf.id,
        chargeId: charge.id,
        installmentId: installmentInputRef.current.value,
      })
        .then(() => {
          setSuccess('Rapprochement manuel réussi avec succès.');
          load();
        })
        .finally(() => {
          setDialogOpen(false);
          setProcessing(false);
        });
    }
  };

  /* */
  const suggestPayers = () => {
    if (potentialPayers.length > 0) {
      Promise.resolve(potentialPayers)
        .then(() => { setDialogOpen(true); });
    } else {
      Radar.api.listPotentialPayers(master, { golfId: golf.id })
        .then((data) => {
          setPotentialPayers(data);
          setDialogOpen(true);
        });
    }
  };

  /* */
  const matchDebtorWithPayer = () => {
    if (window.confirm('Êtes-vous sûr ?')) {
      setProcessing(true);
      Radar.api.matchDebtorWithPayer(master, {
        golfId: golf.id,
        debtorReference: accountReference,
        payerReference: payerInputRef.current.value,
      })
        .then(() => {
          setSuccess('Payeur associé avec succès.');
          load();
        })
        .finally(() => {
          setDialogOpen(false);
          setProcessing(false);
        });
    }
  };

  /* */
  const showPotentialPayers = () => {
    const suggestions = potentialPayers.filter((p) => p.lastname.includes(debtor.lastname));

    return (
      <Dialog
        open={dialogOpen}
        disableBackdropClick
        disableEscapeKeyDown>
        <DialogTitle>{'Associer un payeur'}</DialogTitle>
        <DialogContent>
          {potentialPayers.length > 0 && (
            <Select
              native
              inputRef={payerInputRef}
              inputProps={{ name: 'payerReference' }}>
              {suggestions.length > 0 && (
                <optgroup label="Suggestions">
                  {suggestions.map((p, k) => (
                    <option key={k} value={p.lightspeed.reference}>
                      {`${p.firstname} ${p.lastname} (${p.lightspeed.reference})`}
                    </option>
                  ))}
                </optgroup>
              )}
              {potentialPayers.map((p, k) => (
                <option key={k} value={p.lightspeed.reference}>
                  {`${p.firstname} ${p.lastname} (${p.lightspeed.reference})`}
                </option>
              ))}
            </Select>
          )}
          {potentialPayers.length === 0 && 'Aucun payeur possible.'}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={processing}
            onClick={() => setDialogOpen(false)}>
            {'Annuler'}
          </Button>
          {potentialPayers.length > 0 && (
            <Button
              color="primary"
              disabled={processing}
              variant="contained"
              onClick={matchDebtorWithPayer}>
              {processing
                ? <CircularProgress color="inherit" size={24} />
                : 'Valider'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  /* */
  const showSuggestions = () => {
    const {
      charge: unmatched,
      installments: suggestions,
    } = manualMatching;

    if (dues.length === 0 || !unmatched) return null;

    return (
      <Dialog
        open={dialogOpen}
        disableBackdropClick
        disableEscapeKeyDown>
        <DialogTitle>{'Rappochement manuel'}</DialogTitle>
        <DialogContent>
          {suggestions.length > 0 && (
            <React.Fragment>
              <Typography color="primary" display="block">
                {'Le paiement'}
              </Typography>
              <Typography>
                {`${moment(unmatched.createdAt).format('DD/MM/YY')}`}
                {` - ${unmatched.description} - `}
                <b>{`${i18n.l('currency', unmatched.amount / 100)}`}</b>
              </Typography>
              <br />
              <Typography color="primary" display="block">
                {"sera associé à l'échéance"}
              </Typography>
              <Select
                native
                inputRef={installmentInputRef}
                inputProps={{ name: 'installmentId' }}>
                {suggestions.map((s, k) => (
                  <option key={k} value={s.id}>
                    {`${moment(s.dueDate, 'x').format('DD/MM/YY')}`}
                    {` - ${s.name}`}
                    {` - ${i18n.l('currency', s.amount / 100)}`}
                  </option>
                ))}
              </Select>
            </React.Fragment>
          )}
          {suggestions.length === 0 && 'Aucun rapprochement possible.'}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={processing}
            onClick={() => setDialogOpen(false)}>
            {'Annuler'}
          </Button>
          {suggestions.length > 0 && (
            <Button
              color="primary"
              disabled={processing}
              variant="contained"
              onClick={matchCharge}>
              {processing
                ? <CircularProgress color="inherit" size={24} />
                : 'Valider'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  React.useEffect(load, []);

  /* */
  return (
    <Screen
      error={error}
      loading={loading}
      success={success}>
      <Box
        display="flex"
        alignItems="center">
        <AccountCircleIcon className={classes.mr8} />
        <Typography
          variant="h5"
          component="h2">
          {debtor && `${debtor.firstname} ${debtor.lastname} (${accountReference})`}
        </Typography>
      </Box>
      {payer && (
        <Typography variant="body2">
          {'A pour payeur '}
          <Link href={`/stripe/debtor/${payer.reference}`}>
            {`${payer.firstname} ${payer.lastname} (${payer.reference})`}
          </Link>
        </Typography>
      )}
      {otherDebtors.length > 0 && (
        <Box display="flex">
          <Typography variant="body2">{'Payeur pour'}</Typography>
          <Box px={0.5}>
            {otherDebtors.map((d, k) => (
              <Link key={k} href={`/stripe/debtor/${d.reference}`} variant="body2">
                {`${d.firstname} ${d.lastname} (${d.reference})`}
              </Link>
            ))}
          </Box>
        </Box>
      )}
      <br />
      <Typography
        gutterBottom
        variant="h6"
        component="h3">
        {'Échéances'}
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.head}>{'Date'}</TableCell>
              <TableCell className={classes.head}>{'Prestation'}</TableCell>
              {/*
              <TableCell className={classes.head}>{'Période'}</TableCell>
              */}
              <TableCell className={classes.head} align="right">{'Total'}</TableCell>
              <TableCell className={classes.head}>{'Statut'}</TableCell>
              <TableCell className={classes.head} align="right">{'Montant'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {installments
              .map((data, i) => (
                <TableRow key={i}>
                  <TableCell>
                    {moment(data.dueDate, 'x').format('DD/MM/YY')}
                  </TableCell>
                  <TableCell>
                    {data.name}
                  </TableCell>
                  {/*
                  <TableCell>
                    {`du ${moment(data.startsAt, 'x').format('DD/MM')}`}
                    {` au ${moment(data.endsAt, 'x').format('DD/MM/YY')}`}
                  </TableCell>
                  */}
                  <TableCell align="right">
                    {i18n.l('currency', data.totalAmount / 100)}
                  </TableCell>
                  <TableCell>
                    <PaymentStateChip type="installment" state={data.state} />
                  </TableCell>
                  <TableCell align="right" style={{ fontWeight: 600 }}>
                    {i18n.l('currency', data.amount / 100)}
                  </TableCell>
                </TableRow>
              ))}
              {installments.length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan="5"
                    size="medium"
                    align="center">
                    <WarningIcon color="disabled" style={{ color: '#737b83', fontSize: 64 }} />
                    <Typography style={{ color: '#737b83' }} variant="h6">
                      {'Aucune échéance trouvée.'}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <br />
      <Typography
        gutterBottom
        variant="h6"
        component="h3">
        {'Paiements'}
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.head} padding="checkbox" />
              <TableCell className={classes.head}>{'Date'}</TableCell>
              <TableCell className={classes.head}>{'Prestation'}</TableCell>
              <TableCell className={classes.head}></TableCell>
              <TableCell className={classes.head}></TableCell>
              <TableCell className={classes.head}>{'Statut'}</TableCell>
              <TableCell className={classes.head} align="right">{'Montant'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {charges
              .map((data, i) => {
                const outcome = JSON.parse(data.outcome) || null;

                return (
                  <TableRow key={i}>
                    <TableCell padding="checkbox">
                      {data.installmentId && data.status === 'succeeded' && (
                        <DoneIcon fontSize="small" className={classes.success} />
                      )}
                      {/* data.installmentId && data.status === 'failed' && (
                        <DoneIcon fontSize="small" className={classes.error} />
                      ) */}
                      {!data.installmentId
                        && !data.matchedAt
                        && data.status === 'succeeded'
                        && (
                          <WarningIcon fontSize="small" className={classes.error} />
                        )}
                    </TableCell>
                    <TableCell>
                      {moment(data.createdAt).format('DD/MM/YY')}
                    </TableCell>
                    <TableCell>
                      {data.description || '-'}<br />
                      {data.status !== 'succeeded' && outcome && (
                        <Typography variant="caption" className={classes.error}>
                          {outcome.network_status} / {outcome.reason}
                        </Typography>
                      )}
                      {!data.installmentId
                        && !data.matchedAt
                        && data.status === 'succeeded'
                        && dues.length > 0
                        && (
                          <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={suggestMatching(data)}>
                            {'Rapprocher'}
                          </Button>
                        )}
                    </TableCell>
                    <TableCell>
                      {data.cardLast4.padStart(16, '*')}
                    </TableCell>
                    <TableCell>
                      {moment(data.cardExpiration, 'MMYY').format('MM/YY')}
                    </TableCell>
                    <TableCell>
                      <PaymentStateChip type="charge" state={data.status} />
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: 600 }}>
                      {i18n.l('currency', data.amount / 100)}
                    </TableCell>
                  </TableRow>
                );
              })}
              {charges.length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan="7"
                    size="medium"
                    align="center">
                    <WarningIcon
                      color="disabled"
                      style={{ color: '#737b83', fontSize: 64 }} />
                    <Typography
                      gutterBottom
                      style={{ color: '#737b83' }}
                      variant="h6">
                      {'Aucun paiement trouvé.'}
                    </Typography>
                    {installments.length > 0 && (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={suggestPayers}>
                        {'Associer un payeur'}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
      {charges.length > 0 && showSuggestions()}
      {charges.length === 0 && showPotentialPayers()}
    </Screen>
  );
};

const mapStateToProps = ({ app }) => ({ ...app });

export default connect(mapStateToProps)(DebtorStripe);
