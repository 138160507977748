import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import React, { useState } from 'react';

import {
  // Box,
  Button,
  Dialog,
  DialogActions,
} from '@material-ui/core';

import i18n from '_utils/i18n';
import Menu from '_screens/Menu';
import master from '_utils/master';
import * as Push from '_redux/push';
import { Screen } from '_components/core';

import {
  useQuery,
} from 'react-query';

const mapStateToProps = ({ app: { golf } }) => ({ golf });

export default connect(mapStateToProps)(({ golf, history }) => {
  const [openDialog, setOpenDialog] = useState(false);
  // const [itemsDelete, setItemsDelete] = useState([]);
  const lists = useQuery(['lists', { golfId: golf.id }], () => Push.api.getPushLists(master, { golfId: golf.id }).then((allLists) => allLists.map(({
    createdAt,
    updatedAt,
    ...rest
  }) => ({
    ...rest,
    createdAt: createdAt && new Date(createdAt),
    updatedAt: updatedAt && new Date(updatedAt),
    createdAtTime: createdAt && i18n.l('time.formats.short_y', new Date(createdAt)),
    updatedAtTime: updatedAt && i18n.l('time.formats.short_y', new Date(updatedAt)),
  }))), {
    initialData: [],
  });
  return (
    <Screen
      error={lists.error}
      layout='fluid'
      loading={lists.isLoading && !lists.isFetched}
      title='Listes de diffusion'
      menu={<Menu />}
      >
        <MaterialTable
          title="Listes de diffusion"
          data={lists.data.length > 0 ? lists.data : []}
          options={{
            paging: true,
            pageSize: 10,
            toolbar: false,
            filtering: true,
            selection: false,
            showTitle: false,
            showHeader: false,
            toolbarButtonAlignment: 'right',
            pageSizeOptions: [10, 50, 100, 150, 200],
            maxBodyHeight: '80vh',
          }}
          columns={[
            { field: 'id', hidden: true },
            { title: 'Nom', field: 'name' },
            {
              title: 'Date de création',
              field: 'createdAtTime',
              defaultSort: 'desc',
              customSort: (a, b) => a.createdAt - b.createdAt,
            },
            {
              title: 'Date de modification',
              field: 'updatedAtTime',
              customSort: (a, b) => a.updatedAt - b.updatedAt,
            },
            { title: 'Nb de destinataires', field: 'accounts', render: (item) => ((item.accounts && item.accounts.length) || 0) },
          ]}
          onRowClick={(event, item) => history.push(`/push/${item.id}`)} /><Dialog
            fullScreen
            open={openDialog}
            onClose={() => setOpenDialog(false)}
          >
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)} color="primary" style={{ float: 'left' }}>
                {'Fermer'}
              </Button>
            </DialogActions>
          </Dialog>
    </Screen>
  );
});
