import gql from 'graphql-tag';
import master from '_utils/api';

import actionTypes from './constants';

// -----------------------------------------------------------------------------
// Misc
// -----------------------------------------------------------------------------
export const sendEmail = (variables) => (
  master.post('api/job', { job: variables, queue: 'mail' })
);

// -----------------------------------------------------------------------------
// GameConditions
// -----------------------------------------------------------------------------
export const getGameConditions = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`${actionTypes.gql.GET_GAME_CONDITIONS}`,
    variables,
    fetchPolicy,
  })
    .then(({ data }) => data.getGolfGameConditions));

/* */
export const setGameConditions = (client, variables) => (
  client.mutate({
    mutation: gql`${actionTypes.gql.SET_GAME_CONDITIONS}`,
    variables,
  })
    .then(({ data }) => data.success));

// -----------------------------------------------------------------------------
// AccountMatchs
// -----------------------------------------------------------------------------
export const getAccounts = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`${actionTypes.gql.GET_ACCOUNTS}`,
    variables,
    fetchPolicy,
  })
    .then(({ data }) => data.getGolfAccounts));

/* */
export const deleteAccounts = (client, variables) => (
  client.mutate({
    mutation: gql`${actionTypes.gql.DELETE_ACCOUNTS}`,
    variables,
  })
    .then(({ data }) => data.success));

// -----------------------------------------------------------------------------
// Finance Transactions
// -----------------------------------------------------------------------------
export const searchTransactions = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`${actionTypes.gql.SEARCH_TRANSACTIONS}`,
    variables,
    fetchPolicy,
  })
    .then(({ data }) => data.searchTransactions));
