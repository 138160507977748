export default {
  // Default
  default: {
    actions: {
      ok: 'Ok',
      add: 'Ajouter',
      delete: 'Supprimer',
      cancel: 'Annuler',
      confirm: 'Valider',
      get: 'Récupérer',
      save: 'Enregistrer',
      send: 'Envoyer',
      signIn: 'Se connecter',
      signOut: 'Se déconnecter',
    },
  },
  stripe: {
    matching: {
      matched: 'Rapproché',
      unmatched: 'Orphelin',
    },
    installmentState: {
      due: 'A payer',
      paid: 'Payé',
      failed: 'En échec',
      unpaid: 'Non payé',
      cancelled: 'Annulé',
    },
    chargeStatus: {
      failed: 'Refusé',
      succeeded: 'Réussi',
    },
  },
  MUIDataTable: {
    body: {
      noMatch: 'Aucun résultat',
      toolTip: 'Trier',
    },
    pagination: {
      next: 'Page suivante',
      previous: 'Page précédente',
      rowsPerPage: 'Nombre de lignes par page :',
      displayRows: 'sur',
    },
    toolbar: {
      search: 'Rechercher',
      downloadCsv: 'Télécharger CSV',
      print: 'Imprimer',
      viewColumns: 'Colonnes',
      filterTable: 'Filtrer',
    },
    filter: {
      all: 'Tous',
      title: 'FILTRES',
      reset: 'RAZ',
    },
    viewColumns: {
      title: 'Voir les colonnes',
      titleAria: 'Afficher/Masquer les colonnes',
    },
    selectedRows: {
      text: 'ligne(s) sélectionnée(s)',
      delete: 'Supprimer',
      deleteAria: 'Supprimer les lignes sélectionnées',
    },
  },
  MaterialTable: {
    body: {
      emptyDataSourceMessage: 'Aucun résultat',
      filterRow: {
        filterTooltip: 'Filtrer',
      },
    },
    pagination: {
      firstTooltip: 'première page',
      firstAriaLabel: 'première page',
      lastTooltip: 'Dernière page',
      lastAriaLabel: 'Dernière page',
      nextTooltip: 'Page suivante',
      nextAriaLabel: 'Page suivante',
      previousTooltip: 'Page précédente',
      previousAriaLabel: 'Page précédente',
      labelRowsSelect: 'lignes',
      labelRowsPerPage: 'Nombre de lignes par page :',
      labelDisplayedRows: '{from}-{to} sur {count}',
    },
    toolbar: {
      searchTooltip: 'Rechercher',
      searchPlaceholder: 'Rechercher',
    },
  },
  // Terms,
  terms: {
    day: 'jour',
    month: 'mois',
    week: 'semaine',
    quota_exceeded: 'hors quota',
    price_overrided: 'tarif réduit',
  },
};
