export default {
  gql: {
    GET_ACCOUNTS: `
      query(
        $golfId: String
      ) {
        getAccounts(
          golfId: $golfId
        ) {
          id
          email
          phone
          lastname
          firstname
          createdAt
          matches {
            isMember
            golfId
          }
          tokens {
            token
            platform
            updatedAt
            deviceName
            expoVersion
          }
          documents {
            id
            filename
            mimetype
            createdAt
          }
        }
      }
    `,
    GET_ACCOUNTS_WITH_PUSH_TOKEN: `
      query(
        $golfId: String
      ) {
        getAccountsWithPushToken(
          golfId: $golfId
        ) {
          id
          email
          phone
          lastname
          firstname
          createdAt
          matches {
            isMember
            golfId
          }
          tokens {
            token
            platform
            updatedAt
            deviceName
            expoVersion
          }
          documents {
            id
            filename
            mimetype
            createdAt
          }
        }
      }
    `,
    GET_ACCOUNTS_BY_IDS: `
      query(
        $ids: [String]
      ) {
        getAccountsbyIds(
          ids: $ids
        ) {
          id
          email
          phone
          lastname
          firstname
          createdAt
          matches {
            isMember
            golfId
          }
          tokens {
            token
            platform
            updatedAt
            deviceName
            expoVersion
          }
          documents {
            id
            filename
            mimetype
            createdAt
          }
        }
      }
    `,
    MATCH_ACCOUNT: `
      mutation (
      $id: String!
      $golfId: String!
      ) {
        matchAccount(
      id: $id
      golfId: $golfId
      ) {
          success
        }
      }
    `,
    GET_ACCOUNT: `
      query($id: String!) {
        getAccount(id: $id) {
          id
          phone
          email
          lastname
          firstname
          createdAt
          matches {
            golfId
            isDefault
            isFavorite
          }
          tokens {
            token
            platform
            updatedAt
            deviceName
            expoVersion
          }
          covidCertificate
        }
      }
    `,
    LIST_PASSAGES: `
      query(
        $limit: Int
        $offset: Int
        $golfId: String
        $accountId: String!
      ) {
        listPassages(
          limit: $limit
          offset: $offset
          golfId: $golfId
          accountId: $accountId
        ) {
          passages {
            id
            date
            duration
            status
            paymentType
            total {
              price
              discount
              initPrice
              currency
            }
            track {
              id
              name
            }
            golf {
              id
              name
            }
          }
          metadata {
            total
            limit
            offset
          }
        }
      }
    `,
    LIST_WALLET_OPERATIONS: `
      query(
        $limit: Int
        $offset: Int
        $golfId: String
        $accountId: String!
      ) {
        listWalletOperations(
          limit: $limit
          offset: $offset
          golfId: $golfId
          accountId: $accountId
        ) {
          operations {
            date
            name
            price
            status
            credits
            discount
            quantity
            initPrice
          }
          metadata {
            total
            limit
            offset
          }
        }
      }
    `,
    SET_COVID_CERTIFICATE_OWNED: `
      mutation (
        $id: String!
      ) {
        setCovidCertificateOwned(
          id: $id
        ) {
        success
        }
      }
    `,
  },
};
