import React from 'react';
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  Grid,
} from '@material-ui/core';
import moment from 'moment';
import { connect } from 'react-redux';
import { useQuery } from 'react-query';
import TouchAppIcon from '@material-ui/icons/TouchApp';
/* */
import Menu from '_screens/Menu';
import masterClient from '_utils/master';
import { Screen } from '_components/core';
import { Chart } from '_components/statistiques';
import { loadMetrics } from '_redux/analytics/api';
/* */
const Dashboard = ({ golf }) => {
  const startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
  const endDate = 'today';
  const {
    isLoading,
    error,
    data: metricsData,
    isFetching,
  } = useQuery(['activeUsers', golf?.propertyId, startDate, endDate], () =>
    loadMetrics(masterClient, {
      propertyId: golf.propertyId, startDate, endDate,
    }),
  { enabled: !!golf?.propertyId });
  const Data = (data) => data && ([
    {
      Android: data.Android.total,
      iOS: data.iOS.total,
      Web: data.Web.total,
    },
  ]);
  if (!golf) {
    return (
    <CircularProgress />
    );
  }
  if (golf && !golf?.propertyId) {
    return (
      <Screen
        error={error}
        layout='fluid'
        title='Contacts'
        menu={<Menu />}>
        <Box style={{ textAlign: 'center' }}>
          <Container>
            <TouchAppIcon fontSize='large'/>
            <Typography variant="h4">
              {"Vous n'utilisez pas ce service actuellement."}
            </Typography>
            <Typography variant="h6">
              {'Veuillez contacter Prima.'}
            </Typography>
          </Container>
        </Box>
      </Screen>
    );
  }
  return (
    <Screen
      error={error}
      layout='fullWidth'
      title='Contacts'
      menu={<Menu />}
    >
      <Typography
        style={{
          fontSize: '18px', fontWeight: 800, marginLeft: 8, marginBottom: 4, alignSelf: 'flex-start',
        }}
      >
        {'Statistiques des 30 derniers jours'}
      </Typography>
      <Grid container justify='flex-start' spacing={1}>
        <Grid item xs={12} sm={10} md={12} lg={8} xl={6}>
          <Chart title={'Utilisateurs'} data={metricsData} variant='map' loading={isLoading || isFetching} />
        </Grid>
        <Grid item xs={12} sm={10} md={6} lg={4} xl={3}>
          <Chart title='Utilisateurs Actifs' data={Data(metricsData?.activeUsers)} loading={isLoading || isFetching} />
        </Grid>
        <Grid item xs={12} sm={10} md={6} lg={4} xl={3}>
          <Chart title='Nouveaux Utilisateurs' data={Data(metricsData?.newUsers)} loading={isLoading || isFetching} />
        </Grid>
      </Grid>
    </Screen>
  );
};
/* */
const mapStateToProps = ({ app: { golf } }) => ({ golf });
/* */
export default connect(mapStateToProps)(Dashboard);
