import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
/* */
import master from '_utils/master';
import { Screen } from '_components/core';
import * as Settings from '_redux/settings';
import { GolfGameConditionsForm } from '_components/forms';
import { createValidationSchema, validators } from '_utils/validation';

const services = require('./services.json');

/* */
const validationSchema = createValidationSchema({
  gameConditions: validators.array(
    createValidationSchema({
      type: validators.required,
      // name: validators.required,
      label: validators.required,
      status: validators.requiredBoolean,
      statuses: validators.array(validators.required),
    }),
  ),
});

/* */
const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(6),
  },
}));

/* */
const GameConditions = ({ golf }) => {
  /* */
  const [error, setError] = React.useState(null);
  const [comment, setComment] = React.useState('');
  const [success, setSuccess] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [gameConditions, setGameConditions] = React.useState([]);

  React.useEffect(() => {
    Settings.api.getGameConditions(master, { id: golf.id })
      .then((result) => {
        if (result) {
          const infos = result.find((c) => c.type === 'informations') || { comment: '' };
          const conditions = result.filter((c) => c.type !== 'informations');

          setComment(infos.comment);
          setGameConditions(conditions);
        }

        return Promise.resolve();
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  }, [golf.id]);

  const onSubmit = (values, { setSubmitting }) => {
    setError(null);
    setSuccess(null);

    const data = values.gameConditions.map(({
      status,
      statuses,
      ...others
    }) => ({
      ...others,
      status: Number(status),
      statuses: Settings.functions.statusesToObject(statuses),
    }));

    const c = values.comment.trim();
    if (c.length > 0) {
      data.push({
        comment: c,
        type: 'informations',
      });
    }

    Settings.api.setGameConditions(master, {
      id: golf.id,
      gameConditions: data,
    })
      .then(() => setSuccess('Données enregistrées.'))
      .catch(({ message }) => setError(message))
      .then(() => setSubmitting(false));
  };

  /* */
  const classes = useStyles();

  return (
    <Screen
      error={error}
      loading={loading}
      success={success}>
      <Typography
        gutterBottom
        variant="h5"
        align="center"
        component="h2"
        className={classes.title}>
        {'Conditions de jeu'}
      </Typography>
      <Formik
        onSubmit={onSubmit}
        component={GolfGameConditionsForm}
        validationSchema={validationSchema}
        initialValues={{ comment, gameConditions, services }} />
    </Screen>
  );
};

const mapStateToProps = ({ app }) => ({ ...app });

export default connect(mapStateToProps)(GameConditions);
