import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

/* */
const useStyles = makeStyles((theme) =>
  createStyles({
    fluid: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      paddingTop: theme.spacing(4),
      marginRight: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.up('sm')]: {
        width: 576,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      [theme.breakpoints.up('md')]: {
        width: 936,
      },
    },
    fixed: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      paddingTop: theme.spacing(4),
      marginRight: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.up(480 + (theme.spacing(2) * 2))]: {
        width: 480,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    fullWidth: {
      width: 'auto',
      [theme.breakpoints.up('xs')]: {
        width: 500,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      [theme.breakpoints.up('sm')]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      [theme.breakpoints.up('md')]: {
        width: 1000,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      [theme.breakpoints.up('lg')]: {
        width: 1600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      [theme.breakpoints.up('xl')]: {
        width: 1600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  }));

export default (props) => {
  const classes = useStyles();
  const {
    children,
    layout = 'fluid',
    component: Component = 'div',
  } = props;

  const className = () => {
    switch (layout) {
      case 'fluid':
        return classes.fluid;
      case 'fullWidth':
        return classes.fullWidth;
      default:
        return classes.fixed;
    }
  };

  return (
    <Component className={className()}>
      {children}
    </Component>
  );
};
