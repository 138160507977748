import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';

import i18n from '_utils/i18n';
import master from '_utils/master';
import { Screen } from '_components/core';
import * as Subscription from '_redux/subscription';

/* */
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
  },
}));

/* */
const Portfolio = ({ golf }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [memberships, setMemberships] = useState([]);

  /* */
  const load = () => {
    setLoading(true);

    Subscription.api.search(master, { golfId: golf.id })
      .then((data) => {
        setMemberships(data);
        console.log(data);
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  };

  /* */
  const listByState = (state) => {
    const list = memberships.filter((m) => m.currentStatus === state);

    if (list.length > 0) {
      return (
        <Paper className={classes.paper}>
          <List dense disablePadding>
            {list.map((m, key) => {
              const { firstname, lastname } = m.account;

              return (
                <ListItem key={key} divider>
                  <ListItemText
                    primary={`${firstname} ${lastname}`}
                    secondary={m.membershipPackage.membership.name} />
                  <ListItemSecondaryAction>
                    <Typography>
                      {i18n.l('currency', m.totalAmount / 100)}
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Paper>
      );
    }

    return null;
  };

  useEffect(load, []);

  /* */
  return (
    <Screen error={error} loading={loading}>
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        flexDirection="row">
        <Typography
          variant="h5"
          component="h2">
          {'Abonnement / Portefeuille'}
        </Typography>
      </Box>
      <Grid container justify="center" spacing={4}>
        <Grid item md={3}>
          <Typography variant="subtitle1">
            {'A renouveler (central)'}
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Typography variant="subtitle1">
            {'Ciblés (draft)'}
          </Typography>
          {listByState('draft')}
        </Grid>
        <Grid item md={3}>
          <Typography variant="subtitle1">
            {'En cours (pending)'}
          </Typography>
          {listByState('pending')}
        </Grid>
        <Grid item md={3}>
          <Typography variant="subtitle1">
            {'Transformés (completed)'}
          </Typography>
          {listByState('completed')}
        </Grid>
      </Grid>
    </Screen>
  );
};

const mapStateToProps = ({ app }) => ({ ...app });

export default connect(mapStateToProps)(Portfolio);
