import React from 'react';
import { useSnackbar } from 'notistack';
import MenuIcon from '@material-ui/icons/Menu';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Button,
  Drawer,
  Hidden,
  Toolbar,
  IconButton,
  Typography,
  CssBaseline,
  CircularProgress,
} from '@material-ui/core';

import i18n from '_utils/i18n';
import helpers from '_utils/helpers';

import { Wrapper } from '../elements';

/* */
const drawerWidth = 250;

/* */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: 20,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing(4)}px 0`,
  },
  drawer: {
    [theme.breakpoints.up('xl')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('xl')]: {
      display: 'none',
    },
  },
  homeButton: {
    marginRight: 20,
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
}));

const defaultProps = {
  error: null,
  success: null,
  loading: false,
  layout: 'fluid',
  noDrawer: false,
  errorAction: null,
  successAction: null,
};

/* */
export default ({
  menu,
  error,
  style,
  title,
  layout,
  success,
  loading,
  children,
  container,
  errorAction,
  onBackPress,
  successAction,
} = defaultProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  /* */
  const [tabletOpen, setTabletOpen] = React.useState(false);

  /* */
  React.useEffect(() => {
    if (success) {
      const options = {};
      if (successAction) {
        options.action = <Button size="small">{successAction.title}</Button>;
        options.onClickAction = successAction.onClick;
      }
      enqueueSnackbar(success, { variant: 'success', ...options });
    }
  }, [enqueueSnackbar, success, successAction]);
  /* */
  React.useEffect(() => {
    if (error) {
      const options = {};
      if (errorAction) {
        options.action = <Button size="small">{errorAction.title}</Button>;
        options.onClickAction = errorAction.onClick;
      }
      enqueueSnackbar(i18n.t(helpers.string.handleError(error)), { variant: 'error', ...options });
    }
  }, [enqueueSnackbar, error, errorAction]);

  /* */
  const handleDrawerToggle = () => {
    setTabletOpen((previous) => !previous);
  };

  /* */
  return (
    <div className={classes.root} style={{ ...style }}>
      <CssBaseline />
      {(menu || onBackPress) && (
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            {menu && (
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={handleDrawerToggle}
                className={classes.menuButton}>
                <MenuIcon />
              </IconButton>
            )}
            {onBackPress && (
              <IconButton
                color="inherit"
                aria-label="back"
                onClick={onBackPress}
                className={classes.menuButton}>
                <BackIcon />
              </IconButton>
            )}
            <Typography
              noWrap
              variant="h6"
              color="inherit"
              className={classes.grow}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      {menu && (
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden xlUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              open={tabletOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}>
              {menu}
            </Drawer>
          </Hidden>
          <Hidden lgDown implementation="css">
            <Drawer
              open
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent">
              {menu}
            </Drawer>
          </Hidden>
        </nav>
      )}
      <div className={classes.grow}>
        {(menu || onBackPress) && <div className={classes.toolbar} />}
        <Wrapper component="main" layout={layout}>
          {loading ? (
            <div className={classes.loaderContainer}>
              <CircularProgress
                size={32}
                thickness={4}
                color="secondary" />
            </div>
          ) : children}
        </Wrapper>
      </div>
    </div>
  );
};
