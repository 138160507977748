import React from 'react';
import { connect } from 'react-redux';
import RefreshIcon from '@material-ui/icons/Refresh';
import {
  Box,
  Button,
  Fab,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Icon, CircularProgress,
} from '@material-ui/core';
/* */
import master from '_utils/master';
import { Screen } from '_components/core';
import * as Booking from '_redux/booking';
import * as Client from '_redux/client';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

/* */
const columns = [
  {
    name: 'number',
    label: '',
  },
  {
    name: 'time',
    label: 'Départ',
  },
  {
    name: 'course',
    label: 'Parcour',
  },
  {
    name: 'lastname-firstname',
    label: 'Nom et Prénom',
  },
  {
    name: 'covid_certificate',
    label: 'Certificat COVID',
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'calc(97.5vh - 150px)',
    backgroundColor: '#FFFFFF',
    borderRadius: 15,
  },
  hasCertificate: {
    backgroundColor: '#67a467',
  },
  noCertificate: {
    backgroundColor: '#e74d4d',
  },
});

/* */
const CovidCertificate = (props) => {
  /* */
  const classes = useStyles();
  const { golf } = props;
  const [loading, setLoading] = React.useState(true);
  const [accounts, setAccounts] = React.useState([]);
  /* */
  const covidCertificateOwned = (data) => {
    data.persist();
    const { target } = data;
    const newAccounts = [];
    for (let i = 0; i < accounts.length; i += 1) {
      newAccounts.push(accounts[i]);
      if (newAccounts[i].id === target.id) {
        newAccounts[i].covid_certificate = moment();
      }
    }
    setAccounts(newAccounts);
    Client.api.setCovidCertificateOwned(master, { id: target.id });
    return null;
  };
  /* */
  const load = () => {
    const getData = async () => {
      setLoading(true);
      const { courses } = await Booking.api.selectGolf(master, { id: golf.id });
      const results = [];
      const coursesUniques = Array.from(new Set(courses.map((s) => s.id)));
      for (let i = 0; i < coursesUniques
        .map((id) => ({
          id,
          type: courses.find((s) => s.id === id).type,
          dayOpen: courses.find((s) => s.id === id).dayOpen,
          name: courses.find((s) => s.id === id).name,
        })).length; i += 1) {
        const course = coursesUniques
          .map((id) => ({
            id,
            type: courses.find((s) => s.id === id).type,
            dayOpen: courses.find((s) => s.id === id).dayOpen,
            name: courses.find((s) => s.id === id).name,
          }))[i];
        if (course.dayOpen !== 0) {
          const teetimes = await Booking.api.getCourseSchedule(master, {
            golfId: golf.id,
            date: moment().format('YYYY-MM-DD'),
            fullday: 1,
            players: [],
            courseId: course.id,
            course_type: course.type,
            admin: 1,
          })
            .then((r) => r.courseSchedule.teetimes)
            .catch(() => []);
          for (const teetime of teetimes) {
            const { players } = teetime;
            if (players.length > 0) {
              for (const player of players) {
                if (player && player.tid) {
                  const playerAccount = await Client.api.getAccount(master, { id: player.tid })
                    .then((account) => account)
                    .catch();
                  results.push({
                    id: playerAccount ? playerAccount.id : player.tid,
                    time: [teetime.time.slice(0, 2), ':', teetime.time.slice(2, 4)].join(''),
                    course: course.name,
                    lastname: player.lastname,
                    firstname: player.firstname,
                    reference: player.tid,
                    covid_certificate: playerAccount ? playerAccount.covidCertificate : null,
                    qr_code: '',
                  });
                }
              }
            }
          }
        }
      }
      return results;
    };
    getData().then((results) => {
      setAccounts(results.sort((a, b) => moment(a.time, 'hh:mm') - moment(b.time, 'hh:mm')));
      setLoading(false);
    });
  };
  /* */
  React.useEffect(load, []);

  const isValid = (r) => {
    if (window.confirm('Ce joueur possède un pass sanitaire valide ?')) {
      covidCertificateOwned(r);
    }
  };
  /* */
  return (
    <Screen>
      <Box
        mb={4}
        display="flex"
        alignItems='center'
        flexDirection="column">
        <Typography
          gutterBottom
          variant="h5"
          align="center"
          component="h2">
          {'Certificat COVID - Liste des Joueurs du jour'}
        </Typography>
        <Fab aria-label="Rafraichir" disabled={loading} onClick={load}>
          {!loading ? (
              <RefreshIcon />
          ) : (
              <CircularProgress size={25} />
          )}
        </Fab>
      </Box>
      {!loading ? (
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                      <TableCell
                          key={column.name}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {accounts.map((row, rowIndex) => (
                    <TableRow
                        tabIndex={-1}
                        className={row.covid_certificate ?
                          classes.hasCertificate :
                          classes.noCertificate}
                        style={{ height: '50px' }}
                        key={rowIndex}>
                      <TableCell>
                        <Typography>{rowIndex + 1}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{row.time}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{row.course}</Typography>
                      </TableCell>
                      <TableCell style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography>{`${row.lastname} `}</Typography>
                        <Typography>{`${row.firstname}`}</Typography>
                      </TableCell>
                      <TableCell>
                        {row.covid_certificate ? (
                            <Typography>{`Possède un certificat au ${moment(row.covid_certificate).format('DD-MM-YYYY')}`}</Typography>
                        ) : (
                            <Button
                                key={row.id}
                                title={'Possède un certificat'}
                                id={row.id}
                                name={row.id}
                                children={
                                  <Icon
                                      name={row.id}
                                      id={row.id}>
                                    check_circle_outline
                                  </Icon>
                                }
                                variant={'contained'}
                                color={'default'}
                                style={{ minWidth: 0, paddingLeft: 6, paddingRight: 6 }}
                                onClick={(r) => isValid(r)}>
                            </Button>
                        )}
                      </TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
      ) : (
          <Skeleton variant="rect" width={'100%'} height={'calc(97.5vh - 150px)'} />
      )}
    </Screen>
  );
};

const mapStateToProps = ({ app }) => ({ ...app });

export default connect(mapStateToProps)(CovidCertificate);
