import React from 'react';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { withStyles } from '@material-ui/core/styles';
import {
  Box, Button, TableCell, TableRow, Typography,
} from '@material-ui/core';
import moment, { now } from 'moment';
import Icon from '@mdi/react';
import { mdiGolfCart, mdiPlusCircle } from '@mdi/js';
/* */
const styles = () => ({
  rows: {
    paddingBlock: '8px',
    paddingInline: '16px',
  },
  icons: {
    marginLeft: 10,
    paddingTop: 3,
    fontSize: 24,
  },
  disabled: {
    backgroundColor: '#b1b1b1',
  },
  odd: {
    backgroundColor: '#f3faff',
  },
  even: {
    backgroundColor: '#ffffff',
  },
  timeIndicator: {
    scrollMargin: '100px',
    zIndex: '999',
    height: '3px',
    backgroundColor: '#ff9393',
    '&::before': {
    },
  },
});
/* */
class TeeSheetRow extends React.Component {
  isExpired = (dateTime) => {
    const { selectedDate } = this.props;
    return moment(`${selectedDate.year
    }-${
      selectedDate.month
    }-${
      selectedDate.day
    } ${
      dateTime.hours()
    }:${
      dateTime.minutes()
    }:00`).isSameOrBefore(moment(now()));
  }

  renderSlots = (data) => {
    const {
      classes,
      openPlayerList,
    } = this.props;
    const slots = [];
    for (let p = 0; p < data.players.length; p += 1) {
      slots.push(
        <TableCell className={classes.rows} key={`slot_${p}`}>
          <Button
            style={{
              width: '100%',
              padding: 0,
              margin: 0,
            }}
            onClick={() => (openPlayerList({
              time: data.time,
              players: data.players,
            }))}
            color='primary'
            variant={'contained'}>
            <Typography>
              {data.players[p].firstname} {data.players[p].lastname} {data.players[p].index && `(${data.players[p].index.toFixed(1)})`}
            </Typography>
          </Button>
        </TableCell>,
      );
    }
    for (let i = 0; i < data.availability; i += 1) {
      slots.push(
        <TableCell className={classes.rows} key={`emptySlot_${i}`}>
          <Button
            style={{
              width: '100%',
              padding: 0,
              margin: 0,
            }}
            color='primary'
            variant={'outlined'}
            onClick={() => (openPlayerList({
              time: data.time,
              players: data.players,
            }))}
            disabled={this.isExpired(moment([data.time.slice(0, 2), ':', data.time.slice(2, 4)].join(''), 'hh:mm'))}
          >
            <Icon path={mdiPlusCircle} size={1} style={{ verticalAlign: 'middle' }}/>
          </Button>
        </TableCell>,
      );
    }
    for (let i = data.players.length; i < 4 - data.availability; i += 1) {
      slots.push(
        <TableCell className={classes.rows} key={`disabledSlot_${i}`}>
          <Button
            style={{
              width: '100%',
              padding: 0,
              margin: 0,
            }}
            color='primary'
            variant={'contained'}
            disabled={true}
          >
            <Typography>Indisponible</Typography>
          </Button>
        </TableCell>,
      );
    }
    return slots;
  }

  /* */
  render() {
    const {
      classes,
      data,
      index,
      selectedDate,
      myRef,
    } = this.props;
    return (
      <React.Fragment key={`row_${index}`}>
        {
          data.time.slice(2, 4) === '00' && (
            <TableRow key={`hourSpace_${index}_`}
                      className={this.isExpired(moment([data.time.slice(0, 2), ':', data.time.slice(2, 4)].join(''), 'hh:mm')) ?
                        classes.disabled : null }>
              <TableCell
                className={classes.rows}
                colSpan={7}
                style={{ paddingBlock: 5 }}
              >
                <Box style={{
                  borderRadius: 7,
                  textAlign: 'left',
                  fontWeight: 'bold',
                  flex: 1,
                  padding: 5,
                  backgroundColor: '#DDDDDD',
                  marginInline: 15,
                }}>{[data.time.slice(0, 2), ':', data.time.slice(2, 4)].join('')}</Box>
              </TableCell>
            </TableRow>
          )}
        {
          moment(now()).isAfter(moment(`${selectedDate.year}-${selectedDate.month}-${selectedDate.day} ${data.time.slice(0, 2)}:${data.time.slice(2, 4) - 10}:00`)) &&
          moment(now()).isSameOrBefore(moment(`${selectedDate.year}-${selectedDate.month}-${selectedDate.day} ${data.time.slice(0, 2)}:${data.time.slice(2, 4)}:00`)) ?
            <TableRow>
              <Box
                colSpan={7}
                style={{ padding: 0 }}
                component={'td'}
                className={classes.timeIndicator}
                ref={myRef}
              />
            </TableRow> : null
        }
        <TableRow
          key={index}
          className={(this.isExpired(moment([data.time.slice(0, 2), ':', data.time.slice(2, 4)].join(''), 'hh:mm')) &&
            classes.disabled) ||
            ((index % 2) === 0 ? classes.odd : classes.even) }>
          <TableCell
            style={{ textAlign: 'center' }}
            className={classes.rows}>
            {[data.time.slice(0, 2), ':', data.time.slice(2, 4)].join('')}
          </TableCell>
          <TableCell
            style={{ textAlign: 'center' }}
            className={classes.rows}>
            {[data.prices.reduce((a, b) => a + b, 0), '€'].join(' ')}
          </TableCell>
          {this.renderSlots(data)}
          <TableCell style={{ padding: 5, textAlign: 'center', display: 'none' }} className={classes.rows}>
            <Icon path={mdiGolfCart} size={0.8} color={'grey'} className={classes.icons}/> 0
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}
/* */
export default withStyles(styles, { withTheme: true })(TeeSheetRow);
