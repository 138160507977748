import * as yup from 'yup';

import i18n from '_utils/i18n';

/* */
function equalTo(ref: any, msg: any) {
  return yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg, // || `${path} must be the same as ${reference}`,
    params: {
      reference: ref.path,
    },
    test(value: any) {
      return value === this.resolve(ref);
    },
  });
}
yup.addMethod(yup.string, 'equalTo', equalTo);

/* */
export const createValidationSchema = (validators) => yup.object().shape(validators);

/* */
export const validators = {
  /* */
  nullable: (validator) => validator.nullable(),
  require: (validator) => validator.required(i18n.t('errors.yup.required')),
  file: yup.mixed().test('fileSize', 'Le fichier ne doit pas dépasser 2Mo et être au format 16/9.', (value) => {
    if (!value?.length) return true; // attachment is optional
    return value[0].size <= 2000000;
  }),
  /* */
  email: yup
    .string()
    .trim()
    .email(i18n.t('errors.yup.string.email'))
    .required(i18n.t('errors.yup.string.required')),
  /* */
  emailConfirm: yup
    .string()
    .email(i18n.t('errors.yup.string.email'))
    .equalTo(
      yup.ref('email'),
      i18n.t('errors.yup.custom.mismatched_emails'),
    ),
  /* */
  optional: yup
    .string()
    .trim()
    .typeError(i18n.t('errors.yup.string.type')),
  /* */
  get required() {
    return this.optional
      .required(i18n.t('errors.yup.string.required'));
  },
  get inputSize() {
    return this.file;
  },
  /* */
  array: (of) => yup
    .array()
    .of(of)
    .typeError(i18n.t('errors.yup.array.type')),

  /* */
  number: yup
    .number()
    .typeError(i18n.t('errors.yup.number.type')),
  /* */
  get optionalNumber() {
    return this.number
      .nullable();
  },
  /* */
  get requiredNumber() {
    return this.number
      .required(i18n.t('errors.yup.required'));
  },
  /* */
  get optionalInt() {
    return this.optionalNumber
      .integer(i18n.t('errors.yup.number.integer'));
  },
  /* */
  get requiredInt() {
    return this.optionalInt
      .nullable(false)
      .required(i18n.t('errors.yup.required'));
  },

  boolean: yup
    .boolean()
    .typeError(i18n.t('errors.yup.boolean.type')),

  /* */
  get optionalBoolean() {
    return this.boolean
      .nullable();
  },
  /* */
  get requiredBoolean() {
    return this.optionalBoolean
      .required(i18n.t('errors.yup.required'));
  },
  /* */
  dateFourDigits: yup
    .string()
    .matches(/^([0-9]{2}\/[0-9]{2})$/, {
      message: ' ',
      excludeEmptyString: true,
    })
    .required(),
  /* */
  cvv: yup
    .string()
    .matches(/^([0-9]{3})$/, {
      message: ' ',
      excludeEmptyString: true,
    })
    .required(),
};
