import moment from 'moment';

/* */
export const getPeriods = (format = 'DD/MM/YYYY') => {
  const periods = {};

  // Today
  periods.today = [
    moment().format(format),
    moment().format(format),
  ];

  // This week
  periods.thisWeek = [
    moment().startOf('isoWeek').format(format),
    moment().endOf('isoWeek').format(format),
  ];

  // This month
  periods.thisMonth = [
    moment().startOf('month').format(format),
    moment().endOf('month').format(format),
  ];

  // This year
  periods.thisYear = [
    moment().startOf('year').format(format),
    moment().endOf('year').format(format),
  ];

  // Yesterday
  periods.yesterday = [
    moment().subtract(1, 'days').format(format),
    moment().subtract(1, 'days').format(format),
  ];

  // Last week
  periods.lastWeek = [
    moment().subtract(1, 'weeks').startOf('isoWeek').format(format),
    moment().subtract(1, 'weeks').endOf('isoWeek').format(format),
  ];

  // Last month
  periods.lastMonth = [
    moment().subtract(1, 'months').startOf('month').format(format),
    moment().subtract(1, 'months').endOf('month').format(format),
  ];

  // Last year
  periods.lastYear = [
    moment().subtract(1, 'years').startOf('year').format(format),
    moment().subtract(1, 'years').endOf('year').format(format),
  ];

  return periods;
};

export const noop = null;
