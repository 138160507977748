import React from 'react';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-modern-calendar-datepicker';
import { withStyles } from '@material-ui/core/styles';
import { mdiCalendarRange } from '@mdi/js';
import {
  Box, ListItemIcon, MenuItem, Select,
  Typography,
} from '@material-ui/core';
import i18n from '_utils/i18n';
import Icon from '@mdi/react';
/* */
const styles = () => ({
  inputSmall: {
    zIndex: 1,
    outline: 'none',
    cursor: 'pointer',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    border: 'none',
    paddingRight: 25,
    marginRight: 10,
    '&::before': {
      display: 'none',
    },
  },
  inputLarge: {
    zIndex: 1,
    outline: 'none',
    cursor: 'pointer',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    border: 'none',
  },
  otherTextSmall: {
    zIndex: -1,
    fontSize: 16,
    padding: 0,
    marginBottom: -5,
  },
  otherTextLarge: {
    zIndex: -1,
    fontSize: 14,
    padding: 0,
    marginBottom: -5,
  },
  content: {
    letterSpacing: -0.8,
    borderRadius: 5,
  },
  picker: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    borderRadius: 5,
  },
  pickerIcon: {
    zIndex: -1,
    position: 'absolute',
    right: 0,
    top: '40%',
  },
  dayNumber: {
    zIndex: -1,
    fontSize: 42,
    padding: 0,
    marginTop: -5,
    marginBottom: -5,
  },
  dayText: {
    zIndex: -1,
    fontWeight: 'bold',
    fontSize: 14,
    padding: 0,
    marginTop: -5,
  },
  calendar: {
    margin: 0,
    zIndex: 999,
  },
  icons: {
    marginTop: -1,
  },
});
/* */
class RNDatePicker extends React.Component {

  /* */
  renderSmallInput = ({ ref }) => (
    <Box style={{ paddingRight: 10, userSelect: 'none' }}>
      <Select
        value={''}
        readOnly
        ref={ref}
        className={this.props.classes.inputSmall}
      />
      {this.props.selectedDate ? (
        <MenuItem style={{
          display: 'flex', flexDirection: 'row', paddingTop: 14, userSelect: 'none',
        }}>
          <ListItemIcon style={{ textAlign: 'center', userSelect: 'none' }}>
            <Icon path={mdiCalendarRange} size={1} className={this.props.classes.icons} key={'icon'}/>
            <Typography className={this.props.classes.otherTextSmall} style={{ marginRight: 4, userSelect: 'none' }}>
              {this.props.selectedDate.day}
            </Typography>
            <Typography className={this.props.classes.otherTextSmall} style={{ textTransform: 'capitalize', marginRight: 4, userSelect: 'none' }}>
              {i18n.t('date.abbr_month_names')[
                new Date(`${this.props.selectedDate.year}, ${this.props.selectedDate.month + 1}, ${this.props.selectedDate.day}`).getMonth()
              ]}
            </Typography>
            <Typography className={this.props.classes.otherTextSmall}>
              {`${this.props.selectedDate.year}`}
            </Typography>
          </ListItemIcon>
        </MenuItem>
      ) : ''}
    </Box>
  );

  /* */
  renderLargeInput = ({ ref }) => (
    <Box className={this.props.classes.content}>
      <Select
        readOnly
        value={''}
        ref={ref}
        className={this.props.classes.inputLarge}
      />
      {this.props.selectedDate ? (
        <Box style={{ display: 'flex', flexDirection: 'row', userSelect: 'none' }}>
          <Typography className={this.props.classes.dayNumber}>
            {this.props.selectedDate.day}
          </Typography>
          <Box style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 5,
            paddingTop: 11,
            marginRight: 20,
          }}>
            <Typography className={this.props.classes.dayText} style={{ textTransform: 'capitalize' }}>
              {i18n.t('date.day_names')[
                new Date(`${this.props.selectedDate.year}, ${this.props.selectedDate.month}, ${this.props.selectedDate.day}`).getDay()
              ]}
            </Typography>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography className={this.props.classes.otherTextLarge} style={{ textTransform: 'capitalize', marginRight: 4 }}>
                {i18n.t('date.abbr_month_names')[
                  new Date(`${this.props.selectedDate.year}, ${this.props.selectedDate.month + 1}, ${this.props.selectedDate.day}`).getMonth()
                ]}
              </Typography>
              <Typography className={this.props.classes.otherTextLarge}>
                {`${this.props.selectedDate.year}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : ''}
    </Box>
  );

  /* */
  render() {
    const { classes, onChange } = this.props;
    const { variant } = this.props || 'small';
    const myCustomLocale = {
      // months list by order
      months: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
      ],

      // week days by order
      weekDays: [
        {
          name: 'Dimanche', // used for accessibility
          short: 'D', // displayed at the top of days' rows
          isWeekend: true, // is it a formal weekend or not?
        },
        {
          name: 'Lundi',
          short: 'L',
        },
        {
          name: 'Mardi',
          short: 'M',
        },
        {
          name: 'Mercredi',
          short: 'M',
        },
        {
          name: 'Jeudi',
          short: 'J',
        },
        {
          name: 'Vendredi',
          short: 'V',
        },
        {
          name: 'Samedi',
          short: 'S',
          isWeekend: true,
        },
      ],

      // just play around with this number between 0 and 6
      weekStartingIndex: 0,

      // return a { year: number, month: number, day: number } object
      getToday(gregorainTodayObject) {
        return gregorainTodayObject;
      },

      // return a native JavaScript date here
      toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
      },

      // return a number for date's month length
      getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
      },

      // return a transformed digit to your locale
      transformDigit(digit) {
        return digit;
      },

      // texts in the date picker
      nextMonth: 'Mois Suivant',
      previousMonth: 'Mois Précédent',
      openMonthSelector: 'Ouvrir Sélecteur de Mois',
      openYearSelector: 'Ouvrir Sélecteur d\'Année',
      closeMonthSelector: 'Fermer Sélecteur de Mois',
      closeYearSelector: 'Fermer Sélecteur d\'Année',
      defaultPlaceholder: 'Selectionner...',

      // for input range value
      from: 'Du',
      to: 'au',

      // used for input value when multi dates are selected
      digitSeparator: ',',

      // if your provide -2 for example, year will be 2 digited
      yearLetterSkip: 0,

      // is your language rtl or ltr?
      isRtl: false,
    };
    return (
      <React.Fragment>
        <DatePicker
          value={{
            year: this.props.selectedDate.year,
            month: this.props.selectedDate.month,
            day: this.props.selectedDate.day,
          }}
          onChange={onChange}
          locale={myCustomLocale}
          calendarClassName={classes.calendar}
          renderInput={variant === 'large' ? this.renderLargeInput : this.renderSmallInput }
        />
      </React.Fragment>
    );
  }
}
export default withStyles(styles, { withTheme: true })(RNDatePicker);
