/* eslint global-require: 0 */
import _ from 'lodash/fp/object';
import config from './env/config.json';

/* */
function envConfig() {
  if (['development', 'dev'].includes(process.env.NODE_ENV)) {
    return require('./env/config_dev.json');
  }
  return {};
}

const conf = _.defaultsDeep(config, envConfig());
if (process.env.REACT_APP_TOKEN) {
  conf.token = process.env.REACT_APP_TOKEN;
}

export default conf;
