import gql from 'graphql-tag';
import actionTypes from './constants';

/* */
export const getRefillStatus = (client, variables, fetchPolicy = 'no-cache') => client.query({
  query: gql`${actionTypes.gql.GET_REFILL_STATUS}`,
  fetchPolicy,
  variables,
})
  .then((res) => res.data)
  .then((data) => (data.PA_getRefillStatusByGolf));

/* */
export const setRefillStatus = (client, variables) => client.mutate({
  mutation: gql`${actionTypes.gql.SET_REFILL_STATUS}`,
  variables,
})
  .then((res) => res.data)
  .then((data) => data.PA_setRefillStatus);
