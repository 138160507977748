import React from 'react';
import {
  Box,
  CircularProgress,
} from '@material-ui/core';

/* */
export default () => (
  <Box
    height="100%"
    display="flex"
    alignItems="center"
    justifyContent="center">
    <CircularProgress
      size={40}
      thickness={4}
      color="inherit" />
  </Box>
);
