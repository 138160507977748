import React, { useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { connect } from 'react-redux';
import { mdiCardAccountDetails } from '@mdi/js';
import {
  Box,
  Button,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
  CircularProgress,
} from '@material-ui/core';

import i18n from '_utils/i18n';
import master from '_utils/master';
import { Screen } from '_components/core';
import * as Primaccess from '_redux/primaccess';

/* */
const List = ({ golf }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [autoRefills, setAutoRefills] = useState([]);
  const [disabling, setDisabling] = useState(null);

  /* */
  const load = () => {
    setLoading(true);

    Primaccess.api.getRefillStatus(master, { golfId: golf.id })
      .then((data) => {
        setAutoRefills(data.map((d) => ({
          ...d,
          product: JSON.parse(d.product),
        })));
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  };

  useEffect(load, []);

  /* */
  const disableRefill = (id, account) => {
    setDisabling(id);

    const msg = `Rechargement désactivé pour ${account.firstname} ${account.lastname}.`;

    Primaccess.api.setRefillStatus(master, {
      accountId: account.id,
      golfId: golf.id,
      status: false,
    })
      .then(() => {
        setSuccess(msg);
        setAutoRefills(autoRefills.filter((r) => r.id !== id));
      })
      .catch((err) => setError(err.message))
      .finally(() => setDisabling(null));
  };

  /* */
  return (
    <Screen
      layout="fluid"
      error={error}
      success={success}
      loading={loading}
    >
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        flexDirection="row">
        <Typography
          variant="h5"
          component="h2">
          {'Practice - Rechargements automatiques actifs'}
        </Typography>
      </Box>
      <TableContainer
        component={Paper}
        style={{ marginTop: 24 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>{'Golfeur'}</TableCell>
              <TableCell>{'Produit concerné'}</TableCell>
              <TableCell>{'Depuis le'}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && autoRefills
              .map(({
                id,
                status,
                createdAt,
                product,
                account,
              }, i) => (
                <TableRow key={i}>
                  <TableCell
                    scope="row"
                    component="th">
                    {`${account.firstname} ${account.lastname}`}
                  </TableCell>
                  <TableCell>
                    {product &&
                      `${product.name} - ${product.value} crédits - ${i18n.l('currency', product.price / 100)}`
                    }
                  </TableCell>
                  <TableCell>
                    {i18n.l('date.formats.default', createdAt)}
                  </TableCell>
                  <TableCell align="right">
                    {status && (
                      <Button
                        size="small"
                        color="primary"
                        variant={(disabling === id) ? 'text' : 'contained'}
                        disabled={Boolean(disabling)}
                        onClick={() => disableRefill(id, account)}
                      >
                        {(disabling === id)
                          ? <CircularProgress color="secondary" size={24} />
                          : 'Désactiver'
                        }
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {!loading && autoRefills.length === 0 && (
                <TableRow>
                  <TableCell size="medium" align="center" colSpan="5">
                    <Icon path={mdiCardAccountDetails} size={3} style={{ color: '#737b83' }} />
                    <Typography style={{ color: '#737b83' }} variant="h6">
                      {'Aucun rechargement activé.'}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell size="medium" align="center" colSpan="5">
                    <CircularProgress
                      size={16}
                      thickness={4}
                      color="secondary" />
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Screen>
  );
};

const mapStateToProps = ({ app }) => ({ ...app });

export default connect(mapStateToProps)(List);
