import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import 'moment/locale/fr';
import moment from 'moment';
import master from '_utils/master';
import * as School from '_redux/school';
import { Screen } from '_components/core';
import { makeStyles } from '@material-ui/styles';

import { ages, levels } from './shared';

/* */
const useStyles = makeStyles(() => ({
  notPrintable: {
    '@media print': {
      display: 'none',
    },
  },
}));

export default () => {
  const classes = useStyles();

  const [error] = useState(null);
  const [success] = useState(null);
  const [loading, setLoading] = useState(true);

  const [students, setStudents] = useState([]);

  const getLevel = (level) => levels[level];
  const getAge = (age) => ages[age];
  const getDate = (date) => {
    const d = moment(date).format('dddd');
    return d[0].toLocaleUpperCase() + d.substr(1);
  };

  /* */
  const loadStudents = () => {
    School.api.getLessonStudents(master).then((res) => {
      let sortedStudents = [...res];
      sortedStudents = sortedStudents.sort((a, b) => a.firstname.localeCompare(b.firstname));
      sortedStudents = sortedStudents.sort((a, b) => a.lastname.localeCompare(b.lastname));
      sortedStudents = sortedStudents.sort((a, b) => {
        const ageA = Object.keys(ages).indexOf(ages[a]);
        const ageB = Object.keys(ages).indexOf(ages[b]);

        return ageA - ageB;
      });
      sortedStudents = sortedStudents.sort((a, b) => moment(a.date) - moment(b.date));

      setStudents(sortedStudents);
    });
  };

  useEffect(() => {
    loadStudents();
    setLoading(false);
  }, []);

  return (
    <Screen
      layout="fluid"
      error={error}
      success={success}
      loading={loading}
      title="EDG">
      <Box position="absolute" right="0" top="0" className={classes.notPrintable}>
        <Button variant="outlined" onClick={() => window.print()}>
          Imprimer
        </Button>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs className={classes.notPrintable}>
          <Typography variant="h5" component="h2">{'Élèves'}</Typography>
        </Grid>

        {students ? (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nom</TableCell>
                  <TableCell>Prénom</TableCell>
                  <TableCell>Jour</TableCell>
                  <TableCell>Heure</TableCell>
                  <TableCell>Professeur</TableCell>
                  <TableCell>Âge</TableCell>
                  <TableCell>Niveau</TableCell>
                  {/* <TableCell>Cours</TableCell> */}
                  <TableCell>Commentaire</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((student) => (
                  <TableRow key={student.id}>
                    <TableCell>{student.lastname}</TableCell>
                    <TableCell>{student.firstname}</TableCell>
                    <TableCell>{getDate(student.date)}</TableCell>
                    <TableCell>{student.hour.substr(0, 5)}</TableCell>
                    <TableCell>{student.teacher}</TableCell>
                    <TableCell>{getAge(student.age)}</TableCell>
                    <TableCell>{getLevel(student.level)}</TableCell>
                    {/* <TableCell>{student.title}</TableCell> */}
                    <TableCell>{student.comment}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (null)}
      </Grid>
    </Screen>
  );
};
