import gql from 'graphql-tag';

/* */
const search = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`
      query(
        $golfId: String
      ) {
        searchSubscriptions(
          golfId: $golfId
        ) {
          id
          golf {
            id
          }
          account {
            id
            firstname
            lastname
          }
          name
          totalAmount
          startsAt
          expiresAt
          createdAt
          currentStatus
          countActiveBeneficiaries
          membershipPackage {
            membership {
              name
              amount
            }
            products {
              name
              type
              amount
            }
          }
        }
      }
    `,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => data.searchSubscriptions));

/* */
const searchMemberships = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`
      query(
        $year: String!
        $golfId: String
      ) {
        searchMemberships(
          year: $year
          golfId: $golfId
        ) {
          fees
          name
          amount
          pgbNumcotis
        }
      }
    `,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => data.searchMemberships));

/* */
const searchOptions = (client, variables, fetchPolicy = 'no-cache') => (
  client.query({
    query: gql`
      query(
        $year: String!
        $golfId: String
      ) {
        searchOptions(
          year: $year
          golfId: $golfId
        ) {
          name
          type
          amount
          pgbCode
          isPayableInInstallments
        }
      }
    `,
    variables,
    fetchPolicy,
  })
    .then((res) => res.data)
    .then((data) => data.searchOptions));

/* */
const create = (client, variables) => (
  client.mutate({
    mutation: gql`
      mutation(
        $baseAmount: Int!
        $totalAmount: Int!
        $startsAt: String!
        $expiresAt: String!
        $accountId: String!
        $paymentPlan: String!
        $membershipPackage: String!
        $golfId: String
        $paymentSchedule: [Sub_InstallmentInput]
      ) {
        createSubscription(
          baseAmount: $baseAmount
          totalAmount: $totalAmount
          startsAt: $startsAt
          expiresAt: $expiresAt
          accountId: $accountId
          paymentPlan: $paymentPlan
          membershipPackage: $membershipPackage
          golfId: $golfId
          paymentSchedule: $paymentSchedule
        ) {
          id
          status
          createdAt
        }
      }
    `,
    variables,
  })
    .then((res) => res.data)
    .then((data) => data.createSubscription));

/* */
const getById = (client, variables) => client.query({
  query: gql`
    query(
      $id: String!
    ) {
      getSubscriptionById(
        id: $id
      ) {
        id
        name
        totalAmount
        startsAt
        expiresAt
        paymentPlan
        createdAt
        membershipPackage {
          id
          membership {
            name
            amount
          }
          products {
            name
            type
            amount
          }
        }
      }
    }
  `,
  fetchPolicy: 'no-cache',
  variables,
})
  .then((res) => res.data)
  .then((data) => (data.getSubscriptionById));

/* */
const getPlayerTypes = (client, variables) => client.query({
  query: gql`
    query(
      $membershipPackageId: String!
    ) {
      getSubscriptionPlayerTypes(
        membershipPackageId: $membershipPackageId
      ) {
        id
        name
        rules {
          name
          value
        }
      }
    }
  `,
  fetchPolicy: 'no-cache',
  variables,
})
  .then((res) => res.data)
  .then((data) => data.getSubscriptionPlayerTypes);

/* */
const getBeneficiaries = (client, variables) => client.query({
  query: gql`
    query(
      $id: String!
    ) {
      getSubscriptionBeneficiaries(
        subscriptionId: $id
      ) {
        id
        endDate
        startDate
        account {
          id
          lastname
          firstname
          email
          phone
        }
      }
    }
  `,
  fetchPolicy: 'no-cache',
  variables,
})
  .then((res) => res.data)
  .then((data) => data.getSubscriptionBeneficiaries);

/* */
const addBeneficiary = (client, variables) => (
  client.mutate({
    mutation: gql`
      mutation(
        $accountId: String!
        $subscriptionId: String!
      ) {
        addSubscriptionBeneficiary(
          accountId: $accountId
          subscriptionId: $subscriptionId
        ) {
          id
          endDate
          startDate
          createdAt
        }
      }
    `,
    variables,
  })
    .then((res) => res.data)
    .then((data) => data.addSubscriptionBeneficiary));

/* */
const removeBeneficiary = (client, variables) => (
  client.mutate({
    mutation: gql`
      mutation(
        $id: String!
      ) {
        removeSubscriptionBeneficiary(
          id: $id
        ) {
          success
        }
      }
    `,
    variables,
  })
    .then((res) => res.data)
    .then((data) => data.removeSubscriptionBeneficiary));

export {
  create,
  search,
  getById,
  getBeneficiaries,
  getPlayerTypes,
  addBeneficiary,
  removeBeneficiary,
  searchOptions,
  searchMemberships,
};
